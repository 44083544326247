/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import { Select, AutoComplete } from 'antd';
import { getLicenseNumbers } from '../services/api.service';
import { getUsers } from '../services/manage-users.service';
import { take, debounce, get, uniqBy, keyBy } from 'lodash';

export default class LNSearch extends Component {
  state = {
    error: null,
    loading: true,
    licenseNumbersAll: [],
    licenseNumbers: [],
    allUsers: [],
    value: undefined,
    selectedOption: undefined,
    isNM: false,
  };
  retrieve = async () => {
    try {
      const { accountsOnly } = this.props;
      const allLns = await getLicenseNumbers();

      const filterActive = get(this, 'props.filterActive', true);

      let licenseNumbersAll = []; 
      
      let allUsers = [];
      if (accountsOnly) {
        allUsers = await getUsers();
        const map = keyBy(allLns, 'License #');

        allUsers.map(u => {
          const company = get(u, 'data.company');
          if (company) {
            licenseNumbersAll.push(company);
            const additionalLNs = get(company, 'licenseNumbers');
            if (additionalLNs && additionalLNs.map) {
              additionalLNs.map(ln => {
                const comp = get(map, ln);
                if (comp) { licenseNumbersAll.push(comp) }
              })
            }
          }
        })
        licenseNumbersAll = uniqBy(licenseNumbersAll, 'License #');
      } else {
        licenseNumbersAll = allLns;
      }
      licenseNumbersAll = licenseNumbersAll.filter(f => !!get(f, 'License #'));
      const licenseNumbers = this.generateOptions(take(licenseNumbersAll, 100));
      this.setState({
        licenseNumbers,
        loading: false,
        allUsers,
        licenseNumbersAll: filterActive ? licenseNumbersAll.filter(f => !!get(f, 'active', true)) : licenseNumbersAll,
      });
    } catch (err) {
      console.error(err);
    }
  }
  componentDidMount = () => {
    this.retrieve();
  };
  componentDidUpdate = (prevProps, prevState) => {
    if(this.props.value !== prevProps.value && this.props.value !== this.state.value) {
      this.setState({
        value: this.props.value
      })
    }
    if (prevState.isNM !== this.state.isNM) {
      this.props.onNM && this.props.onNM(this.state.value);
    }
    if (this.props.accountsOnly !== prevProps.accountsOnly) {
      this.retrieve();
    }
  }
  generateOptions = (lns) => {
    return lns.map(l => (
      <Select.Option
        key={l['License #']}
        value={l['License #']}
        name={`${l['Licensee']} - #${l['License #']}`}
      >
        <span>
          <span>{`${l["Licensee"]} - `}</span>
          <span css={css(`font-weight: bold;`)}>{`#${l['License #']}`}</span>
        </span>
      </Select.Option>
    ))
  }
  getSelected = () => {
    const { licenseNumbersAll, selectedOption, value } = this.state;

    return licenseNumbersAll.find(l => get(l, 'License #') === (selectedOption || value)) || {};
  }
  onChange = (value) => {
    const isNM = (value || '').toLowerCase().indexOf('nm-') > -1;
    this.setState({ value, isNM });
    if (isNM) {
      this.selectOption(value);
    }
  }
  onSearch = debounce((input) => {
    const { licenseNumbersAll } = this.state;

    this.setState({ loading: true });

    const val = input.toLowerCase();

    const licenseNumbers = licenseNumbersAll.filter(l => {
      const name = get(l, 'Licensee', '').toLowerCase();
      const ln = get(l, 'License #', '').toLowerCase();
      if (name.indexOf(val) > -1 || ln.indexOf(val) > -1) {
        return true;
      } else {
        return false;
      }
    })

    this.setState({
      licenseNumbers: take(this.generateOptions(licenseNumbers), 100),
      loading: false,
    });
  }, 800);
  selectOption = (val) => {
    const { onSelect } = this.props;
    this.setState({ selectedOption: val, value: val }, () => onSelect && onSelect(val));
  }
  render() {
    const { loading, licenseNumbers, value } = this.state;

    const canAdd = get(this, 'props.canAdd', true);

    return (
      <AutoComplete
        {...this.props}
        style={{ width: '100%' }}
        placeholder={'Type to filter results'}
        onSearch={this.onSearch}
        onSelect={this.selectOption}
        dataSource={canAdd ? licenseNumbers : undefined}
        onChange={this.onChange}
        value={value}
        loading={loading}
        optionLabelProp={'name'}
      />
    );
  }
};
