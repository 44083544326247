/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import { Form, Input, Alert } from 'antd';
import { withRouter } from 'react-router';
import { Button } from '../../_shared/button';
import styles from './change-password.styles';
import { buildErrorMsgFromForm } from '../../actions/utils';
import { changePassword } from '../../_shared/services/authentication.service';
import { get } from 'lodash';

export class _ChangePasswordComponent extends Component {
  state = {
    loading: false,
    error: null,
    success: null,
    toDisplay: [
      {
        title: 'Current Password',
        var: 'currentPassword',
      },
      {
        title: 'New Password',
        var: 'pw1',
        options: {
          validateTrigger: 'onBlur',
          rules: [
            {
              max: 60,
              message: 'Password is limited to 60 characters. ',
            },
            {
              min: 8,
              message: 'Passwords must be at least 8 characters. ',
            },
          ],
        },
      },
      {
        title: 'Confirm New Password',
        var: 'pw2',
        options: {
          validateTrigger: 'onBlur',
          rules: [
            {
              max: 60,
              message: 'Password is limited to 60 characters. ',
            },
            {
              min: 8,
              message: 'Passwords must be at least 8 characters. ',
            },
          ],
        },
      },
    ],
  };
  submit = async (e) => {
    e.preventDefault();
    if (this.state.loading) {
      return;
    }

    this.state.loading = true;
    this.state.error = null;
    this.state.success = null;
    this.setState(this.state);

    const { form } = this.props;

    form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        const error = buildErrorMsgFromForm(err);
        return this.setState({ error: error, loading: false });
      }

      try {
        const { pw1, pw2, currentPassword } = values;

        form.setFields({
          pw1: {
            value: pw2,
            errors: null,
          },
        });

        form.setFields({
          pw2: {
            value: pw2,
            errors: null,
          },
        });

        if (pw1 !== pw2) {
          return this.setState({
            error: 'Passwords do not match. Please try again.',
            loading: false,
          });
        }

        await changePassword(this.props.sState.user, currentPassword, pw1);

        this.setState({
          loading: false,
          isEdit: false,
          success: 'Password updated successfully!',
        });
      } catch (err) {
        console.log(err.response)
        const msg = get(err, 'response.data.error') || err.message.toLowerCase();
        return this.setState({
          error:
            msg.indexOf('incorrect username') > -1
              ? 'Current Password entered is incorrect.'
              : 'Change Password Failed: ' + msg,
          loading: false,
        });
      }
    });
  };
  render() {
    const {
      sState: {
        user,
      },
      form: { getFieldDecorator, getFieldValue },
    } = this.props;
    const { toDisplay, loading, error, success } = this.state;

    const btnDisabled = !(
      getFieldValue('currentPassword') &&
      getFieldValue('pw1') &&
      getFieldValue('pw2')
    );

    return user ? (
      <div css={css(styles.container)}>
        <h2>Change Password</h2>

        {error && (
          <Alert
            css={css(styles.alert)}
            type="error"
            message={error}
            closable
            onClose={() => this.setState({ error: null })}
          />
        )}

        {success && (
          <Alert
            css={css(styles.alert)}
            type="success"
            message={success}
            closable
            onClose={() => this.setState({ success: null })}
          />
        )}

        <Form layout="vertical" onSubmit={this.submit}>
          {toDisplay.map((item, i) => {
            return (
              <Form.Item key={i} label={item.title}>
                {getFieldDecorator(item.var, item.options)(<Input.Password />)}
              </Form.Item>
            );
          })}
          <Button
            disabled={btnDisabled}
            title={'Save'}
            loading={loading}
            onClick={this.submit}
          />
        </Form>
      </div>
    ) : (
      <div>No User Provided</div>
    );
  }
}

export const ChangePasswordComponent = Form.create()(
  withRouter(_ChangePasswordComponent)
);
