export const DEFAULT_FONT_SIZE = 72;
export const DEFAULT_WIDTH = 600;

export const buildDims = (width = DEFAULT_WIDTH) => {
  return {
    width,
    height: width * 0.2,
  }
}

const buttonRow = {
  height: 32,
}

export const styles = {
  fullscreen: `
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    padding: 10px;
  `,
  fullscreenbottomrow: `
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    width: 100%;
    justify-content: center;
  `,
  editcontainer: props => `
    width: ${buildDims(props.width).width}px;
    height: ${buildDims(props.width).height + buttonRow.height}px;
    position: relative;
    // border: 1px solid blue;

    .namedisplay {
      position: absolute;
      top: 5px;
    }

    .inputContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  `,
  canvascontainer: `
    border: 1px solid rgba(0,0,0,0.2);
    // background-color: rgba(0,0,0,0.2);
    position: relative;
    top: 10px;

    &.display {
      top: ${buttonRow.height}px;
    }

    .textToSig {
      font-family: 'Homemade Apple', cursive;
      font-size: 48px;  
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      padding: 10px;
    }
  `,
  buttonContainer: `
    position: absolute;
    top: 0;
    right: 0;
    height: ${buttonRow.height};
    justify-content: flex-end;
  `,
  buttonContainerFS: `
    top: 10px;
    right: 10px;
  `,
  fsSigText: `
    font-weight: bold;
  `,
}