import colors from '../../_shared/colors';

export default {
  bgSelect: `
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.2);
    z-index: 1048;
  `,
  orderPicker: `
    position: absolute;
    top: 25px;
    right: 0px;
    height: 400px;
    width: 290px;
    z-index: 1049;
  `,
  title: `
    position: relative;
    top: -24px;
    margin: -10px;
    background: white;
    font-weight: bold;
    padding: 10px;
  `,
  submitOrder: `
    position: absolute;
    bottom: 10px;
    right: 10px;
  `,
}