/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import {
  testPassword,
  PWReqsMessage,
  buildErrorMsgFromForm,
} from '../../actions/utils';
import { Button } from '../../_shared/button';
import { withRouter } from 'react-router-dom';
import Logo from '../../_shared/logo';
import { Form, Input, Alert } from 'antd';
import styles from '../authentication.styles';
import SharedStyles from '../../_shared/styles';
import Link from '../../_shared/link';

export class FGPasswordComponent extends Component {
  state = {
    loading: false,
    error: null,
  };
  goToLogin = () => {
    this.props.history.push('/login');
  };
  submit = async (e) => {
    e.preventDefault();
    if (this.state.loading) {
      return;
    }

    this.state.loading = true;
    this.state.error = null;
    this.setState(this.state);

    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        const error = buildErrorMsgFromForm(err);
        return this.setState({ error, loading: false });
      }
      const { code, pw1 } = values;

      if (!testPassword(pw1)) {
        return this.setState({
          loading: false,
          error: PWReqsMessage,
        });
      }

      try {
        const { email } = this.props.history.location.state;
        await this.props.forgotPasswordSubmit(email, code, pw1);
        await this.props.signIn(email, pw1);
        this.props.history.push('/dashboard');
      } catch (err) {
        this.setState({
          loading: false,
          error: err.message,
        });
      }
    });
  };
  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { error, loading } = this.state;
    const btnDisabled = !(
      getFieldValue('code') &&
      (getFieldValue('pw1') !== '' &&
        getFieldValue('pw1') === getFieldValue('pw2'))
    );

    return (
      <div className="ForgotPasswordSubmit" css={css(styles.container)}>
        <div css={css(SharedStyles.logoWrap)}>
          <Logo />
        </div>

        <p css={css(styles.title)}>{'Change Your Password'}</p>
        <p css={css(styles.subTitle)}>{'Enter the code you received from the email we sent you.'}</p>

        {!!error && (
          <Alert
            message={error}
            type={'error'}
            closable
            onClose={() => this.setState({ error: null })}
          />
        )}

        <div css={css(styles.inputContainer)}>
          <Form layout="vertical" onSubmit={this.submit}>
            <Form.Item label={'Code'}>
              {getFieldDecorator('code', {
                validateTrigger: 'onBlur',
                rules: [
                  {
                    required: true,
                    message: 'Please input the code we sent to your email.',
                  },
                  {
                    max: 10,
                    message: 'Code is limited to 10 characters. ',
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label={'New Password'}>
              {getFieldDecorator('pw1', {
                validateTrigger: 'onBlur',
                rules: [
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                  {
                    max: 60,
                    message: 'Password is limited to 60 characters. ',
                  },
                ],
              })(<Input.Password />)}
            </Form.Item>
            <Form.Item label={'Confirm Password'}>
              {getFieldDecorator('pw2', {
                validateTrigger: 'onBlur',
                rules: [
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  {
                    max: 60,
                    message: 'Password is limited to 60 characters. ',
                  },
                ],
              })(<Input.Password />)}
            </Form.Item>

            <Form.Item>
              <div css={css(styles.buttonContainer + `margin-top: 20px; flex-direction: column;`)}>
                <Button
                  loading={loading}
                  title={'Reset Password'}
                  onClick={this.submit}
                  disabled={btnDisabled}
                />

                <Link
                  css={css`
                    margin-top: 40px;
                  `}
                  onClick={this.goToLogin}
                >
                  {'Return to Sign In'}
                </Link>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export const ForgotPasswordSubmit = Form.create()(
  withRouter(FGPasswordComponent)
);
