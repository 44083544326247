/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Icon } from 'antd';
import styles from './gateway.styles';
import { userIsLoggedIn, getUrlParams, isLocal, userHasRole } from '../actions/utils';
import { getUserData } from '../authentication/authentication.actions';
import { getUser } from '../_shared/services/manage-users.service';
import { getPublicRoutes } from '../dashboard/dashboard.routes';

export class GateWayComponent extends Component {
  state = { thinking: true, redirect: null };
  componentDidMount = async () => {
    const { sState, history: { location } } = this.props;
    if (userIsLoggedIn(sState)) {
      this.props.getUserData()
        .then(async () => {
          if (location.pathname === '/validateUser') {
            const { email } = getUrlParams(window.location.href);
            const editAccount = await getUser(email);
            this.props.history.push('/dashboard/editAccount', {
              editAccount: { data: editAccount },
            });
            this.setState({ thinking: false });
          } else if (location.pathname.indexOf('/order/') > -1) {
            const [, id] = location.pathname.split('/order/');
            const isAdmin = userHasRole(0, sState);
            const isDriver = userHasRole(2, sState);
            //http://localhost:3000/order/f7314f8c-30be-448a-8739-921a2110f878
            this.redirect(`/dashboard/${isAdmin ? 'adminManageOrders' : isDriver ? 'manageSchedule' : 'orders'}?id=${id}`);
          } else {
            this.redirect('/dashboard')
          }
        })
        .catch(() => this.redirect('/dashboard'));
    } else {
      const publicRoutes = getPublicRoutes().map(r => r.path);

      if (publicRoutes.indexOf(location.pathname) > -1) {
        return this.redirect(`${location.pathname}${location.search}`);
      }

      if (location.pathname === '/signMeUp') {
        const { email } = getUrlParams(window.location.href);

        this.props.history.push('/signup', {
          email,
          fromLink: true,
        });
        this.setState({ thinking: false });
      } else {
        this.redirect('/login');
      }
    }
  };
  redirect = (loc) => {
    this.setState({ 
      redirect: isLocal ? undefined : loc, 
      thinking: false 
    });
  };
  render() {
    const { redirect, thinking } = this.state;

    if (redirect) {
      return <Redirect to={redirect || '/'} />;
    }

    return thinking ? (
      <div css={css(styles.thinkWrap)}>
        <Icon type="loading" css={css(styles.icon)} />
      </div>
    ) : null;
  }
}

export default connect(
  (sState) => ({ sState })
, {
  getUserData
})(withRouter(GateWayComponent));
