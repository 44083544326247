/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useState, useEffect } from 'react';
import { Icon, Input, message, Modal } from 'antd';
import { createInvite, getInvites, sendInviteEmail } from '../../_shared/services/accounts.service';
import { deleteUser as DeleteUser } from '../../_shared/services/manage-users.service';
import { get } from 'lodash';
import { useAuth } from '../../_shared/hooks/auth';
import { emailRegex } from '../../actions/utils';
import { TableList, TableListHeader } from '../../_shared/table-list';
import { watcherstyles } from './styles'; 
import moment from 'moment';
import { Button } from '../../_shared/button';

export const Invite = props => {
  const [state, setState] = useState({
    sending: false,
    loading: false,
    email: '',
    invites: [],
  })
  
  const { user } = useAuth();

  const retrieveInvites = async () => {
    try {
      setState(s => ({ ...s, loading: true }));
      const invites = await getInvites({ userId: user.id })
      setState(s => ({ ...s, invites }));
    } catch (err) {
      const error = get(err, 'response.data.error', err.message);
      message.error(`${error}`);
    } finally {
      setState(s => ({ ...s, loading: false }));
    }
  }

  const onChange = e => {
    const { value } = e.target;
    setState(s => ({ ...s, email: value }))   
  }

  const existsInList = email => {
    return !!state.invites.find(u => u.email == email && !get(u, 'data.notified'));
  }

  const sendInvite = async () => {
    try {
      const email = state.email.trim().toLowerCase();

      if (!emailRegex.test(email)) {
        return message.error('Email address is invalid');
      }

      if (existsInList(email)) {
        return message.error('You have already invited this user');
      }

      setState(s => ({ ...s, sending: true }));
      await createInvite({ 
        email,
        userId: user.id,
        data: {
          accepted: false,
        },
      });

      message.success('User invited successfully!');
    } catch (err) {
      const error = get(err, 'response.data.error', err.message);
      message.error(`${error}`);
    } finally {
      setState(s => ({ ...s, sending: false }));
    }
  }

  const resendInviteEmail = async (row) => {
    try {
      setState(s => ({ ...s, sending: true }));
      await sendInviteEmail({ 
        email: row.email,
        userId: user.id,
        data: {},
      });

      message.success("Email sent successfully! Please tell the user to check their spam folder if it doesn't show up within the next 30 minutes");
    } catch (err) {
      const error = get(err, 'response.data.error', err.message);
      message.error(`${error}`);
    } finally {
      setState(s => ({ ...s, sending: false }));
    }
  }

  const deleteUser = async (user) => {
    const { email, invited } = user;
    Modal.confirm({
      title: 'Are you sure?',
      content: `Are you sure you want to delete ${email}? This cannot be un-done.`,
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
        try {
          await DeleteUser(invited);
          retrieveInvites();  
        } catch (err) {
          message.error(`Failed to delete user: ${err.message}`);
        }
      },
      onCancel: () => {}
    });
  }

  useEffect(() => {
    retrieveInvites();
  }, []);

  return (
    <div>
      <div>Invite Users</div>

      <Input.Search 
        value={state.email} 
        onChange={onChange}
        enterButton="Invite"
        placeholder="Enter email address" 
        loading={state.sending} 
        onSearch={sendInvite}
      />

      <TableListHeader>
        Invited Users
      </TableListHeader>

      <TableList
        data={state.invites}
        canSelectRows={false}
        columns={[
          {
            title: 'Email',
            dataIndex: 'email',
            width: '30%'
          },
          {
            title: 'Date Invited',
            dataIndex: 'date',
            width: '30%',
            render: t => moment(t).format('L'),
          },
          {
            title: 'Accepted Invite',
            dataIndex: 'data.accepted',
            width: '15%',
            render: t => !t ? 'false' : 'true'
          },
          {
            title: 'Received Invite',
            dataIndex: 'data.notified',
            width: '15%',
            render: t => !t ? 'false' : 'true'
          },
          {
            title: 'Actions',
            dataIndex: 'data.accepteds',
            width: '10%',
            render: (t, r) => {
              const accepted = !!get(r, 'data.accepted');
              return (
                <div css={css(watcherstyles.buttonActions)}>
                  {(accepted && r.invited) && <Button type='primary' onClick={() => deleteUser(r)} hoverText="Delete User"><Icon type="delete" /></Button>}
                  {(!accepted && !get(r, 'data.notified')) && <Button type='primary' onClick={() => resendInviteEmail(r)} hoverText="Resend Email"><Icon type="mail" /></Button>}
                </div>
              )
            }
          }
        ]}
      />
    </div>
  )
}