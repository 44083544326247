import { connect } from 'react-redux';
import { Login as LoginComponent } from './login';
import { ForgotPassword as ForgotPasswordComponent } from './forgot-password';
import { Verify as VerifyComponent } from './verify';
import { ForgotPasswordSubmit as FGPasswordSubmitComponent } from './forgot-password-submit';
import { SignUp as SignUpComponent } from './sign-up';
import { ChangeEmail as ChangeEmailComponent } from './change-email';
import {
  signUp,
  verify,
  signIn,
  forgotPassword,
  getUserData,
  setCurrentUser,
} from './authentication.actions';

const Connected = connect(
  sState => ({ sState }),
  {
    signUp,
    verify,
    signIn,
    forgotPassword,
    getUserData,
    setCurrentUser,
  }
);

export const Login = Connected(LoginComponent);
export const ForgotPassword = Connected(ForgotPasswordComponent);
export const Verify = Connected(VerifyComponent);
export const ForgotPasswordSubmit = Connected(FGPasswordSubmitComponent);
export const SignUp = Connected(SignUpComponent);
export const ChangeEmail = Connected(ChangeEmailComponent);
