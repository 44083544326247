/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Component } from 'react'
import { message, Icon, DatePicker, Button, Input } from 'antd';
import { get, first, set, debounce } from 'lodash';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import styles from './billing.styles';
import { getSchedule, updateOrder } from '../../_shared/services/orders.service';
import { TableList, TableListHeader } from '../../_shared/table-list';
import Link from '../../_shared/link';
import colors from '../../_shared/colors';
import Styles from '../../_shared/styles';
import { OrderModal, orderSearch } from '../orders/orders.component';
import { jparse, size } from '../../actions/utils';
import { CashReturnIcon } from '../../_shared/CustomIcons';

const getCols = (_this, scheduleKey) => {
  return [
    {
      title: 'Order ID',
      dataIndex: 'orderId',
      width: '10%',
      render: (t, row) => {
        const order = get(row, '__order', row);
        return (
          <Link onClick={() => _this.selectOrder(order)}>{t}</Link>
        )
      }
    },
    {
      title: 'Quantity/Notes',
      dataIndex: 'data.order.notes',
      width: '1%',
      render: (t) => {
        return <span title={t}>{t}</span>
      }
    },
    {
      title: 'Billing Notes',
      dataIndex: 'data.order.billingNotes',
      width: '7%',
      render: (t, row) => {
        const isLoading = get(_this, 'state.saving') === row.id;
        const key = 'data.order.billingNotes';
        return (
          <div css={css(Styles.row)}>
            <Input value={t} onChange={e => _this.changeText(e.target.value, row, scheduleKey, key)} />
            {isLoading && <Icon css={css(`color: ${colors.highlight}`)} type="loading" />}
          </div>
        )
      }
    },
    {
      title: 'Box count',
      dataIndex: 'data.order.weight',
      width: '8%',
      render: (t, row) => {
        const isLoading = get(_this, 'state.saving') === row.id;
        const key = 'data.order.weight';
        return (
          <div css={css(Styles.row)}>
            <Input value={t} onChange={e => _this.changeText(e.target.value, row, scheduleKey, key)} />
            {isLoading && <Icon css={css(`color: ${colors.highlight}`)} type="loading" />}
          </div>
        )
      }
    },
    {
      title: 'Vendor',
      dataIndex: 'data.pickupCompany.Licensee',
      width: '15%',
      render: (t, row) => {
        const name = 'pickupCompany';
        const compName = get(row, `data.${name}.DBA`, get(row, `data.${name}.Licensee`));
        const compAddress = get(row, `data.${name}.Street Address`);
        const text = `${compName || ''}${compAddress ? ' - ' + compAddress : ''}`;
        return <span>{text}</span>
      }
    },
    {
      title: 'Destination',
      dataIndex: 'data.destCompany.Street Address',
      width: '15%',
      render: (t, row) => {
        const isPickup = get(row, 'isPickup');
        const name = isPickup ? 'pickupCompany' : 'destCompany';
        const compName = get(row, `data.${name}.DBA`, get(row, `data.${name}.Licensee`));
        const compAddress = get(row, `data.${name}.Street Address`);
        const text = isPickup ? 'GPS' : `${compName || ''}${compAddress ? ' - ' + compAddress : ''}`;
        return <span>{text}</span>
      }
    },
    {
      title: 'City',
      dataIndex: 'data.destCompany.City',
      width: '5%',
      render: (t, row) => {
        const isPickup = get(row, 'isPickup');
        const text = isPickup ? get(row, 'data.pickupCompany.City') : get(row, 'data.destCompany.City');
        return <span>{text}</span>
      }
    },
    //driver blanks
    {
      title: 'Invoice',
      dataIndex: 'data.billing.invoice',
      width: '8%',
      render: (t, row) => {
        const key = 'data.billing.invoice';
        return (
          <Input value={t} onChange={e => _this.changeText(e.target.value, row, scheduleKey,  key)} />
        )
      }
    },
    {
      title: 'Amount',
      dataIndex: 'data.billing.amount',
      width: '8%',
      render: (t, row) => {
        const key = 'data.billing.amount';
        return (
          <Input value={t} onChange={e => _this.changeText(e.target.value, row, scheduleKey, key)} />
        )
      }
    },
    {
      title: 'Method',
      dataIndex: 'data.billing.method',
      width: '8%',
      render: (t, row) => {
        const isLoading = get(_this, 'state.saving') === row.id;
        const key = 'data.billing.method';
        return (
          <div css={css(Styles.row)}>
            <Input value={t} onChange={e => _this.changeText(e.target.value, row, scheduleKey, key)} />
            {isLoading && <Icon css={css(`color: ${colors.highlight}`)} type="loading" />}
          </div>
        )
      }
    },
    {
      title: 'Manifest',
      dataIndex: 'data.billing.manifest',
      width: '8%',
      render: (t, row) => {
        const isLoading = get(_this, 'state.saving') === row.id;
        const key = 'data.billing.manifest';
        return (
          <div css={css(Styles.row)}>
            <Input value={t} onChange={e => _this.changeText(e.target.value, row, scheduleKey, key)} />
            {isLoading && <Icon css={css(`color: ${colors.highlight}`)} type="loading" />}
          </div>
        )
      }
    },
    {
      title: 'GPS Invoice',
      dataIndex: 'data.billing.gpsinvoice',
      width: '8%',
      render: (t, row) => {
        const isLoading = get(_this, 'state.saving') === row.id;
        const key = 'data.billing.gpsinvoice';
        return (
          <div css={css(Styles.row)}>
            <Input value={t} onChange={e => _this.changeText(e.target.value, row, scheduleKey, key)} />
            {isLoading && <Icon css={css(`color: ${colors.highlight}`)} type="loading" />}
          </div>
        )
      }
    }
  ]
}

export const filterSchedule = (searchTerm = '', originalSchedule) => {
  const passes = {};

  if (!searchTerm) { return originalSchedule }

  const val = (searchTerm || '').toLowerCase();

  Object.keys(originalSchedule).map(k => {
    const { route, orders } = originalSchedule[k];

    const _orders = orderSearch(orders, val);

    const rname = get(route, 'name', '').toLowerCase();
    if (rname.indexOf(val) > -1 || size(_orders)) {
      set(passes, k, {
        route,
        orders: size(_orders) ? _orders : orders,
      })
    }
  })

  return passes;
}

export class BillingComponent extends Component {
  state = {
    loadingSchedule: false,
    selectedDate: moment(),
    originalSchedule: {},
    schedule: {},
    today: moment(),
    saving: false,
    printOnly: undefined,
    editModal: undefined,
    isAdmin: true,
  }
  componentDidMount = () => {
    this.loadSchedule();
  }
  onSelectDay = (date) => {
    this.setState({ selectedDate: date ? moment(date) : null }, this.loadSchedule);
  }
  saveOrder = debounce(async (order) => {
    this.setState({ saving: order.id });
    await updateOrder(order).catch(err => console.log(err));
    if (this.state.saving === order.id) {
      this.setState({ saving: false });
    }
  }, 3000, { leading: false, trailing: true })
  changeText = (text, row, scheduleKey, textKey) => {
    const schedOrders = get(this, `state.schedule.${scheduleKey}.orders`, []);
    const orderIndex = schedOrders.findIndex(o => o.id === row.id);
    set(schedOrders[orderIndex], textKey, text);
    const order = {
      ...get(row, ['__order'], row),
    }
    set(order, textKey, text);
    this.setState({ ...this.state }, () => this.saveOrder(order));
  }
  loadSchedule = async () => {
    try {
      const { selectedDate, isAdmin } = this.state;
      if(!selectedDate) return;

      this.setState({ loadingSchedule: true });
      const day = selectedDate.toDate();
      const schedule = await getSchedule({ day, isAdmin });
      this.setState({ loadingSchedule: false, schedule, originalSchedule: schedule });
    } catch (err) {
      message.error(`Error: ${err.message}`);
      this.setState({ loadingSchedule: false, schedule: {}, originalSchedule: {} });
    }
  }
  isSameDate = (o, sel) => {
    return moment(get(o, sel)).isSame(this.state.selectedDate, 'day');
  }
  pulloutPickups = () => {
    const { schedule } = this.state;
    
    const pickups = {};

    Object.keys(schedule).map(k => {
      const { orders } = schedule[k];
      orders.map(o => {
        const otype = get(o, 'data.order.orderType');
        const proute = get(o, 'data.order.pickupRoute')

        const pr = jparse(proute);
        if (get(pr, 'zipCode') == '00000' && get(pr, 'city', '').indexOf('Utility') === -1 && proute && this.isSameDate(o, 'data.order.pickupDate')) {
          set(pickups, pr.city, [
            ...get(pickups, pr.city, []),
            o
          ])
        } else if (otype === 'dropoff' && this.isSameDate(o, 'data.order.dropoffDate')) {
          const sel = 'Warehouse Drop';
          set(pickups, sel, [
            ...get(pickups, sel, []),
            o,
          ])
        }
      })
    })

    return pickups;
  }
  notCashReturn = o => {
    return !get(o, 'data.order.cashRoute');
  }
  pullOutPickupTypes = (orders) => {
    return {
      regular: orders.filter(o => {
        const ptype = get(o, 'data.order.pickupType') === 'regular' || (!get(o, 'data.order.pickupType') && get(o, 'data.order.orderType') !== 'dropoff');
        return ptype && this.isSameDate(o, 'data.order.deliveryDate') && this.notCashReturn(o);
      }),
      warehouse: orders.filter(o => {
        const ptype = get(o, 'data.order.pickupType');
        const otype = get(o, 'data.order.orderType');
        const isLayover = (ptype === 'warehouse' || ptype === 'layoverpickup' || (otype === 'dropoff' && !ptype)) && this.isSameDate(o, 'data.order.deliveryDate');
        return isLayover && this.notCashReturn(o);// || get(o, 'data.order.pickupType') === 'warehouse';
      }),
      // layover: orders.filter(o => {
      //   return get(o, 'data.order.pickupType') === 'layoverpickup' && moment(get(o, 'data.order.deliveryDate')).isSame(this.state.selectedDate, 'day');
      // }),
      layoverpickup: orders.filter(o => {
        return get(o, 'data.order.pickupType') === 'layoverpickup' && this.isSameDate(o, 'data.order.pickupDate') && this.notCashReturn(o);;
      }).map(o => ({ __order: { ...o }, ...o, route: jparse(get(o, 'data.order.pickupRoute')), isPickup: true })),
      dropoff: orders.filter(o => get(o, 'data.order.orderType') === 'dropoff'),
      cashreturn: orders.filter(o => !this.notCashReturn(o)),
    }
  }
  passesIgnore = (key) => {
    const ignore = ['Cash', 'Warehouse'];
    return size(ignore.filter(ig => key.indexOf(ig) > -1)) === 0;
  }
  getScheduleMap = () => {
    const { schedule } = this.state;

    const pickups = this.pulloutPickups();
    const warehouses = Object.keys(pickups).map(k => pickups[k]).map(o => o.id);

    const sched = {};

    Object.keys(schedule).map(k => {
      const item = schedule[k];
      const { layoverpickup, regular, warehouse } = this.pullOutPickupTypes(item.orders);

      const schedkey = get(item, 'route.city', k);

      const layids = layoverpickup.map(lo => lo.id);
      const lps = [...layoverpickup, ...regular, ...warehouse];
      if (size(lps)) {
        layoverpickup.filter(lp => warehouses.indexOf(lp.id) === -1).map(lo => {
          const routename = get(lo, 'route.city', 'Default');
          const sel = routename;
          const ordsel = `${sel}.orders`;
          set(sched, [sel, 'route'], lo.route);
          set(sched, [sel, 'isPickup'], true);
          set(sched, ordsel, [
            ...get(sched, ordsel, []),
            lo,
          ])
        })
      
        const otherorders = get(sched, [schedkey, 'orders'], []);
        const orders = [...otherorders, ...item.orders];
        sched[schedkey] = { ...item, orders: orders.filter(o => layids.indexOf(o.id) === -1) }
      }
    })

    return sched;
  }
  selectOrder = (editOrder) => {
    const cashReturn = !this.notCashReturn(editOrder);
    this.setState({
      editModal: {
        isModal: true,
        state: {
          cashReturn,
          editOrder,
        }
      }
    });
  }
  orderFromSchedule = (sched, cashReturn = false) => {
    this.props.history.push('/dashboard/adminEditOrder', {
      isNewAdminOrder: true,
      cashReturn,
      editOrder: cashReturn ? {} : {
        data: {
          ...get(first(sched.orders), 'data', {}),
        }
      },
    })
  }
  renderWarehouse = () => {
    const { loadingSchedule, selectedDate, printOnly } = this.state;

    const pickups = this.pulloutPickups();

    if (!size(pickups) || loadingSchedule) { return null };

    const tProps = {
      implementScroll: false,
      columns: getCols(this),
      showPagination: false,
      canSelectRows: false,
    }

    return (
      <div css={css(styles.routeDivider)} className={printOnly && printOnly.indexOf('__warehouse__') === -1 ? 'no-print printNoMarg' : 'printNoMarg'}>
        <TableListHeader css={css(styles.routeTableHeader)}>
          <div css={css(styles.routeHeader)}>{`Warehouse - ${moment(selectedDate).format('L')}`}</div>
        </TableListHeader>

        {Object.keys(pickups).map(k => {
          const orders = pickups[k];
          return (
            <div className={printOnly && printOnly !== `${k}__warehouse__` ? 'no-print printNoMarg' : "printNoMarg"} css={css(styles.margB10)}>
              <TableListHeader>
                <div className="margHeader" css={css(styles.pickTypeHeader)}>{k}</div>
                <div css={css(styles.printOnly)}>
                  <Button
                    onClick={() => this.setState({ printOnly: `${k}__warehouse__` }, this.print)}
                    css={css(styles.printOnly)}
                    className="no-print"
                    type="primary"
                    icon="printer"
                  />
                </div>
              </TableListHeader>
              <TableList {...tProps} data={orders} />
            </div>
          )
        })}
      </div>
    )
  }
  renderSchedule = () => {
    const { loadingSchedule, printOnly, selectedDate, isAdmin } = this.state;

    const schedule = this.getScheduleMap();

    if (loadingSchedule) {
      return <Icon type="loading" />
    }

    if (size(schedule) === 0) {
      return <div css={css(styles.noOrders)}>No Orders found. Please select a different date.</div>
    }

    const tProps = {
      implementScroll: false,
      columns: getCols(this),
      showPagination: false,
      canSelectRows: false,
    }

    return (
      <div>
        {Object.keys(schedule).map(k => {
          const { regular, warehouse, layoverpickup, cashreturn } = this.pullOutPickupTypes(get(schedule, [k, 'orders']));

          if (!this.passesIgnore(k)) { return null }

          const amt = [
            ...regular,
            ...warehouse,
            ...layoverpickup,
            ...cashreturn
          ]

          const sched = get(schedule, k);

          return !size(amt) ? null : (
            <div key={k} css={css(styles.routeDivider)} className={printOnly && printOnly !== k ? 'no-print printNoMarg' : 'printNoMarg'}>
              <TableListHeader css={css(styles.routeTableHeader)}>
                <div css={css(styles.routeHeader)}>{`${get(sched, ['route', 'name'], '')} - ${moment(selectedDate).format('L')}`}</div>
                <div css={css(styles.printOnly)}>
                  <Button
                    onClick={() => this.setState({ printOnly: k }, this.print)}
                    css={css(styles.printOnly)}
                    className="no-print"
                    type="primary"
                    icon="printer"
                  />
                  {isAdmin && <Button 
                    onClick={() => this.orderFromSchedule(schedule[k])}
                    css={css(styles.printOnly)}
                    type={"dashed"}
                    icon="plus"
                    title={'New order from this route'}
                    className="no-print"
                  />}
                  {isAdmin && <Button 
                    onClick={() => this.orderFromSchedule(schedule[k], true)}
                    css={css(styles.printOnly)}
                    type={"dashed"}
                    title={'Cash Return'}
                    className="no-print"
                  >
                    <CashReturnIcon />
                  </Button>}
                </div>
              </TableListHeader>
              
              {size(regular) > 0 && <div className="printNoMarg" css={css(styles.margB10)}>
                <div className="margHeader" css={css(styles.pickTypeHeader)}>{"Same Day Pickup"}</div>
                <TableList {...tProps} data={regular} />
              </div>}
              {size(warehouse) > 0 && <div className="printNoMarg" css={css(styles.margB10)}>
                <div className="margHeader" css={css(styles.pickTypeHeader)}>{"Warehouse Pickup"}</div>
                <TableList {...tProps} data={warehouse} />
              </div>}
              {size(layoverpickup) > 0 && <div className="printNoMarg" css={css(styles.margB10)}>
                <div className="margHeader" css={css(styles.pickTypeHeader)}>{"Layover Pickup"}</div>
                <TableList {...tProps} data={layoverpickup} />
              </div>}
              {size(cashreturn) > 0 && <div className="printNoMarg" css={css(styles.margB10)}>
                <div className="margHeader" css={css(styles.pickTypeHeader)}>{"Cash Return"}</div>
                <TableList {...tProps} data={cashreturn} />
              </div>}
            </div>
          )
        })}
      </div>
    )
  }
  print = () => {
    window.print();
  }
  filterSchedule = (searchTerm = '') => {
    this.setState({ schedule: filterSchedule(searchTerm, this.state.originalSchedule) })
  }
  render() {
    const {
      loadingSchedule,
      schedule,
      selectedDate,
      editModal,
    } = this.state;

    const canShowPrint = schedule && size(schedule) > 0 && !loadingSchedule && selectedDate;

    return (
      <div className="scheduleWrap" css={css(styles.container)}>
        <div className="no-print" css={css(styles.calContainer)}>
          <Input.Search
            style={{ width: '30%' }}
            placeholder={'Search by Order ID, or Route Name'}
            enterButton
            onSearch={this.filterSchedule}
          />
          <DatePicker 
            css={css('width: 40%;margin: 0 auto;')}
            defaultValue={selectedDate}
            loading={loadingSchedule}
            onChange={this.onSelectDay}
          />

          {canShowPrint && 
            <Button 
              onClick={() => this.setState({ printOnly: undefined }, this.print)} 
              css={css(styles.printBtn)} 
              className="no-print"
              type="primary" 
              icon="printer"
            >
              Print
            </Button>}
        </div>

        <div css={css(styles.schedContainer)}>
          {this.renderSchedule()}
          {this.renderWarehouse()}
        </div>
        <OrderModal editModal={editModal} onClose={this.onCloseOrder} />
      </div>
    )
  }
}

export const Billing = withRouter(BillingComponent);
