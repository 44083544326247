/** @jsx jsx */
import { css, jsx, Global } from '@emotion/core';
import { Component } from 'react';
import { Layout, Menu } from 'antd';
import { withRouter } from 'react-router';
import styles, { GlobalInserts } from './header.styles';
import Logo from '../../_shared/logo';
import { MenuButton } from '../../_shared/menubutton';
import { Ellipsis } from './ellipsis.component';
import { userIsLoggedIn } from '../../actions/utils';
import { connect } from 'react-redux';
import ScreenComponent from '../../_shared/hooks/useScreen';

export class HeaderComponent extends Component {
  logoClick = () => {
    this.props.history.push('/dashboard');
  };
  render() {
    const { routes, changeRoute, ellipsisRoutes, sState } = this.props;

    const loggedIn = userIsLoggedIn(sState);

    return (
      <Layout.Header className="no-print" css={css(styles.container)}>
        <Global styles={GlobalInserts} />
        {this.props.screen.isMobile && loggedIn ? <MenuButton /> : <Logo
          small={true}
          style={{ margin: 10, marginLeft: 20, marginRight: 20 }}
          onClick={loggedIn ? this.logoClick : () => 0}
        />}
        {loggedIn && <Menu
          mode="horizontal"
          defaultSelectedKeys={['0']}
          css={css(styles.container)}
        >
          {routes.map((route, i) => {
            return (
              <Menu.Item key={i} onClick={() => changeRoute(route)}>
                {route.title}
              </Menu.Item>
            );
          })}
        </Menu>}
        <Ellipsis {...this.props} routes={ellipsisRoutes} />
      </Layout.Header>
    );
  }
}

export const Header = ScreenComponent(withRouter(connect((state) => ({ sState: state }))(HeaderComponent)));
