/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Button } from '../../_shared/button';
import { Modal, message } from 'antd';
import { deleteUser } from '../../_shared/services/manage-users.service';
import { useAuth } from '../../_shared/hooks/auth';
import { getConfig } from '../../actions/utils';

export const DeleteAccount = props => {
  const { user, logout } = useAuth();
  const deleteAccount = () => {
    Modal.confirm({
      title: 'Delete account',
      content: 'Are you sure you want to delete your account? This cannot be un-done!',
      onOk: async () => {
        try {
          await deleteUser(user);
          logout();
        } catch (err) {
          message.error(err.message);
        }
      },
    })
  }
  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: getConfig('account.deleteText', 'Are you sure?') }} />
      <Button title="Delete Account" type="danger" icon="delete" onClick={deleteAccount} />
    </div>
  )
}