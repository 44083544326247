import React from 'react';
import { userHasRole, userIsLoggedIn } from "../../actions/utils";
import { useSelector } from 'react-redux';
import { logout } from '../../authentication/authentication.actions';

export const useAuth = props => {
  const sState = useSelector(state => state);
  const loggedIn = userIsLoggedIn(sState);

  return {
    user: sState.user,
    loggedIn,
    userHasRole: (ids) => userHasRole(ids, sState),
    logout,
  }
}