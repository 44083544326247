/** @jsx jsx */
import { useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { EditableFormTable } from '../../../_shared/editable-table';
import { size } from 'lodash';
import { guid } from '../../../actions/utils';
import { deleteDriver, getDrivers, updateDriver } from '../../../_shared/services/drivers.service';

export const COLS = [
  {
    title: 'Name',
    dataIndex: 'name',
    editable: true
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    editable: true
  },
  {
    title: 'Email',
    dataIndex: 'email',
    editable: true
  },
  {
    title: 'Drivers License',
    dataIndex: 'lic',
    editable: true
  },
  {
    title: 'Occupation Lic/Employee ID',
    dataIndex: 'occLic',
    editable: true
  },
]

export const Drivers = props => {
  const [rows, setRows] = useState([]);

  const getData = async () => {
    const data = await getDrivers();
    setRows(data);
  }

  useEffect(() => { getData(); }, []);

  const updateData = async d => {
    const newrows = d.map(r => r.id);
    const missingrows = rows.filter(r => newrows.indexOf(r.id) === -1);

    setRows([ ...d ]);
    await Promise.all(d.map(async dd => {
      await updateDriver(dd)
    }))

    if (!!size(missingrows)) {
      await Promise.all(missingrows.map(async dd => {
        await deleteDriver(dd)
      }))
    }
  }

  const addRow = () => {
    setRows([
      ...rows,
      {
        id: guid(),
        name: '',
        phone: '',
        email: '',
        lic: '',
        occLic: '',
      }
    ])
  }

  return (
    <div>
      <EditableFormTable 
        data={rows}
        columns={COLS}
        updateData={updateData}
        addRow={addRow}
        rowKey={'id'}
        addButton={'Add Driver'}
      />
    </div>
  )
}