/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import { AutoComplete, Tag } from 'antd';
import { get } from 'lodash';
import colors from '../colors';

export class MultiSelectSearchComponent extends Component {
  constructor(props) {
    super(props);

    const state = {
      value: '',
      selected: props.selected || [],
      dataSource: props.dataSource,
    };

    if (state.dataSource && state.dataSource.then) {
      state.dataSource = [];
      props.dataSource.then(res => {
        this.setState({ dataSource: res })
      });
    }
    this.state = state;
  }
  tagChange = () => {
    if (this.props.onTagsChange) {
      this.props.onTagsChange(this.state.selected);
    }
  };
  onSelect = (value) => {
    setTimeout(() => {
      const state = { ...this.state };
      state.value = '';
      state.selected = [...state.selected, value];
      const index = state.dataSource.indexOf(value);
      state.dataSource.splice(index, 1);
      this.setState(state, this.tagChange);
    }, 10);
  };
  removeSelected = (removedTag) => {
    const state = { ...this.state };
    state.selected = state.selected.filter(tag => tag !== removedTag);
    state.dataSource.push(removedTag);
    this.setState(state, this.tagChange);
  };
  onChange = (value) => {
    this.setState({ value: value });
  };
  clearTags = () => {
    this.state.selected = [];
    this.setState(this.state);
  };
  componentDidMount = () => {
    if (this.props.ref) {
      this.props.ref(this);
    }
  };
  componentDidUpdate(prevProps) {
    if(prevProps.selected !== this.props.selected) {
      this.setState({ selected: this.props.selected });
    }
  }
  render() {
    const { selected, value, dataSource } = this.state;
    const { placeholder } = this.props;

    const props = {
      dataSource,
      placeholder,
      value,
      onSelect: this.onSelect,
      onChange: this.onChange,
    };

    return (
      <div
        css={css`
          width: 100%;
        `}
      >
        <AutoComplete
          css={css`
            width: 100%;
          `}
          {...props}
          filterOption={(inputValue, option) =>
            get(option, 'props.children', '')
              .toUpperCase()
              .indexOf(inputValue.toUpperCase()) !== -1
          }
        />
        <div
          css={css`
            margin-top: 5px;
            align-items: flex-start;
            display: flex;
          `}
        >
          {selected.map(sel => {
            return (
              <Tag
                css={css`
                  margin-bottom: 5px;
                `}
                color={colors.tagDark}
                key={sel}
                closable
                onClose={() => this.removeSelected(sel)}
              >
                {sel}
              </Tag>
            );
          })}
        </div>
      </div>
    );
  }
}

MultiSelectSearchComponent.defaultProps = {
  placeholder: 'Search...',
  dataSource: [],
  onTagsChange: () => 0,
};
