export default {
  container: `
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;

    .print-only {
      overflow: hidden;
      font-size: 12px;

      table td {
        font-size: 12px;
      }
    }
  `,
  calContainer: `
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    width: 90%;
  `,
  input: `
    width: 40%;
    margin-right: 10px;
  `,
  printBtn: `
    position: absolute;
    right: 10px;
    z-index: 100;
  `,
  printOnly: `
    margin-right: auto;
    margin-left: 10px;
  `,
  row: `
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `,
  headerRow: `
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
  `,
  headerSub: `
    font-size: 16px;
    margin-bottom: 10px;
  `,
  printLowerText: `
    text-align: left;
    font-size: 12px;
  `,
  sig: `
    font-weight: bold;
  `,
  ellipsis: `
    width: 25px; 
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `
}