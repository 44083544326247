/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useState, useEffect } from 'react';
import { useAuth } from '../../_shared/hooks/auth';
import { Modal } from 'antd';
import { get, set, size, uniq } from 'lodash';
import { getInvites, updateInvites } from '../../_shared/services/accounts.service';
import { updateUser } from '../../_shared/services/manage-users.service';
import { watcherstyles as styles } from './styles';
import { TableList, TableListHeader } from '../../_shared/table-list';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { getUserData } from '../../authentication/authentication.actions';
import { retrieveData } from '../dashboard.actions';
import { cleanCopy } from '../../actions/utils';

export const InviteWatcher = props => {
  const [state, setState] = useState({
    invites: [],
    selected: [],
  });
  const { user } = useAuth();
  const dispatch = useDispatch();

  const email = get(user, 'email', '').toLowerCase();

  const retrieveInvites = async () => {
    try {
      const allinvites = await getInvites({ email });
      const invites = (allinvites || []).filter(inv => !get(inv, 'data.notified'));
      setState(s => ({ ...s, invites, selected: invites }));
    } catch (err) {
      console.warn(err);
    }
  }

  const sendUpdates = async () => {
    let newlns = [ ...get(user, 'company.licenseNumbers', []) ];

    const newinvites = state.invites.map((inv, i) => {
      const sel = state.selected.find(s => s.id === inv.id);

      set(inv, 'data.notified', true);

      if (sel) {
        set(inv, 'data.accepted', true);
        const lns = get(inv, 'user.company.licenseNumbers', []);
        const ln = get(inv, 'user.company.License #', []);
        newlns = [
          ...newlns,
          ...lns,
          ln,
        ]
      }

      return {
        ...inv,
        user: undefined,
      };
    })
    
    newlns = uniq(newlns);

    await updateInvites(newinvites);
    const u = cleanCopy(user);
    set(u, 'company.licenseNumbers', newlns);
    await updateUser(u);
    await dispatch(getUserData());
    await dispatch(retrieveData());
  }

  const onSelectedRowsChange = (keys, rows) => {
    setState(s => ({ ...s, selected: rows }));
  }

  const onClose = c => {
    console.log('onClose');
    sendUpdates();
    setState(s => ({ ...s, invites: [], selected: [] }));
  }

  useEffect(() => {
    if (email) {
      retrieveInvites();
    }
  }, [email]);

  return (
    <Modal 
      title="Invites" 
      onOk={onClose} 
      onCancel={onClose}
      width={'80%'} 
      visible={!!size(state.invites)}
    >
      <div css={css(styles.container)}>
        <TableListHeader>
          <div>Select checkboxes next to rows to accept the invite. If none are selected, these invites will be dismissed.</div>
        </TableListHeader>

        <TableList
          data={state.invites}
          onSelectedRowsChange={onSelectedRowsChange}
          showPagination={false}
          rowKey="id"
          selectedRowKeys={state.selected.map(k => k.id)}
          columns={[
            {
              title: 'Invited By',
              dataIndex: 'user.email',
              width: '40%',
              render: (t, r) => {
                return `${get(r, 'user.email')} - ${get(r, 'user.name')}`;
              }
            },
            {
              title: 'License Numbers',
              dataIndex: 'ln',
              width: '40%',
              render: (t, r) => {
                const lns = uniq([
                  get(r, 'user.License #', ''),
                  ...get(r, 'user.company.licenseNumbers', []),
                ]).filter(f => !!f);
                return lns.join(', ');
              }
            },
            {
              title: 'Date Invited',
              dataIndex: 'date',
              width: '20%',
              render: t => moment(t).format('L'),
            },
          ]}
        />
      </div>
    </Modal>
  );
}