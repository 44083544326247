export default () => {
  return {
    signIn: 'Sign in to your account',
    signUp: 'Sign up for your account',
    emailLabel: 'Email',
    passwordLabel: 'Password',
    forgotPasswordBtn: 'Forgot Password',
    button: 'Sign In',
    buttonSignUp: 'Sign Up',
  };
};
