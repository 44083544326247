import React, { Component } from 'react';
import './input-label.styles.css';
import { Input } from 'antd';

export class InputLabel extends Component {
  onChange = (e) => {
    this.props.onChange(e);
  };
  render() {
    const { label, value, disabled, type, inputProps } = this.props;

    const COMP = type === 'textarea' ? Input.TextArea : Input;

    return (
      <div className="ant-row ant-form-item">
        <label className="ant-col ant-form-item-label">{label}</label>
        <div className="ant-col ant-form-item-control-wrapper">
          <COMP {...inputProps} disabled={disabled} onChange={this.onChange} value={value} />
        </div>
      </div>
    );
  }
}
