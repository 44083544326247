import { connect } from 'react-redux';
import { Dashboard as DashboardComponent } from './dashboard.component';
import { retrieveData } from './dashboard.actions';
import { logout } from '../authentication/authentication.actions';

export const Dashboard = connect(
  (state) => ({ sState: state }),
  {
    logout,
    retrieveData,
  }
)(DashboardComponent);
