import React, { useState, useEffect } from 'react';
import { debounce, get } from 'lodash';

function getSize() {
  return {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    outerHeight: window.outerHeight,
    outerWidth: window.outerWidth,
  };
}

export const useScreen = () => {
  const [windowSize, setWindowSize] = useState(getSize());

  function handleResize() {
    setWindowSize(getSize());
  }

  useEffect(() => {
    let isSubscribed = true;
    window.addEventListener('resize', debounce(() => {
      if (isSubscribed) { 
        handleResize();
      }
    }, 200));

    return () => {
      isSubscribed = false;
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    windowSize,
    isMobile: get(windowSize, 'innerWidth', 0) <= 700,
  };
}

export default Component => props => {
  const screen = useScreen();

  return (
    <Component 
      {...props}
      screen={screen}
    />
  )
}