import React, { useState, useEffect, useRef } from "react"
import { GPSID, getDirections, getGeoCode, getLabelFromAlpha, getLicAddress, getLicName, makeDirRenderer, makeMap, makeMarker } from "../../actions/utils"
import { Checkbox, Modal } from "antd";
import { get, isObject, last, orderBy, size } from "lodash";
import Row from '../../_shared/row';
import { Button } from "../../_shared/button";
import { SortableList } from '../../_shared/sortable-list';
import { useScreen } from "../../_shared/hooks/useScreen";
import { getMapState, updateMapState } from '../../_shared/services/maps.service';

// https://www.google.com/maps/dir/33.93729,-106.85761/33.91629,-106.866761/33.98729,-106.85861//@34.0593359,-106.7131944,11z

const BUFFER = {
  mobile: 80,
  desktop: 145,
};

export const Map = props => {
  const { isMobile, windowSize } = useScreen();

  const addresses = [{ address: getLicAddress(GPSID), name: getLicName(GPSID) }, ...props.addresses];

  const [state, setState] = useState({
    map: undefined,
    dirRenderer: undefined,
    markers: [],
    
    mapstate: undefined,
    hasLoaded: false,
    dirEnabled: false,
  })
  
  const dirEnabled = state.dirEnabled;

  const mapref = useRef();

  const loadAddresses = async () => {
    const markers = await Promise.all(addresses.map(async (addr, i) => {
      const address = get(addr, 'address', addr);
      const mark = await getGeoCode({ address });
      
      const marker = makeMarker({
        map: state.map,
        position: mark.geometry.location,
      })

      return {
        ...(isObject(addr) ? addr : { address: addr }),
        ...mark,
        marker,
      };
    }))

    state.map.setCenter(get(last(markers), 'geometry.location'));
    state.map.setZoom(10);

    const dirRenderer = makeDirRenderer({ suppressMarkers: true });
    dirRenderer.setMap(state.map);

    setState(s => ({ ...s, markers: markers, dirRenderer }));
  }

  const makeUrl = () => {
    const getUrlLoc = l => {
      return `${get(l, 'geometry.location.lat')()},${get(l, 'geometry.location.lng')()}`
    }

    return `https://www.google.com/maps/dir/${state.markers.filter(f => !f.deleted).map(m => getUrlLoc(m)).join('/')}`;////@${getUrlLoc(state.markers[0])},11z`;
  }

  const onSort = ord => {
    setState(s => ({ 
      ...s, 
      markers: ord
    }))
  }

  const openUrl = () => {
    window.open(makeUrl(), '_blank');
  }

  const deleteMarker = m => {
    setState(s => ({ ...s, markers: s.markers.map(f => f.place_id === m.place_id ? { ...f, deleted: true } : f) }))
  }

  const addMarkerBack = m => {
    setState(s => ({ ...s, markers: s.markers.map(f => f.place_id === m.place_id ? { ...f, deleted: false } : f) }))
  }

  const loadMapState = async () => {
    if (props.id) {
      const mapstate = await getMapState(props.id);
      setState(s => ({ ...s, mapstate }));
      console.log(mapstate);
    }
  }

  const loadDirections = async () => {
    if (dirEnabled) {
      state.dirRenderer.setMap(state.map);
      const validmarkers = state.markers.filter(f => !f.deleted);
      let origin;
      let destination;
      let waypoints = [];
  
      const possel = 'geometry.location';
  
      validmarkers.map((mark, i) => {
        if (i === 0) {
          origin = get(mark, possel);
        } else if (i === validmarkers.length - 1) {
          destination = get(mark, possel);
        } else {
          waypoints.push({ location: get(mark, possel) });
        }
      })
  
      const directions = await getDirections({
        origin,
        destination,
        waypoints,
        travelMode: 'DRIVING',
      })
  
      state.dirRenderer.setDirections(directions)
    } else {
      state.dirRenderer.setMap(null);
    }
  }

  useEffect(() => {
    if (size(state.markers) && state.mapstate && !state.hasLoaded) { //loadstate
      const mapaddrs = get(state.mapstate, 'data.addresses', []);

      setState(s => {
        const markers = orderBy(s.markers.map((m, i) => { 
          const findmatch = mapaddrs.find(ma => ma.address == m.address);
          const deleted = get(findmatch, 'deleted', false);
          const order = get(findmatch, 'order', i);
          return {
            ...m,
            order,
            deleted,
          }
        }), 'order');

        return {
          ...s, 
          hasLoaded: true,
          markers,
        }
      });
    }

    if (props.id && state.mapstate && state.hasLoaded) { //savestate
      const addrs = addresses.map(addr => {
        const address = addr.address || addr;

        const order = state.markers.findIndex(m => m.address == address);
        const marker = state.markers[order];

        return {
          address,
          deleted: marker.deleted,
          order,
        }
      })

      updateMapState({ id: props.id, data: { addresses: addrs } });
    }

    if (size(state.markers) && state.hasLoaded) {
      loadDirections(); 
    }
  }, [state.markers, state.mapstate, state.hasLoaded, dirEnabled]);

  useEffect(() => {
    setState(s => ({
      ...s,
      map: makeMap(mapref.current),
    }))

    loadMapState();
  }, []);

  useEffect(() => {
    if (!!state.map) { loadAddresses(); }
  }, [state.map]);

  const getHeight = (mult) => {
    return (windowSize.innerHeight * mult) - (isMobile ? BUFFER.mobile : BUFFER.desktop);
  }

  const validmarkers = state.markers.filter(f => !f.deleted);

  const markers = state.markers.map((m, i) => { 
    const { deleted } = m;
    const label = deleted ? 'deleted' : `${validmarkers.findIndex(v => m.place_id == v.place_id) + 1}`;
    if (!deleted) {
      m.marker.setMap(state.map);
      m.marker.setLabel(label);
    } else {
      m.marker.setMap(null);
    }
    return {
      ...m, 
      key: m.place_id, 
      disabled: m.deleted,
      content: (
        <Row style={{ width: '100%', opacity: m.deleted ? 0.4 : 1 }}>
          <span>{`${label}: ${m.name}`}</span>
          <div style={{ marginLeft: 'auto' }}>
            <Button style={{ marginLeft: 5 }} icon={m.deleted ? "plus" : "delete"} size="small" onClick={m.deleted ? () => addMarkerBack(m) : () => deleteMarker(m)} />
          </div>
        </Row>  
      )
    }
  });

  return (
    <div>
      <Row style={{ marginBottom: 5 }}>
        <Button onClick={openUrl} icon="export" title="Open in Google Maps" style={{ marginRight: 10, }} />
        <Checkbox checked={state.dirEnabled} onChange={e => setState(s => ({ ...s, dirEnabled: !s.dirEnabled }))}>Show Directions</Checkbox>
      </Row>
      <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
        <div style={{ width: isMobile ? '100%' : '25%', paddingRight: isMobile ? 0 : 10, height: getHeight(isMobile ? 0.45 : 1), overflowY: 'scroll' }}>
          <SortableList items={markers} onSort={onSort} />
        </div>
        <div id="MAP" ref={mapref} style={{ height: getHeight(isMobile ? 0.55 : 1), width: isMobile ? '100%' : '75%' }} />
      </div>
    </div>
  )
}

export const MapModal = props => {
  const { addresses } = props;

  const onClose = () => {
    props.onClose();
  }

  return (
    <Modal 
      visible={!!addresses} 
      width={'100%'} 
      height={window.innerHeight} 
      onOk={onClose} 
      onCancel={onClose}
      style={{ top: 0 }}
    >
      {!!addresses && <Map {...props} />}
    </Modal>
  )
}