/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React, { useState, useEffect } from 'react';
import { DatePicker, message, Icon } from 'antd';
import styles from './styles';
import moment from 'moment';
import { stopProp } from '../../actions/utils';
import { get } from 'lodash';
import { Button } from '../button'

export const CalSelect = props => {
  const { buttonProps, onSubmit, title } = props;
  const [open, setOpen] = useState(false);

  const submit = async (date) => {
    try {
      await onSubmit(date);
    } catch (err) {
      message.error(err.message);
    } finally {
      setOpen(false);
    }
  }

  return (
    <>
      {open && <div onClick={(e) => { stopProp(e); setOpen(false) }} css={css(styles.bgSelect)} />}
      <div style={{ position: 'relative' }}>
        <Button {...(typeof buttonProps === 'function' ? buttonProps() : buttonProps)} onClick={(e) => { stopProp(e); setOpen(!open) }} />
        {open && <div css={css(styles.orderPicker)}>
          {title && <div css={css(styles.title)}>{title}</div>}
          <DatePicker.WeekPicker open onChange={submit} />
        </div>}
      </div>
    </>
  )
}