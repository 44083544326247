/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Alert, Checkbox, Input, message } from 'antd';
import { get, keyBy, set, size } from 'lodash';
import { useState, useEffect } from 'react';
import { exportToCsv, getRawLicense } from '../../actions/utils';
import { EditableFormTable } from '../../_shared/editable-table';
import { TableListHeader } from '../../_shared/table-list';
import { getStoredLicenseNumbers, updateStoredLicenseNumbers } from '../../_shared/services/api.service';
import useDebounce from '../../_shared/hooks/useDebounce';
import moment from 'moment';
import Row from '../../_shared/row';
import { Button } from '../../_shared/button';

const timeformat = 'h:mm a';

const defaultWindows = [
  [moment('9:00', timeformat), moment('17:00', timeformat)],
  [moment('9:00', timeformat), moment('17:00', timeformat)],
  [moment('9:00', timeformat), moment('17:00', timeformat)],
  [moment('9:00', timeformat), moment('17:00', timeformat)],
  [moment('9:00', timeformat), moment('17:00', timeformat)],
  [],
  [],
]

const COLS = [
  {
    title: 'LN',
    dataIndex: 'License #',
    editable: true,
    width: 200,
  },
  {
    title: 'Name',
    dataIndex: 'Licensee',
    editable: true,
    width: 200,
  },
  {
    title: 'DBA',
    dataIndex: 'DBA',
    editable: true,
  },
  {
    title: 'Street Address',
    dataIndex: 'Street Address',
    editable: true,
  },
  {
    title: 'City',
    dataIndex: 'City',
    editable: true,
  },
  {
    title: 'Zip',
    dataIndex: 'Zip',
    editable: true,
  },
  {
    title: 'Emails',
    dataIndex: 'emails',
    editable: true,
    inputType: 'textarea'
  },
  {
    title: 'Receiving Windows',
    dataIndex: 'windows',
    editable: true,
    inputType: 'TimePickerRange',
    required: false,
    width: 170,
    options:[
      { label: 'Mon', format: timeformat, defaultValue: [moment('9:00', timeformat), moment('17:00', timeformat)] },
      { label: 'Tues', format: timeformat, defaultValue: [moment('9:00', timeformat), moment('17:00', timeformat)] },
      { label: 'Wed', format: timeformat, defaultValue: [moment('9:00', timeformat), moment('17:00', timeformat)] },
      { label: 'Thurs', format: timeformat, defaultValue: [moment('9:00', timeformat), moment('17:00', timeformat)] },
      { label: 'Fri', format: timeformat, defaultValue: [moment('9:00', timeformat), moment('17:00', timeformat)] },
      { label: 'Sat', format: timeformat, defaultValue: [] },
      { label: 'Sun', format: timeformat, defaultValue: [] },
    ],
    render: function (text, record) {
      return (
        <div>
          {get(record, 'windows', defaultWindows).map((wind, i) => {
            const hasStart = !!get(wind, 0);
            const hasEnd = !!get(wind, 1);
            return (
              <Row key={i} style={{ fontSize: 10 }}>
                <div style={{ marginRight: 5 }}>{`${get(COLS.find(f => f.dataIndex === 'windows'), `options.${i}.label`)}: `}</div>
                <div>{hasStart && hasEnd ? `${moment(get(wind, 0)).format(timeformat)} - ${moment(get(wind, 1)).format(timeformat)}` : 'N/A'}</div>
              </Row>
            )
          })}
        </div>
      )
    }
  },
  {
    title: 'Active',
    dataIndex: 'active',
    inputType: 'singlecheckbox',
    editable: true,
    required: false,
    valuePropName: 'checked',
    render: (text, record) => {
      return (
        <span>
          {`${text}`}
        </span>
      )
    }
  }
]

const ADDKEY = '000-00000';

export const LNUpdate = props => {
  const [state, setState] = useState({
    data: {},
    stored: {},
    loading: true,
    showAll: false,
    input: '',
    exporting: false,
  })

  const debInput = useDebounce(state.input, 750);

  const findMissingAddresses = () => {
    const lns = get(window, 'KEYMAPS.license', {});
    const keys = {};
    
    Object.keys(lns).filter(k => {
      const ln = lns[k];
      const addr = get(ln, 'Street Address');
      const existsInStore = !!get(state, `stored.${k}`);

      if (!!state.input) {
        const inp = state.input.toLowerCase();
        const search = ['License #', 'Licensee'];
        return !!search.find(s => get(ln, s, '').toLowerCase().indexOf(inp) > -1)
      }

      return existsInStore || !addr || state.showAll;
    }).map(k => {
      set(keys, k, { ...getRawLicense(k), active: true, ...get(state, `stored.${k}`, {}) });
    })

    setState(s => ({ ...s, loading: false, data: keys }));
  }

  const getStored = async () => {
    try {
      await window.LOADLICENSE();
      const stored = await getStoredLicenseNumbers();
      setState(s => ({ ...s, stored }));
    } catch (err) {
      message.error(`Error: ${err.message}`);
    }
  }

  const updateData = async (_data, updatedRow, updatedIndex, action) => {
    try {
      const stored = { ...state.stored };
  
      const ln = get(updatedRow, 'License #');
  
      if (action === 'delete') {
        delete stored[ln];
      } else {
        const isAdd = !!updatedRow.isAdd;
  
        if (isAdd) { //check to see if it already exists
          const exists = !!get(state, `data.${ln}`);
          if (exists) {
            setState(s => {
              const d = s.data;
              delete d[ADDKEY];
              return {
                ...s,
                data: d,
              }
            })
            throw new Error('License number already exists! Please try again!');
          } else {
            delete updatedRow['isAdd'];
          }
        }
  
        set(stored, ln, updatedRow);
      }

      const data = keyBy(_data, 'License #');
      setState(s => ({ ...s, data }))

      await updateStoredLicenseNumbers(stored);
      getStored();
    } catch (err) {
      message.error(err.message);
    }
  };

  const changeText = e => {
    const input = e.target.value;
    setState(s => ({ ...s, input }));
  }

  const scrollTop = () => {
    try {
      document.getElementById('ContentContainer').scrollTop = 0;
    } catch (err) {
      console.log(err);
    }
  }

  const addRow = () => {
    setState(s => ({
      ...s,
      data: {
        [ADDKEY]: {
          'isAdd': true,
          'City': "",
          'DBA': "",
          'FacilityType': "",
          'License #': ADDKEY,
          'Licensee': "",
          'Street Address': "",
          'Zip': "",
        },
        ...s.data,
      }
    }))
    scrollTop();
    return ADDKEY;
  }

  const exportlns = async () => {
    try {
      setState(s => ({ ...s, exporting: true }))
      const all = {
        ...state.data,
        ...state.stored,
      }

      const rows = [COLS.map(c => c.title)];
      Object.keys(all).map(k => {
        const d = { ...getRawLicense(k), active: true, ...get(state, `stored.${k}`, {}) };
        rows.push(COLS.map(c => get(d, c.dataIndex, '').toString()))
      })
      exportToCsv(`LN-export-${moment().toISOString()}.csv`, rows);
    } catch (err) {
      message.error(err.message);
    } finally {
      setState(s => ({ ...s, exporting: false }))
    }
  }

  useEffect(() => {
    getStored();

    return () => {
      try {
        window.LOADLICENSES();
      } catch (err) {
        console.warn(err);
      }
    }
  }, []);

  useEffect(() => {
    setTimeout(findMissingAddresses, 200);
  }, [state.stored, state.showAll, debInput]);

  return (
    <div>
      <Alert message="DO NOT CHANGE ANYTHING ON THIS PAGE WITHOUT MANAGEMENT APPROVAL!!" type="error" style={{ marginBottom: 10 }} />
      <TableListHeader>
        <Input.Search value={state.input} style={{ width: '40%', marginRight: 10 }} onChange={changeText} />
        <Checkbox checked={state.showAll} onChange={() => setState(s => ({ ...s, showAll: !s.showAll }))}>Show All</Checkbox>
        <Button style={{ marginLeft: 'auto' }} type="primary" disabled={state.loading || state.exporting} onClick={exportlns}>Export LN's</Button>
      </TableListHeader>
      <EditableFormTable
        data={Object.keys(state.data).map(k => state.data[k])}
        columns={COLS}
        rowKey={'License #'}
        updateData={updateData}
        loading={state.loading}
        addRow={addRow}
        addTop
        addButton={'Add New LN'}
        tableProps={{ pagination: { hideOnSinglePage: true, pageSize: 50 } }}
      />
    </div>
  )
}
