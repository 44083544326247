import { setUserToken } from "../_shared/services/authentication.service";
import { loadConfig, loadLicenses } from "../actions/utils";
import { ACTIONS } from "../actions/reducers";

export default async (store) => {
  await setUserToken(store);
  const config = await loadConfig().catch(e => ({ }));

  loadLicenses();

  store.dispatch({ type: ACTIONS.SET_CONFIG, config: { ...config, location: window.location.origin } })
};
