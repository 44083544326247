export const ROLES = {
  '0': 'superadmin',
  '1': 'not defined',
  '2': 'driver',
  '3': 'account admin',
  '4': 'standard user',
}

export class _Company {
  constructor(props = {}) {
    for (let key in props) {
      this[key] = props[key];
    }
  }
  ['License #'] = '';
  ['Licensee'] = '';
  ['Street Address'] = '';
  licenseNumbers = [];
  logo = '';
  phone = ''
}

export class _Account {
  constructor(props = {}) {
    for (let key in props) {
      this[key] = props[key];
    }
  }
  userId = '';
  id = '';
  company = new _Company();
  email = '';
  sub = '';
  isActive = 0;
  role = 4;
}