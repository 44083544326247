/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import { Select, AutoComplete, Icon } from 'antd';
import { debounce, get } from 'lodash';
import { findRoutesFromZip, getRoutes } from '../services/routes.service';
import { styles } from './styles';

export default class RouteSearch extends Component {
  state = {
    error: null,
    loading: true,
    routesAll: [],
    routes: [],
    utilRoutes: [],
    value: undefined,
    selectedOption: undefined,
    options: [],
  };
  componentDidMount = async () => {
    this.retrieveZip(true);
  };
  retrieveZip = async (isInitial = false) => {
    try {
      this.setState({ loading: true });

      const utilRoutes = isInitial ? await findRoutesFromZip('00000') : this.state.utilRoutes;
      const routesAll = this.props.showAll ? await getRoutes({}) : await findRoutesFromZip(this.props.zip);

      const routes = this.generateOptions([
        ...routesAll,
        ...utilRoutes,
      ]);

      this.setState({
        routesAll: routes,
        utilRoutes,
        routes,
        loading: false,
      }, this.createOptions);
    } catch (err) {
      console.error(err);
      this.setState({ loading: false });
    }
  }
  componentDidUpdate = (prevProps) => {
    if(this.props.zip !== prevProps.zip || this.props.showAll !== prevProps.showAll) {
      this.retrieveZip();
    }
  }
  generateOptions = (routes) => {
    let retRoutes = [];
    const keys = {};
    routes.map(r => {
      const rows = get(r, 'data.rows', []);
      rows.map((row, i) => {
        const isUtil = get(row, 'zipCode') === '00000';
        const matchesZip = this.props.showAll ? true : (get(row, 'zipCode') === this.props.zip || isUtil);
        if (matchesZip) {
          get(row, 'dayOfTheWeek', []).map(day => {
            const value = JSON.stringify({
              id: isUtil ? row.id : r.id,
              parent: r.id,
              name: r.name,
              city: row.city,
              zipCode: row.zipCode,
              day,
              overnight: row.overnight
            });

            if (!get(keys, value)) {
              keys[value] = true;
              retRoutes.push({
                title: `${r.name} - ${row.city}, ${row.zipCode}: ${day}`,
                value,
              })
            }
          })
        }
      })
    })
    return retRoutes;
  }
  createOptions = () => {
    this.setState({
      options: this.state.routes.map(r => <Select.Option key={r.value} value={r.value}>{r.title}</Select.Option>)
    });
  }
  onChange = (value) => {
    this.setState({ value });
  }
  onSearch = debounce((input) => {
    const { routesAll } = this.state;

    this.setState({ loading: true });

    const val = input.toLowerCase();

    const routes = routesAll.filter(l => {
      const name = get(l, 'title').toLowerCase();
      if (name.indexOf(val) > -1) {
        return true;
      } else {
        return false;
      }
    })

    this.setState({
      routes,
      loading: false,
    }, this.createOptions);
  }, 500);
  selectOption = (val) => {
    const { onSelect } = this.props;
    this.setState({ 
      selectedOption: val, 
      value: val 
    }, () => onSelect && onSelect(val));
  }
  render() {
    const { loading, value, options } = this.state;

    return (
      <div css={css(styles.container)}>
        <AutoComplete
          placeholder={'Type to filter results'}
          onSearch={this.onSearch}
          onSelect={this.selectOption}
          dataSource={options}
          onChange={this.onChange}
          value={value}
          loading={loading}
          {...this.props}
        />
        {loading && <Icon css={css(styles.loader)} type="loading" />}
      </div>
    );
  }
};
