/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Component } from 'react'
import { OrderStatus } from '../../models/order';
import { TableList, TableListHeader } from '../../_shared/table-list';
import { Icon, Input, Checkbox } from 'antd';
import Row from '../../_shared/row';
import SStyles from '../../_shared/styles';
import { get } from 'lodash';
import { getRoutes, createRoute } from '../../_shared/services/routes.service';
import { withRouter } from 'react-router-dom';
import { sortByName, userHasRole } from '../../actions/utils';
import { Button } from '../../_shared/button';

const COLS = [
  {
    title: 'Route Name',
    dataIndex: 'name',
  },
  {
    title: 'Locations',
    dataIndex: 'data.rows',
    render: (text) => {
      return (
        <span>{(text || []).length}</span>
      )
    }
  },
]

export class RoutesComponent extends Component {
  state = {
    allRoutes: [],
    routes: [],
    loading: false,
    error: undefined,
    success: undefined,
    isAdmin: false, //whether to setup admin funcitonalitiy
  }
  componentDidMount = () => {
    this.getRoutes();
  }
  getRoutes = async () => {
    try {
      const { sState } = this.props;
      const { location } = this.props.history;
      let isAdmin = false;
      if (location.pathname.indexOf('manage') > -1 && userHasRole([0], sState)) {
        isAdmin = true;
      }

      this.setState({ loading: true });
      const routes = (await getRoutes()).sort(sortByName);
      this.setState({ allRoutes: routes, routes, loading: false, isAdmin });
    } catch (err) {
      this.setState({ loading: false, error: err.message });
    }
  }
  filterRoutes = (searchTerm = '') => {
    const { allRoutes } = this.state;
    const st = searchTerm.toLowerCase();
    const routes = allRoutes.filter(a => {
      const address = `${get(a, 'data.pickupCompany[Street Address]', '').toLowerCase()} ${get(a, 'data.destCompany[Street Address]', '').toLowerCase()}`;
      const id = get(a, 'orderId', '').toLowerCase();
      const name = `${get(a, 'data.pickupCompany.Licensee', '').toLowerCase()} ${get(a, 'data.destCompany.Licensee', '').toLowerCase()}`;
      const type = get(a, 'data.order.orderType', '').toLowerCase();

      return address.indexOf(st) > -1 || id.indexOf(st) > -1 || name.indexOf(st) > -1 || type.indexOf(st) > -1;
    })
    this.setState({ routes });
  }
  selectRoute = (record, index) => {
    this.props.history.push('/dashboard/editRoute', {
      editRoute: record,
    })
  }
  onSearch = (st) => {
    this.filterRoutes(st);
  }
  filterDelivered = (deliveredOnly) => {
    const { allRoutes } = this.state;
    const routes = deliveredOnly ? allRoutes.filter(o => get(o, 'data.order.status', 0) >= OrderStatus.delivered) : allRoutes;
    this.setState({ routes });
  }
  onCheckChange = (e) => {
    const checked = e.target.checked;
    this.filterDelivered(checked);
  }
  createRoute = async () => {
    try {
      this.setState({ loading: true });
      const route = await createRoute({ name: '', data: { rows: [] } });
      this.props.history.push('/dashboard/editRoute', {
        editRoute: route,
      })
      this.setState({ loading: false });
    } catch (err) {
      console.error(err);
      this.setState({ loading: false });
    }
  }
  // loadRoute = async () => {
  //   await createRoute({
  //     name: 'Denver Central',
  //     data: {
  //       rows: [
  //         { city: 'Denver - North Denver/RiNo/Along I-25 & I-70', zipCode: '80216', dayOfTheWeek: ['tue', 'wed', 'thur', 'fri'] },
  //         { city: 'Denver - Ballpark/Five Points/City Park', zipCode: '80205', dayOfTheWeek: ['tue', 'wed', 'thur', 'fri'] },
  //         { city: 'Denver - Downtown', zipCode: '80202', dayOfTheWeek: ['tue', 'wed', 'thur', 'fri'] },
  //         { city: 'Denver - Uptown/Capitol Hill/Speer', zipCode: '80203', dayOfTheWeek: ['tue', 'wed', 'thur', 'fri'] },
  //         { city: 'Denver - City Park West/Cheesman', zipCode: '80218', dayOfTheWeek: ['tue', 'wed', 'thur', 'fri'] },
  //         { city: 'Denver - East Colfax/Cherry Creek', zipCode: '80206', dayOfTheWeek: ['tue', 'wed', 'thur', 'fri'] },
  //         { city: 'Denver - South Broadway/Washington Park', zipCode: '80209', dayOfTheWeek: ['tue', 'wed', 'thur', 'fri'] },
  //         { city: 'Denver - South Broadway/DU/University', zipCode: '80210', dayOfTheWeek: ['tue', 'wed', 'thur', 'fri'] },
  //       ]
  //     }
  //   })
  // }
  render() {
    const {
      loading,
      routes
    } = this.state;

    return (
      <div>
        <TableListHeader>
          <Row css={css(`width: 1000px;`)}>
            <Input.Search
              placeholder={'Search by Order ID, Address, Name or Order Type...'}
              enterButton
              onSearch={this.onSearch}
            />
            <Checkbox onChange={this.onCheckChange} css={css(SStyles.row, `margin-left: 10px;`)}>
              Show Only Delivered
            </Checkbox>
          </Row>
          <Button loading={loading} onClick={() => this.createRoute()}>
            <Icon type="plus" />
            {'Add Route'}
          </Button>
        </TableListHeader>
        <TableList
          loading={loading}
          data={routes}
          columns={COLS}
          rowClick={this.selectRoute}
          onRefresh={this.getRoutes}
        />
      </div>
    )
  }
}

export const Routes = withRouter(RoutesComponent);
