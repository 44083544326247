/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Footer } from '../footer';
import GateWay from './gateway.component';
import SStyles from '../_shared/styles';
import { Layout } from 'antd';
import { Login, Verify, ForgotPassword, ForgotPasswordSubmit, SignUp } from '../authentication';
import { Dashboard } from '../dashboard';

export default class Navigation extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <Layout css={css(SStyles.container, `background-color: white;`)}>
            <Route path="/login" component={Login} />
            <Route path="/verify" component={Verify} />
            <Route path="/forgotPassword" component={ForgotPassword} />
            <Route path="/forgotPasswordSubmit" component={ForgotPasswordSubmit} />
            <Route path="/signup" component={SignUp} />
            <Route path="/dashboard" component={Dashboard} />
          </Layout>
          <Footer />
          <GateWay />
        </div>
      </Router>
    );
  }
}
