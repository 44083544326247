const environment = process.env.NODE_ENV;

const config = {
  dev: {
    url: 'http://localhost:3001',
    appUrl: 'http://localhost:3000',

    url: 'http://dev.gpsorders.com',
    appUrl: 'http://dev.gpsorders.com',

    // url: 'http://nm.gpsone.co',
    // appUrl: 'http://nm.gpsone.co',

    // url: 'http://sd.gpsone.co',
    // appUrl: 'http://sd.gpsone.co',

    // //prod
    // url: 'http://gpsorders.com',
    // appUrl: 'http://gpsorders.com',

    devEmails: [
      'bruce+dev@vividconceptsllc.com',
    ],

    adminEmails: [
      'bruce@vividconceptsllc.com',
    ],

    rejectionEmails: [
      'bruce+rejection@vividconceptsllc.com',
    ],
    
    exceptionEmails: [
      'bruce+rejection@vividconceptsllc.com',
    ],

    feedbackEmails: [
      'bruce@vividconceptsllc.com',
    ],
  },
  prod: {
    url: window.location.origin,
    appUrl: window.location.origin,

    devEmails: [
      'joel@greenparcelservice.com',
    ],

    adminEmails: [
      'orders@greenparcelservice.com',
      'warehouse@greenparcelservice.com',
    ],

    rejectionEmails: [
      'orders@greenparcelservice.com',
      'warehouse@greenparcelservice.com',
    ],

    exceptionEmails: [
      'orders@greenparcelservice.com',
      'warehouse@greenparcelservice.com',
    ],

    feedbackEmails: [
      'info@greenparcelservice.com',
      'orders@greenparcelservice.com',
    ],
  }
}

export default (env = 'dev') => {
  const _env = environment === 'development' ? 'dev' : 'prod';
  return config[_env];
}