import colors from '../../_shared/colors';

export default {
  table: (opts) => `
    tr.row-warning {
      background-color: ${colors.warningLight};
    }

    tr > td {
      padding: 0px;
      position: relative;
      height: 60px;
    }
    tr > td:last-child {
      // padding: 8px;
    }
    tr > td > div {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: auto;
      padding: 5px;
    }

    .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
      top: ${opts.isMac ? -16 : 0}px;
    }
  `
}