/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Component, useState } from 'react'
import { CHECKINICON, OrderStatus, getStatusIcon } from '../../models/order';
import { TableList, TableListHeader } from '../../_shared/table-list';
import { Icon, Input, Checkbox, Modal, Tooltip, DatePicker, message } from 'antd';
import Row from '../../_shared/row';
import { Button } from '../../_shared/button';
import SStyles from '../../_shared/styles';
import { get, size, set, debounce } from 'lodash';
import { getOrders, deleteOrder, undeleteOrder, syncManifests, updateOrder, getUserOrders } from '../../_shared/services/orders.service';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { getLicAddress, jparse, userHasRole, getUrlParams, orderIsCheckedIn, orderIsCheckedOut, getLicName, orderIsRejected, orderHasManifest, getConfig, getManifestUrl, stopProp } from '../../actions/utils';
import { Order } from '../order'
import { CashPickupIcon, CashReturnIcon } from '../../_shared/CustomIcons';
import { getDataFromGuid } from '../../_shared/driver-search';
import colors from '../../_shared/colors';
import { useSelector } from 'react-redux';
import { PrintLabels } from '../../_shared/printlabels';
import { stripOrderUrl } from '../checkin';
import { getStartOfWeek } from '../drive-schedule/schedule';
import { EditRow } from '../reconciliation';
import ScreenComponent, { useScreen } from '../../_shared/hooks/useScreen';

const dateFormat = 'L';

const COLS = (opts) => {
  if (!opts.isAdmin) {
    return [
      {
        title: 'Order ID',
        dataIndex: 'orderId',
        width: opts.isIncoming ? '10%' : '8%',
        sortDirections: ['descend', 'ascend'],
        sorter: (_a, _b) => {
          try {
            const a = get(_a, 'orderId');
            const b = get(_b, 'orderId');
            return a.localeCompare(b, 'en', { numeric: true })
          } catch (err) {
            return 0;
          }
        },
        render: (text, row) => {
          const isCashReturn = get(row, 'data.order.cashReturn');
          const isCashPickup = get(row, 'data.order.orderType') === 'cash_pickup';
          const checkedout = orderIsCheckedOut(row);
          const isRejected = orderIsRejected(row);
          const { isSigned, hasFile, file } = orderHasManifest(row);
          const showSigned = isSigned && [OrderStatus.fullrejection, OrderStatus.partialrejection, OrderStatus.delivered].includes(get(row, 'data.order.status'));

          const manClick = (e) => {
            stopProp(e);
            window.open(getManifestUrl(file), '_blank').focus();
          }

          return (
            <div style={isRejected ? { color: colors.error, fontWeight: 'bold' } : checkedout ? { color: colors.highlight, fontWeight: 'bold' } : {}}>
              <span>{text}</span>
              {isCashReturn && <CashReturnIcon />}
              {isCashPickup && <CashPickupIcon />}
              {checkedout && !opts.isIncoming && <Icon type={CHECKINICON} title="Order is Checked Out" style={{ marginLeft: 2 }} />}
              {!showSigned && hasFile && <Icon type="file-protect" title="Order has manifest uploaded" style={{ marginLeft: 2 }} />}
              {showSigned && <div><a title="Click to see completed manifest" onClick={manClick}>Manifest <Icon type="file-protect" style={{ marginLeft: 2 }} /></a></div>}
            </div>
          )
        }
      },
      !opts.isIncoming && {
        title: 'Approved Pickup Date',
        dataIndex: 'data.order.pickupDate',
        width: '7%',
        render: (text, row) => {
          return (
            <span>{text ? moment(text).format(dateFormat) : 'N/A'}</span>
          )
        },
        sortDirections: ['descend', 'ascend'],
        sorter: (_a, _b) => {
          const a = get(_a, 'data.order.deliveryDate');
          const b = get(_b, 'data.order.deliveryDate');
          return new Date(b) - new Date(a);
        },
      },
      {
        title: () => <span title="GPS will attempt to meet all approved delivery dates. Approved delivery dates do not guarantee delivery on that date.">Approved Delivery Date</span>,
        dataIndex: 'data.order.deliveryDate',
        width: opts.isIncoming ? '10%' : '7%',
        render: (text, row) => {
          // const route = jparse(get(row, 'data.order.route', '{}'));
          const isOvernight = false;// !opts.isAdmin && get(route, 'overnight.0') === 'true';
          return (
            <span title="GPS will attempt to meet all approved delivery dates. Approved delivery dates do not guarantee delivery on that date.">{text ? moment(text).add(isOvernight ? 1 : 0, 'days').format(dateFormat) : 'N/A'}{isOvernight && <Icon title="Overnight" style={{ marginLeft: 5 }} type="clock-circle" />}</span>
          )
        },
        sortDirections: ['descend', 'ascend'],
        sorter: (_a, _b) => {
          const a = get(_a, 'data.order.deliveryDate');
          const b = get(_b, 'data.order.deliveryDate');
          return new Date(b) - new Date(a);
        },
      },
      opts.isIncoming && {
        title: 'Pickup. Name',
        dataIndex: 'data.pickupCompany.Licensee',
        width: '14%',
        render: (text, record) => {
          const ln = get(record, 'data.pickupCompany.License #');
          const txt = getLicName(ln);
          return (
            <span>{txt}</span>
          )
        },
        sortDirections: ['descend', 'ascend'],
        sorter: (_a, _b) => {
          try {
            const a = get(_a, 'data.pickupCompany.Licensee');
            const b = get(_b, 'data.pickupCompany.Licensee');
            return a.localeCompare(b, 'en', { numeric: true })
          } catch (err) {
            return 0;
          }
        },
      },
      {
        title: 'Pickup Destination',
        dataIndex: 'data.pickupCompany[Street Address]',
        width: opts.isIncoming ? '16%' : '15%',
        sortDirections: ['descend', 'ascend'],
        sorter: (_a, _b) => {
          try {
            const a = get(_a, 'data.pickupCompany[Street Address]');
            const b = get(_b, 'data.pickupCompany[Street Address]');
            return a.localeCompare(b, 'en', { numeric: true })
          } catch (err) {
            return 0;
          }
        },
        render: (text, record) => {
          const key = get(record, 'data.order.pickupLN');
          const addr = getLicAddress(key);
  
          return (
            <span>{addr}</span>
          )
        },
      },
      {
        title: 'Dest. Name',
        dataIndex: 'data.destCompany.Licensee',
        width: opts.isIncoming ? '14%' : '12%',
        render: (text, record) => {
          const ln = get(record, 'data.destCompany.License #');
          const txt = getLicName(ln);
          return (
            <span>{txt}</span>
          )
        },
        sortDirections: ['descend', 'ascend'],
        sorter: (_a, _b) => {
          try {
            const a = get(_a, 'data.destCompany.Licensee');
            const b = get(_b, 'data.destCompany.Licensee');
            return a.localeCompare(b, 'en', { numeric: true })
          } catch (err) {
            return 0;
          }
        },
      },
      {
        title: 'Destination',
        dataIndex: 'data.destCompany[Street Address]',
        width: opts.isIncoming ? '16%' :  '14%',
        sortDirections: ['descend', 'ascend'],
        sorter: (_a, _b) => {
          try {
            const a = get(_a, 'data.destCompany[Street Address]');
            const b = get(_b, 'data.destCompany[Street Address]');
            return a.localeCompare(b, 'en', { numeric: true })
          } catch (err) {
            return 0;
          }
        },
        render: (text, record) => {
          const zip = get(record, 'data.destCompany.Zip');
          const city = get(record, 'data.destCompany.City');
          const txt = city && zip ? `${text}, ${city}, ${zip}` : text;
          const key = get(record, 'data.order.destinationLN');
          const address = getLicAddress(key);
          return (
            <span>{address || txt}</span>
          )
        },
      },
      opts.isIncoming && {
        title: 'Manifest No.',
        dataIndex: 'data.billing.manifest',
        width: '10%',
      },
      !opts.isPublic && !opts.isIncoming && {
        title: () => {
          return (
            <div>
              <span>Driver</span>
              <Tooltip placement="top" title={`Pickup driver is listed in bold. Any changes to pickup driver will be highlighted in red.`}>
                <Icon type="info-circle" style={{ marginLeft: 10, color: colors.highlight }} />
              </Tooltip>
            </div>
          )
        },
        dataIndex: 'data.order.driver',
        width: '18%',
        render: (text, record) => {
          const hasChange = get(record, 'data.order.driver.pickupChange');
          const shouldHighlight = hasChange && moment(hasChange).diff(moment(), 'hours') <= 24;

          const pickup = { ...getDataFromGuid(get(record, 'data.order.driver.pickup')), style: { fontWeight: 'bold', color: shouldHighlight ? colors.error : undefined } };
          const del = { ...getDataFromGuid(get(record, 'data.order.driver.delivery')) };

          return [pickup, del].filter(d => d.driver).map((d, i) => <div key={i} style={d.style}>{`${get(d, 'driver.name', 'none')} | ${get(d, 'driver.occLic', 'none')} | ${get(d, 'driver.lic', 'none')}`}</div>)
        },
      },
      !opts.isPublic && !opts.isIncoming &&  {
        title: () => {
          return (
            <div>
              <span>Vehicle</span>
              <Tooltip placement="top" title={`Pickup vehicle is listed in bold.`}>
                <Icon type="info-circle" style={{ marginLeft: 10, color: colors.highlight }} />
              </Tooltip>
            </div>
          )
        },
        dataIndex: 'data.order.driver.pickup',
        width: '12%',
        render: (text, record) => {
          const hasChange = get(record, 'data.order.driver.pickupChange');
          const shouldHighlight = hasChange && moment(hasChange).diff(moment(), 'hours') <= 24;
          
          const pickup = { ...getDataFromGuid(get(record, 'data.order.driver.pickup')), style: { fontWeight: 'bold', color: shouldHighlight ? colors.error : undefined } };
          const del = { ...getDataFromGuid(get(record, 'data.order.driver.delivery')) };
          
          return [pickup, del].filter(d => d.vehicle).map((d, i) => {
            const hasvin = getConfig('orders.showVin', true) && get(d, 'vehicle.vin', '');
            return <div key={i} style={d.style}>{`${get(d, 'vehicle.name', 'none')} | ${get(d, 'vehicle.lic', 'none')}${hasvin ? ' | ' + hasvin : ''}`}</div>
          })
        },
      },
      !opts.isPublic && {
        title: 'Status',
        dataIndex: 'data.order.status',
        width: '3%',
        render: (text, record) => {
          const ico = getStatusIcon(record, { isAdmin: opts.isAdmin });
  
          return (
            <Icon
              type={ico.icon}
              title={ico.title}
              css={css(`color: ${ico.color};`)}
            />
          );
        },
      },
      {
        title: 'Box Count',
        dataIndex: 'data.order.weight',
        width: '6%',
        render: (t, row) => {
          const _opts = {
            ...opts,
            disabled: !!opts.isIncoming
          }
          return EditRow(t, row, 'data.order.weight', _opts)
        }
      }
    ].filter(f => !!f).map(m => ({ ...m, width: opts.isPublic ? `${100 / 7}%` : m.width }));
  }
  return [
    {
      title: 'Order ID',
      dataIndex: 'orderId',
      width: '10%',
      sortDirections: ['descend', 'ascend'],
      sorter: (_a, _b) => {
        try {
          const a = get(_a, 'orderId');
          const b = get(_b, 'orderId');
          return a.localeCompare(b, 'en', { numeric: true })
        } catch (err) {
          return 0;
        }
      },
      render: (text, row) => {
        const isCashReturn = get(row, 'data.order.cashReturn');
        const isCashPickup = get(row, 'data.order.orderType') === 'cash_pickup';
        const checkedin = orderIsCheckedIn(row);
        const isRejected = orderIsRejected(row);
        const { hasFile } = orderHasManifest(row);

        return (
          <div style={isRejected ? { color: colors.error, fontWeight: 'bold' } : checkedin ? { color: colors.highlight, fontWeight: 'bold' } : {}}>
            <span>{text}</span>
            {isCashReturn && <CashReturnIcon />}
            {isCashPickup && <CashPickupIcon />}
            {checkedin && <Icon type={CHECKINICON} style={{ marginLeft: 2 }} />}
            {hasFile && <Icon type="file-protect" title="Order has manifest" style={{ marginLeft: 2 }} />}
          </div>
        )
      }
    },
    {
      title: 'Date Placed',
      dataIndex: 'data.dateSubmitted',
      width: '10%',
      render: (text) => {
        return (
          <span>{moment(text).format(dateFormat)}</span>
        )
      },
      sortDirections: ['descend', 'ascend'],
      sorter: (_a, _b) => {
        const a = get(_a, 'data.dateSubmitted');
        const b = get(_b, 'data.dateSubmitted');
        return new Date(b) - new Date(a);
      },
    },
    {
      title: 'Approved Pickup Date',
      dataIndex: 'data.order.pickupDate',
      width: '8%',
      render: (text, row) => {
        return (
          <span>{text ? moment(text).format(dateFormat) : 'N/A'}</span>
        )
      },
      sortDirections: ['descend', 'ascend'],
      sorter: (_a, _b) => {
        const a = get(_a, 'data.order.deliveryDate');
        const b = get(_b, 'data.order.deliveryDate');
        return new Date(b) - new Date(a);
      },
    },
    {
      title: 'Approved Delivery Date',
      dataIndex: 'data.order.deliveryDate',
      width: '8%',
      render: (text, row) => {
        const route = jparse(get(row, 'data.order.route', '{}'));
        const isOvernight = !opts.isAdmin && get(route, 'overnight.0') === 'true';
        return (
          <span>{text ? moment(text).add(isOvernight ? 1 : 0, 'days').format(dateFormat) : 'N/A'}{isOvernight && <Icon title="Overnight" style={{ marginLeft: 5 }} type="clock-circle" />}</span>
        )
      },
      sortDirections: ['descend', 'ascend'],
      sorter: (_a, _b) => {
        const a = get(_a, 'data.order.deliveryDate');
        const b = get(_b, 'data.order.deliveryDate');
        return new Date(b) - new Date(a);
      },
    },
    {
      title: 'Vendor',
      dataIndex: 'data.pickupCompany.Licensee',
      width: '15%',
      sortDirections: ['descend', 'ascend'],
      sorter: (_a, _b) => {
        try {
          const a = get(_a, 'data.pickupCompany.Licensee');
          const b = get(_b, 'data.pickupCompany.Licensee');
          return a.localeCompare(b, 'en', { numeric: true })
        } catch (err) {
          return 0;
        }
      },
    },
    {
      title: 'Pickup',
      dataIndex: 'data.pickupCompany[Street Address]',
      width: '16%',
      sortDirections: ['descend', 'ascend'],
      sorter: (_a, _b) => {
        try {
          const a = get(_a, 'data.pickupCompany[Street Address]');
          const b = get(_b, 'data.pickupCompany[Street Address]');
          return a.localeCompare(b, 'en', { numeric: true })
        } catch (err) {
          return 0;
        }
      },
      render: (text, record) => {
        const key = get(record, 'data.order.pickupLN');
        const addr = getLicAddress(key);

        return (
          <span>{addr || text}</span>
        )
      },
    },
    {
      title: 'Dest. Name',
      dataIndex: 'data.destCompany.Licensee',
      width: '15%',
      render: (text, record) => {
        const ln = get(record, 'data.destCompany.License #');
        const txt = getLicName(ln);
        return (
          <span>{txt}</span>
        )
      },
      sortDirections: ['descend', 'ascend'],
      sorter: (_a, _b) => {
        try {
          const a = get(_a, 'data.destCompany.Licensee');
          const b = get(_b, 'data.destCompany.Licensee');
          return a.localeCompare(b, 'en', { numeric: true })
        } catch (err) {
          return 0;
        }
      },
    },
    {
      title: 'Destination',
      dataIndex: 'data.destCompany[Street Address]',
      width: '16%',
      sortDirections: ['descend', 'ascend'],
      sorter: (_a, _b) => {
        try {
          const a = get(_a, 'data.destCompany[Street Address]');
          const b = get(_b, 'data.destCompany[Street Address]');
          return a.localeCompare(b, 'en', { numeric: true })
        } catch (err) {
          return 0;
        }
      },
      render: (text, record) => {
        const key = get(record, 'data.order.destinationLN');
        const address = getLicAddress(key);
        return (
          <span>{address || text}</span>
        )
      },
    },
    {
      title: 'Status',
      dataIndex: 'data.order.status',
      width: '2%',
      render: (text, record) => {
        const ico = getStatusIcon(record);
  
        return (
          <Icon
            type={ico.icon}
            title={ico.title}
            css={css(`color: ${ico.color};`)}
          />
        );
      },
    },
  ].filter(f => !!f);
}

export const sortOrders = (a, b) => {
  const datea = new Date(get(a, 'data.dateSubmitted'));
  const dateb = new Date(get(b, 'data.dateSubmitted'));

  const appA = get(a, 'data.order.deliveryDate');
  const appB = get(b, 'data.order.deliveryDate');

  if (appA && !appB) {
    return 1;
  }
  if (!appA && appB) {
    return -1;
  }

  return dateb - datea;
}

export const orderSearch = (orders = [],  searchTerm = '') => {
  const st = searchTerm.toLowerCase();
  
  return orders.filter(a => {
    const tosearch = [
      `${get(a, 'data.pickupCompany[Street Address]', '').toLowerCase()} ${get(a, 'data.destCompany[Street Address]', '').toLowerCase()}`, //address 
      get(a, 'orderId', '').toLowerCase(), //id
      get(a, 'id', '').toLowerCase(), //guid
      (get(a, 'data.order.destinationLN', '') || '').toLowerCase(), //destid
      `${get(a, 'data.pickupCompany.Licensee', '').toLowerCase()} ${get(a, 'data.destCompany.Licensee', '').toLowerCase()}`, //name
      get(a, 'data.order.orderType', '').toLowerCase(), //type
      `${get(a, 'data.billing.invoice', '')}`.toLowerCase(), //invoiceid,
      `${get(a, 'data.billing.manifest', '')}`.toLowerCase(), //manifestid
    ]

    return !!tosearch.find(s => s.indexOf(st) > -1);
  })
}

export class OrdersComponent extends Component {
  constructor(props) {
    super(props);

    const { userid } = getUrlParams(window.location.href);

    this.state = {
      allOrders: [],
      selectedOrders: [],
      deletedOrders: [],
      orders: [],
      loading: false,
      loadingManifests: false,
      error: undefined,
      success: undefined,
      editModal: undefined,
      searchTerm: '',
      isAdmin: false, //whether to setup admin funcitonalitiy
      isPrintingLabels: false,
      searchVal: '',
      allHistory: false,
      isPublic: !!userid ? true : false,
      userid: userid,
      week: getStartOfWeek(),
      saving: false,
    }
  }
  componentDidMount = async () => {
    await this.getOrders();
    const { id } = getUrlParams(window.location.href);
    if (id) {
      const order = this.state.orders.find(o => o.id === id) || { id };
      this.selectOrder(order);
    }
  }
  isAdmin = () => {
    const { sState } = this.props;
    const { location } = this.props.history;
    let isAdmin = false;
    if (location.pathname.indexOf('admin') > -1 && userHasRole([0], sState)) {
      isAdmin = true;
    }
    return isAdmin;
  }
  isIncoming = () => {
    const { location } = this.props.history;
    let is = false;
    if (location.pathname.indexOf('incomingorders') > -1) {
      is = true;
    }
    return is;
  }
  isPlaced = () => {
    const { location } = this.props.history;
    let is = false;
    if (location.pathname.indexOf('placedorders') > -1) {
      is = true;
    }
    return is;
  }
  getOrders = async () => {
    try {
      const { allHistory, isPublic, userid } = this.state;
      let isAdmin = this.isAdmin();   
      const isIncoming = this.isIncoming(); 
      const isPlaced = this.isPlaced();  

      this.TABLE && this.TABLE.clearSelected();
      this.setState({ loading: true });
      const userId = isPublic ? userid : get(this.props.sState, 'user.id', '__none__');
      let orders;

      if (isPublic) {
        orders = (await getOrders({ 
          userId, 
          between: [this.state.week, moment(this.state.week).add(1, 'week')] 
        })).sort(sortOrders);
      } else if (isPlaced) {
        orders = (await getUserOrders({ 
          userId, 
          between: [this.state.week, moment(this.state.week).add(1, 'month')] 
        })).sort(sortOrders);
      } else {
        orders = (await getOrders(isAdmin ? null : { userId, isIncoming }, !allHistory ? { greaterThen: moment().subtract(40, 'days').toISOString() } : {})).sort(sortOrders);
      }

      if (!isAdmin && !isPublic && !isIncoming) {
        this.props.retrieveData(this.props.screen);
      }

      this.setState({ 
        allOrders: orders, 
        orders, 
        loading: false, 
        isAdmin,
      }, this.filterOrders);
    } catch (err) {
      this.setState({ loading: false, error: err.message });
    }
  }
  filterOrders = () => {
    const { allOrders, searchTerm } = this.state;
    const orders = orderSearch(allOrders, searchTerm);
    this.setState({ orders });
  }
  selectOrder = (record, index) => {
    if (this.state.isPublic || this.isIncoming()) { return };
    this.setState({ editModal: { state: { editOrder: record } } });
  }
  onSearch = (st) => {
    this.setState({ searchTerm: st }, this.filterOrders);
  }
  filterDelivered = (deliveredOnly) => {
    const { allOrders } = this.state;
    const orders = deliveredOnly ? allOrders.filter(o => get(o, 'data.order.status', 0) >= OrderStatus.delivered) : allOrders;
    this.setState({ orders });
  }
  unDeleteItems = async () => {
    const { deletedOrders } = this.state;

    try {
      this.setState({ loading: true });
      await Promise.all(deletedOrders.map(async so => await undeleteOrder(so)))
      this.setState({ deletedOrders: [], selectedOrders: [] }, this.getOrders);
    } catch (err) {
      this.setState({ error: err.message });
    } finally {
      this.setState({ loading: false });
    }
  }
  syncManifests = async () => {
    const go = async () => {
      try {
        this.setState({ loadingManifests: true });
        await syncManifests();
      } catch (err) {
        this.setState({ error: err.message });
      } finally {
        this.setState({ loadingManifests: false });
      }
    }

    Modal.confirm({
      title: 'Sync Manifests',
      content: 'This request will take up to 5 minutes. Please wait, or return in ~5 mins.',
      onOk: go,
      onCancel: () => 0,
    })
  }
  deleteSelectedItems = async () => {
    const { selectedOrders } = this.state;

    try {
      this.setState({ loading: true });
      await Promise.all(selectedOrders.map(async so => await deleteOrder(so)))
      this.setState({ deletedOrders: selectedOrders, selectedOrders: [] }, this.getOrders);
    } catch (err) {
      this.setState({ error: err.message });
    } finally {
      this.setState({ loading: false });
    }
  }
  onDelete = () => {
    const { selectedOrders } = this.state;
    Modal.confirm({
      title: `Delete ${size(selectedOrders)} Orders?`,
      content: `Are you sure you want to delete these orders?`,
      onOk: this.deleteSelectedItems,
      onCancel: () => 0,
    })
  }
  unDelete = () => {
    const { deletedOrders } = this.state;
    Modal.confirm({
      title: `Delete ${size(deletedOrders)} Orders?`,
      content: `Are you sure you want to delete these orders?`,
      onOk: this.unDeleteItems,
      onCancel: () => 0,
    })
  }
  onSelectedRowsChange = (selectedKeys, selectedOrders) => {
    this.setState({ selectedOrders });
  }
  onCheckChange = (e) => {
    const checked = e.target.checked;
    this.filterDelivered(checked);
  }
  closeEditModal = (shouldUpdate) => {
    this.setState({ editModal: undefined });
    if (shouldUpdate) {
      this.getOrders();
    }
  }
  selectWeek = (a) => {
    const week = getStartOfWeek(a);
    this.setState({ week }, this.getOrders);
  }
  saveOrder = debounce(async (order) => {
    try {
      this.setState({ saving: true });
      await updateOrder(order);
    } catch (err) {
      message.error(`${err.message}`);
    } finally {
      this.setState({ saving: false });
    }
  }, 800, { leading: false });
  onChangeText = (val, row, key) => {
    const orderIndex = this.state.orders.findIndex(o => o.id === row.id);
    set(this.state, `orders.${orderIndex}.${key}`, val);
    this.setState({ orders: [...this.state.orders] });
    
    if (!this.state.isPublic) {
      this.saveOrder(get(this.state, `orders.${orderIndex}`));
    }
  }
  render() {
    const { screen } = this.props;
    const {
      loading,
      loadingManifests,
      orders,
      selectedOrders,
      deletedOrders,
      isAdmin,
      editModal,
      allHistory,
      isPublic,
      week,
    } = this.state;

    const isIncoming = this.isIncoming();

    return (
      <div className='no-print'>
        <TableListHeader>
          <Row>
            <Row css={css(screen.isMobile ? `width: 100%` : `width: 600px;`)}>
              <Input.Search
                placeholder={'Search by Order ID, Manifest No., Name or Address...'}
                enterButton
                onSearch={this.onSearch}
                value={this.state.searchVal}
                onChange={e => this.setState({ searchVal: stripOrderUrl(e.target.value) })}
              />
              {isAdmin && <Checkbox checked={allHistory} onChange={() => this.setState({ allHistory: !allHistory }, this.getOrders)} css={css(SStyles.row, `margin-left: 10px; width: 250px;`)}>
                Show All History
              </Checkbox>}
              {!isPublic && <Checkbox onChange={this.onCheckChange} css={css(SStyles.row, `margin-left: 10px; width: 250px;`)}>
                Show Only Delivered
              </Checkbox>}
            </Row>
            {isPublic && <Row css={css('width: 400px; align-items: center; justify-content: center;')}>
              <DatePicker.WeekPicker style={{ width: 250 }} value={week} placeholder="Select Week" onChange={this.selectWeek} />
            </Row>}
            {!isPublic && !screen.isMobile && !isIncoming && <div css={css('width: auto; flex: 1 1 auto; font-weight: bold;text-align: center')}>
              <div dangerouslySetInnerHTML={{ __html: getConfig('orders.infoText', '') }} />
            </div>}
            <Row css={css(`width: auto; justify-content: flex-end;`)}>
              <PrintLabels orders={selectedOrders} />
              {isAdmin && !!size(selectedOrders) && <Button title={'Delete'} type="danger" onClick={this.onDelete} />}
              {isAdmin && !!size(deletedOrders) && <Button title={'Un Delete'} css={css(`margin-left: 10px;`)} type="outline" onClick={this.unDelete} />}
              {isAdmin && <AdminOrderButton onClose={this.getOrders} />}
              {isAdmin && <Button title={'Sync Manifests'} loading={loadingManifests} css={css(`margin-left: 10px;`)} type="outline" onClick={this.syncManifests} />}
            </Row>
          </Row>
        </TableListHeader>
        <TableList
          ref={comp => this.TABLE = comp}
          loading={loading}
          data={orders}
          columns={COLS({ ...this.state, onChangeText: this.onChangeText, isIncoming: this.isIncoming() })}
          onSelectedRowsChange={this.onSelectedRowsChange}
          rowClick={isIncoming ? undefined : this.selectOrder}
          onRefresh={this.getOrders}
        />
        <OrderModal editModal={editModal} onClose={this.closeEditModal} />
      </div>
    )
  }
}

export const OrderModal = props => {
  const { editModal, onClose } = props;
  const { isMobile } = useScreen();
  
  return (
    <Modal
      title={`Edit Order ${get(editModal, 'state.editOrder.orderId', '')}`}
      visible={!!editModal}
      width={isMobile ? '100%' : '90%'}
      style={isMobile ? { top: 0 } : { top: 30 }}
      onOk={() => onClose()}
      onCancel={() => onClose()}
      footer={(null)}
    >
      {editModal && <Order {...editModal} onClose={onClose} isModal={true} />}
    </Modal>
  )
}

export const AdminOrderButton = props => {
  const sState = useSelector(st => st);
  const [state, setState] = useState({
    editModal: undefined,
    newEditModal: {
      state: {
        allAvail: true,
        isNewAdminOrder: true,
        editOrder: {
          data: {
            order: {
              status: OrderStatus.orderApproved,
            }
          }
        },
      }
    }
  });

  const isAdmin = userHasRole([0], sState);

  const startOpen = () => {
    setState(s => ({ ...s, editModal: s.newEditModal }));
  }

  const onClose = () => {
    setState(s => ({ ...s, editModal: undefined }));
    props.onClose && props.onClose();
  }

  if (!isAdmin) { return null }

  return (
    <div>
      <Button onClick={startOpen}>Create Order</Button>
      <OrderModal editModal={state.editModal} onClose={onClose} isModal={true} />
    </div>
  )
}

export const DriverOrderButton = props => {
  const sState = useSelector(st => st);
  
  const [state, setState] = useState({
    editModal: undefined,
    newEditModal: {
      state: {
        allAvail: true,
        isDriverOrder: true,
        editOrder: {
          data: {
            order: {
              cashReturn: true,
              orderType: 'cash_pickup',
              pickupDate: moment().startOf('day').toISOString(),
              deliveryDate: moment().add(1, 'day').startOf('day').toISOString(),
              status: OrderStatus.orderApproved,
            }
          }
        },
      }
    }
  });

  const isDriver = userHasRole([2], sState);

  const startOpen = () => {
    setState(s => ({ ...s, editModal: s.newEditModal }));
  }

  const onClose = () => {
    setState(s => ({ ...s, editModal: undefined }));
    props.onClose && props.onClose();
  }

  if (!isDriver) { return null }

  return (
    <div>
      <Button onClick={startOpen}>Cash Pickup</Button>
      <OrderModal editModal={state.editModal} onClose={onClose} isModal={true} />
    </div>
  )
}

export const Orders = ScreenComponent(withRouter(OrdersComponent));
