import {
  signUp as signUpApi,
  confirmSignUp,
  signIn as signInApi,
  forgotPassword as fgPasswordApi,
  setUserToken,
} from '../_shared/services/authentication.service';
import {
  getUser,
  updateUser as updateUserApi,
} from '../_shared/services/manage-users.service';
import { ACTIONS } from '../actions/reducers';
import { get } from 'lodash';

export const signUp = (username, password, company, opts = {}) => async (
  dispatch,
  getState
) => {
  const resp = await signUpApi(username, password, company, opts);

  dispatch({
    type: ACTIONS.SET_CURRENT_USER,
    payload: { email: username, company, password },
  });

  return resp ? true : false;
};

export const logout = () => {
  localStorage.clear();
  window.location.href = '/login';
};

export const signIn = (username, password) => async (
  dispatch,
  getState,
) => {
  try {
    const resp = await signInApi(username, password);

    dispatch({
      type: ACTIONS.SET_CURRENT_USER,
      payload: {
        email: resp.email,
        password: undefined,
      },
    });

    if (resp) {
      await dispatch(getUserData());
      const user = getState().user;
      await dispatch(updateUser({ ...user, isActive: 1 }));
    }

    return resp ? true : false;
  } catch (err) {
    return false;
  }
};

export const setCurrentUser = (user) => async (
  dispatch,
  getState,
) => {
  dispatch({ type: ACTIONS.SET_CURRENT_USER, payload: user });
}

export const getUserData = () => async (
  dispatch,
  getState,
) => {
  const sState = getState();

  if (sState.user) {
    try {
      const user = await getUser(sState.user.email);
      if (!user.id) {
        return dispatch(logout());
      } else {
        await setUserToken({ dispatch, getState });
        const token = get(getState(), 'user.token', '');
        return dispatch({ type: ACTIONS.SET_CURRENT_USER, payload: { ...user, token } });
      }
    } catch (err) {
      console.warn(err);
      throw new Error(err);
    }
  }
};

export const updateUser = (user) => async (
  dispatch,
  getState,
) => {
  try {
    const u = await updateUserApi({
      ...user,
      userId: user.email,
    });
    dispatch({ type: ACTIONS.SET_CURRENT_USER, payload: u });
    return getState().user;
  } catch (err) {
    console.warn(err);
    throw new Error(err);
  }
};

export const verify = (email, code) => async (
  dispatch,
  getState,
) => {
  let resp = { attributes: {}, error: undefined };
  try {
    resp = await confirmSignUp(email, code);
  } catch (err) {
    resp.error = err;
  }
  return resp;
};

export const forgotPassword = (email) => async (
  dispatch,
  getState,
) => {
  return await fgPasswordApi(email);
};
