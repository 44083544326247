/** @jsx jsx */
import { useEffect, useRef } from 'react';
import { css, jsx } from '@emotion/core';
import { get, set } from 'lodash';
import { Checkbox, Input } from 'antd';

export const ExtraEditRender = props => {
  const state = useRef({}).current;
  const { cell, extras, onChange } = props;
  
  const changeValue = (val, key) => {
    const fieldkey = get(cell, 'dataIndex');
    set(state, key, val);
    
    onChange && onChange(val, key)
    
    cell.setFieldsValue({
      [fieldkey]: state,
    });
  }

  useEffect(() => {
    const dataIndex = get(cell, 'dataIndex');
    const vals = get(cell, `record.${dataIndex}`, {}) || {};
    Object.keys(vals).map(k => changeValue(vals[k], k));
  }, []);

  return (
    <div style={{ lineHeight: 1.5 }}>
      {(extras || []).map(ve => {
        if (ve.type === 'boolean') {
          return <div><Checkbox checked={!!get(state, ve.id)} onChange={e => changeValue(e.target.checked, ve.id)}>{ve.title}</Checkbox></div>
        } else {
          return <div><Input type={ve.type} value={get(state, ve.id)} placeholder={ve.title} onChange={e => changeValue(e.target.value, ve.id)} /></div>
        }
      })}
    </div>
  )
}

export const ExtraRender = props => {
  const { extras, record } = props;
  return (
    <div>
      {(extras || []).map(ve => <div>{`${ve.title}: ${get(record, `data.${ve.id}`, 'N/A')}`}</div>)}
    </div>
  )
}