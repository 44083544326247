import React from 'react';
import { Icon, Button } from "antd"
import { useView } from './hooks/useView';

export const MenuButton = props => {
  const { toggleDrawer } = useView();

  return (
    <Button size="large" onClick={toggleDrawer} type="link" style={{ margin: 5 }}>
      <Icon type="menu" />
    </Button>
  )
}