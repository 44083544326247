/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import { Select, AutoComplete, Icon, Checkbox } from 'antd';
import { debounce, get } from 'lodash';
import { getRoutes } from '../services/routes.service';
import { styles } from './styles';
import moment from 'moment';
import { getStartOfWeek, getDayAsNumber, getRoutesWithUtilities } from '../../dashboard/drive-schedule/schedule';
import { getDriverSchedule } from '../services/driverschedule.service';
import { getDrivers } from '../services/drivers.service';
import { getVehicles } from '../services/vehicles.service';
import { getDriver, getRoute, getVehicle, jparse } from '../../actions/utils';

export const genDriverGuid = (obj) => {
  // const { driver, vehicle, route } = obj;
  const checkTypeOf = (key) => {
    return typeof obj[key] === 'string' ? obj[key] : get(obj, [key, 'id']);
  }

  return `${checkTypeOf('driver')}:${checkTypeOf('vehicle')}:${checkTypeOf('route')}`;
}

export const getDataFromGuid = id => {
  const [driver, vehicle, route] = (id || '').split(':');
  return { 
    _driver: driver,
    _vehicle: vehicle,
    _route: route,
    driver: getDriver(driver),
    vehicle: getVehicle(vehicle), 
    route: getRoute(route),
  };
}

export default class DriverSearch extends Component {
  state = {
    error: null,
    loading: false,
    date: undefined,
    value: undefined,
    selectedOption: undefined,
    opts: [],
    optsAll: [],
    options: [],
    
    drivers: [],
    vehicles: [],
    routes: [],
    schedule: undefined,
    showAll: false,
  };
  componentDidMount = async () => {
    if (this.props.initialValue) {
      this.setState({ value: this.props.initialValue });
    }
    this.getData();
  };
  getData = async () => {
    const [drivers, vehicles, routes] = await Promise.all([
      await getDrivers(),
      await getVehicles(),
      await getRoutes(),
    ])

    this.setState({ drivers, vehicles, routes: getRoutesWithUtilities(routes) }, this.retrieveSchedule)
  }
  retrieveSchedule = async () => {
    try {
      if (!this.state.date) { return }

      this.setState({ loading: true });

      const week = getStartOfWeek(this.state.date);

      const schedule = await getDriverSchedule({ date: week.toISOString() });

      const findOption = (key, id) => {
        return get(this, `state.${key}`, []).find(it => it.id === id);
      }

      const opts = get(schedule, `data.days.${getDayAsNumber(this.state.date)}`, []).map(opt => {
        const driver = findOption('drivers', opt.driver);
        const vehicle = findOption('vehicles', opt.vehicle);
        const route = findOption('routes', opt.route);

        return {
          ...opt,
          driver,
          vehicle,
          route,
          value: genDriverGuid({ driver, vehicle, route }),
          title: `Driver: ${get(driver, 'name', 'none')} - Vehicle: ${get(vehicle, 'name', 'none')} - Route: ${get(route, 'name', 'none')}`
        }
      }).filter(r => {
        if (this.state.showAll) { return true; }
        return get(r, 'route.id') === get(this, 'state.route.id');
      });

      this.setState({
        schedule,
        opts,
        optsAll: opts,
        loading: false,
      }, this.createOptions);
    } catch (err) {
      console.error(err);
      this.setState({ loading: false });
    }
  }
  componentDidUpdate = (prevProps, prevState) => {
    const { date, route } = this.props;
    if (date !== prevProps.date || route !== prevProps.route || this.state.showAll !== prevState.showAll) {
      const state = { ...this.state };
      state.date = moment(this.props.date);
      state.route = jparse(route, {});
      this.setState(state, this.retrieveSchedule);
    }
  }
  onChange = (value) => {
    this.setState({ value });
  }
  createOptions = () => {
    this.setState({
      options: this.state.opts.map(r => <Select.Option key={r.value} value={r.value}>{r.title}</Select.Option>)
    }, this.findMostRelevant);
  }
  findMostRelevant = () => {
    const { options, route, value } = this.state;

    const found = options.find(o => {
      const { value } = o.props;
      return value.indexOf(get(route, 'id')) > -1;
    })

    if (found && !value) {
      this.selectOption(get(found, 'props.value'));
    }
  }
  setValue = value => {
    this.setState({ value });
  }
  onSearch = debounce((input) => {
    const { optsAll } = this.state;

    this.setState({ loading: true });

    const val = input.toLowerCase();

    const opts = optsAll.filter(l => {
      const name = get(l, 'title').toLowerCase();
      if (name.indexOf(val) > -1) {
        return true;
      } else {
        return false;
      }
    })

    this.setState({
      opts,
      loading: false,
    }, this.createOptions);
  }, 500);
  selectOption = (val) => {
    const { onSelect } = this.props;
    this.setState({ 
      selectedOption: val, 
      value: val 
    }, () => onSelect && onSelect(val));
  }
  render() {
    const { loading, value, options, showAll } = this.state;

    return (
      <div css={css(styles.container)}>
        <AutoComplete
          placeholder={'Type to filter results'}
          onSearch={this.onSearch}
          onSelect={this.selectOption}
          dataSource={options}
          onChange={this.onChange}
          value={value}
          loading={loading}
          {...this.props}
        />
        {loading && <Icon css={css(styles.loader)} type="loading" />}
        <Checkbox style={{ position: 'absolute', top: -28, left: 140, width: 300 }} checked={showAll} onChange={e => this.setState({ showAll: !showAll })}>Show All Drivers</Checkbox>
      </div>
    );
  }
};
