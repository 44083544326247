import { combineReducers } from 'redux'

export const ACTIONS = {
  SET_LOCATIONS: 'SET_LOCATIONS',
  SET_SEARCHS: 'SET_SEARCHS',
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  SET_ORDERS: 'SET_ORDERS',
  SET_TOKEN: 'SET_TOKEN',
  SET_CONFIG: 'SET_CONFIG',
}

const defState = {
  view: {
    drawerOpen: false, //mobile only
  },
  user: {
    
  },
  search: {
    searchs: []
  },
  location: {
    locations: []
  },
  app: {
    v: "1.0.0",
    help: null,
    config: {},
  },
  order: {
    orders: []
  }
}

function _view(state = defState.view, action) {
  switch (action.type) {
    case 'SET_VIEW':
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

function _user(state = defState.user, action) {
  switch (action.type) {
    case ACTIONS.SET_CURRENT_USER:
      return {
        ...state,
        ...action.payload,
      }
    case ACTIONS.SET_TOKEN:
      return {
        ...state,
        token: action.token,
      }
    default:
      return state;
  }
}

function _order(state = defState.order, action) {
  switch (action.type) {
    case ACTIONS.SET_ORDERS:
      return {
        ...state,
        orders: action.orders,
      }
    default:
      return state;
  }
}

function _search(state = defState.search, action) {
  switch (action.type) {
    case ACTIONS.SET_SEARCHS:
      return {
        ...state,
        searchs: action.searchs
      }
    default:
      return state;
  }
}

function _location(state = defState.location, action) {
  switch (action.type) {
    case ACTIONS.SET_LOCATIONS:
      return {
        ...state,
        locations: action.locations
      }
    default:
      return state;
  }
}

function _app(state = defState.app, action) {
  switch (action.type) {
    case ACTIONS.SET_CONFIG:
      return {
        ...state,
        config: action.config,
      }
    default:
      return state;
  }
}

const view = _view;
const app = _app;
const user = _user;
const location = _location;
const search = _search;
const order = _order;

const rootReducer = combineReducers({
  view,
  user,
  location,
  app,
  search,
  order,
})

export default rootReducer;
export const DefaultState = JSON.parse(JSON.stringify(defState));