import { get } from 'lodash';
import { Profile } from './profile';
import { Orders } from './orders';
import { Order } from './order';
import { Accounts } from './accounts';
import { ChangeEmail } from '../authentication';
import { Routes as RoutesComponent } from './routes';
import { Route } from './route';
import { Schedule } from './schedule';
import { Billing } from './billing';
import { DriveSchedule } from './drive-schedule';
import { ChangePassword } from './change-password';
import { Reconciliation } from './reconciliation';
import { Invite } from './invite';
import { Reports } from './reports';
import { UserReports } from './user-reports';
import { Checkin } from './checkin';
import { LNUpdate } from './lnupdate';
import { FAQ } from './faq';
import { ScheduleSearch } from './schedule-search';
import { FleetManager } from './fleet-manager';
import { DeleteAccount } from './delete-account';
import { ManifestDownload } from './manifest-download';

export const SideRoutes = [
  {
    title: 'Profile',
    icon: { type: 'user' },
    path: '/dashboard/profile',
  },
  // {
  //   title: 'Schedule',
  //   icon: { lib: 'fontawesome', type: 'calendar-alt' },
  //   path: '/dashboard/schedule',
  // },
  {
    title: 'Invite Users',
    icon: { type: 'usergroup-add' },
    path: '/dashboard/invite',
    allowedRoles: [0, 3],
  },
  {
    title: 'Outgoing Orders',
    icon: { type: 'menu-unfold' },
    path: '/dashboard/orders',
    restrictRoles: [2],
  },
  {
    title: 'Incoming Orders',
    icon: { type: 'menu-fold' },
    path: '/dashboard/incomingorders',
    restrictRoles: [2],
  },
  // {
  //   title: 'Placed Orders',
  //   icon: { type: 'menu-fold' },
  //   path: '/dashboard/placedorders',
  //   allowedRoles: [0, 3],
  // },
  {
    title: 'New Order',
    icon: { type: 'plus' },
    path: '/dashboard/createOrder',
    restrictRoles: [2],
  },
  {
    title: 'Schedules',
    icon: { lib: 'fontawesome', type: 'calendar-alt' },
    path: '/dashboard/manageSchedule',
    allowedRoles: [0, 2],
  },
  {
    title: 'Delivery Schedule',
    icon: { type: 'file-search' },
    path: '/dashboard/schedulesearch',
    restrictRoles: [2],
  },
  {
    title: 'Driver Schedule',
    icon: { lib: 'fontawesome', type: 'car' },
    path: '/dashboard/manageDriver',
    allowedRoles: [0, 2],
  },
  // {
  //   title: 'Reports',
  //   icon: { type: 'table' },
  //   path: '/dashboard/userreports',
  // },
  {
    title: 'Check Out',
    icon: { type: 'login' },
    path: '/dashboard/checkout',
    restrictRoles: [2],
  },
  {
    title: 'Manifest Download',
    icon: { type: 'download' },
    path: '/dashboard/manifestdownload',
    restrictRoles: [2],
  },
  {
    title: 'FAQ',
    icon: { type: 'question-circle' },
    path: '/dashboard/faq',
    restrictRoles: [2],
  },
];

export const AdminSideRoutes = [
  {
    title: 'All Accounts',
    icon: { type: 'user' },
    path: '/dashboard/accounts',
    restrictRoles: [2],
  },
  {
    title: 'All Orders',
    icon: { type: 'unordered-list' },
    path: '/dashboard/adminManageOrders',
    restrictRoles: [2],
  },
  {
    title: 'All Routes',
    icon: { lib: 'fontawesome', type: 'directions' },
    path: '/dashboard/manageRoutes',
    restrictRoles: [2],
  },
  {
    title: 'Schedules',
    icon: { lib: 'fontawesome', type: 'calendar-alt' },
    path: '/dashboard/manageSchedule',
  },
  {
    title: 'Driver Schedule',
    icon: { lib: 'fontawesome', type: 'car' },
    path: '/dashboard/manageDriver',
  },
  {
    title: 'Billing',
    icon: { lib: 'fontawesome', type: 'money-check-alt' },
    path: '/dashboard/billing',
    restrictRoles: [2],
  },
  {
    title: 'Reports',
    icon: { type: 'table' },
    path: '/dashboard/reports',
    restrictRoles: [2],
  },
  {
    title: 'Check In',
    icon: { type: 'login' },
    path: '/dashboard/checkin',
    restrictRoles: [2],
  },
  {
    title: 'LN Update',
    icon: { type: 'edit' },
    path: '/dashboard/lnupdate',
    restrictRoles: [2],
  },
  {
    title: 'Fleet Manager',
    icon: { type: 'car' },
    path: '/dashboard/fleetmanager',
    restrictRoles: [2],
  },
];

export const Routes = {
  account: {
    title: 'Account',
    icon: { type: 'user' },
    path: '/dashboard/profile',
    component: Profile,
    sideRoutes: SideRoutes,
  },
  accounts: {
    title: 'Accounts',
    icon: { type: 'user' },
    path: '/dashboard/accounts',
    component: Accounts,
    sideRoutes: AdminSideRoutes,
  },
  invite: {
    title: 'Invite',
    icon: { type: 'usergroup-add' },
    path: '/dashboard/invite',
    highlighted: '/dashboard/invite',
    component: Invite,
    sideRoutes: SideRoutes,
  },
  accountDetails: {
    title: 'Account',
    icon: { type: 'user' },
    path: '/dashboard/accountDetails',
    component: Profile,
    sideRoutes: SideRoutes,
  },
  landing: {
    title: 'Dashboard',
    icon: { type: 'user' },
    path: '/dashboard',
    exact: true,
    component: Profile,
    sideRoutes: SideRoutes,
  },
  orders: {
    title: 'Outgoing Orders',
    icon: { type: 'menu-unfold' },
    path: '/dashboard/orders',
    highlighted: '/dashboard/orders',
    component: Orders,
    sideRoutes: SideRoutes,
  },
  incomingorders: {
    title: 'Incoming Orders',
    icon: { type: 'menu-fold' },
    path: '/dashboard/incomingorders',
    highlighted: '/dashboard/incomingorders',
    component: Orders,
    sideRoutes: SideRoutes,
  },
  placedorders: {
    title: 'Placed Orders',
    icon: { type: 'menu-fold' },
    path: '/dashboard/placedorders',
    highlighted: '/dashboard/placedorders',
    component: Orders,
    sideRoutes: SideRoutes,
  },
  adminOrders: {
    title: 'Orders',
    icon: { type: 'unordered-list' },
    path: '/dashboard/adminManageOrders',
    highlighted: '/dashboard/adminManageOrders',
    component: Orders,
    sideRoutes: AdminSideRoutes,
  },
  adminEditOrder: {
    title: 'Edit Order',
    icon: { type: 'plus' },
    path: '/dashboard/adminEditOrder',
    highlighted: '/dashboard/adminManageOrders',
    component: Order,
    sideRoutes: AdminSideRoutes,
  },
  adminRoutes: {
    title: 'Routes',
    icon: { type: 'ordered-list' },
    path: '/dashboard/manageRoutes',
    highlighted: '/dashboard/manageRoutes',
    component: RoutesComponent,
    sideRoutes: AdminSideRoutes,
  },
  adminEditRoute: {
    title: 'Edit Route',
    icon: { type: 'plus' },
    path: '/dashboard/editRoute',
    highlighted: '/dashboard/manageRoutes',
    component: Route,
    sideRoutes: AdminSideRoutes,
  },
  adminSchedule: {
    title: 'Schedule',
    icon: { type: 'calendar-alt' },
    path: '/dashboard/manageSchedule',
    highlighted: '/dashboard/manageSchedule',
    component: Schedule,
    sideRoutes: AdminSideRoutes,
  },
  schedule: {
    title: 'Schedule',
    icon: { type: 'calendar-alt' },
    path: '/dashboard/schedule',
    highlighted: '/dashboard/schedule',
    component: Schedule,
    sideRoutes: SideRoutes,
  },
  adminDriver: {
    title: 'Driver Schedule',
    icon: { type: 'car' },
    path: '/dashboard/manageDriver',
    highlighted: '/dashboard/manageDriver',
    component: DriveSchedule,
    public: true,
    showOnLanding: false,
    sideRoutes: AdminSideRoutes,
  },
  adminBilling: {
    title: 'Billing',
    icon: { type: 'money-check-alt' },
    path: '/dashboard/manageBilling',
    highlighted: '/dashboard/manageBilling',
    component: Billing,
    sideRoutes: AdminSideRoutes,
  },
  editOrder: {
    title: 'Edit Order',
    icon: { type: 'plus' },
    path: '/dashboard/editOrder',
    highlighted: '/dashboard/orders',
    component: Order,
    sideRoutes: SideRoutes,
  },
  order: {
    title: 'Create Order',
    icon: { type: 'plus' },
    path: '/dashboard/createOrder',
    highlighted: '/dashboard/createOrder',
    component: Order,
    sideRoutes: SideRoutes,
  },
  admin: {
    title: 'Admin',
    icon: { type: 'user' },
    path: '/dashboard/admin',
    component: Profile,
    sideRoutes: AdminSideRoutes,
    allowedRoles: [0]
  },
  editAccount: {
    title: 'Edit Account',
    icon: { type: 'user' },
    path: '/dashboard/editAccount',
    highlighted: '/dashboard/accounts',
    component: Profile,
    sideRoutes: AdminSideRoutes,
  },
  changeEmail: {
    title: 'Change Email',
    icon: { type: 'lock' },
    path: '/dashboard/changeEmail',
    highlighted: '/dashboard/accounts',
    component: ChangeEmail,
    sideRoutes: SideRoutes,
  },
  changePassword: {
    title: 'Change Password',
    icon: { type: 'lock' },
    path: '/dashboard/changePassword',
    highlighted: '/dashboard/changepassword',
    component: ChangePassword,
    sideRoutes: SideRoutes,
  },
  reconciliation: {
    title: 'Billing',
    icon: { type: 'profile' },
    path: '/dashboard/billing',
    highlighted: '/dashboard/billing',
    component: Reconciliation,
    sideRoutes: AdminSideRoutes,
  },
  reports: {
    title: 'Reports',
    icon: { type: 'table' },
    path: '/dashboard/reports',
    highlighted: '/dashboard/reports',
    component: Reports,
    sideRoutes: AdminSideRoutes,
  },
  userreports: {
    title: 'Reports',
    icon: { type: 'table' },
    path: '/dashboard/userreports',
    highlighted: '/dashboard/userreports',
    component: UserReports,
    sideRoutes: SideRoutes,
  },
  checkin: {
    title: 'Check In',
    icon: { type: 'login' },
    path: '/dashboard/checkin',
    highlighted: '/dashboard/checkin',
    component: Checkin,
    sideRoutes: AdminSideRoutes,
  },
  checkout: {
    title: 'Check Out',
    icon: { type: 'login' },
    path: '/dashboard/checkout',
    highlighted: '/dashboard/checkout',
    component: Checkin,
    sideRoutes: SideRoutes,
  },
  manifestdownload: {
    title: 'Download Manifests',
    icon: { type: 'download' },
    path: '/dashboard/manifestdownload',
    highlighted: '/dashboard/manifestdownload',
    component: ManifestDownload,
    sideRoutes: SideRoutes,
  },
  lnupdate: {
    title: 'LN Update',
    icon: { type: 'edit' },
    path: '/dashboard/lnupdate',
    highlighted: '/dashboard/lnupdate',
    component: LNUpdate,
    sideRoutes: AdminSideRoutes,
  },
  fleetmanager: {
    title: 'Fleet Manager',
    icon: { type: 'car' },
    path: '/dashboard/fleetmanager',
    highlighted: '/dashboard/fleetmanager',
    component: FleetManager,
    sideRoutes: AdminSideRoutes,
  },
  schedulesearch: {
    title: 'Delivery Schedule',
    landingTitle: 'Click here for delivery schedule',
    icon: { type: 'file-search' },
    path: '/dashboard/schedulesearch',
    highlighted: '/dashboard/schedulesearch',
    component: ScheduleSearch,
    public: true,
    sideRoutes: SideRoutes,
  },
  accountdelete: {
    title: 'Delete Account',
    landingTitle: 'How to delete your account',
    icon: { type: 'user-delete' },
    path: '/dashboard/deleteaccount',
    highlighted: '/dashboard/schedulesearch',
    sideRoutes: SideRoutes,
    component: DeleteAccount,
  },
  faq: {
    title: 'FAQ',
    icon: { type: 'question-circle' },
    path: '/dashboard/faq',
    highlighted: '/dashboard/faq',
    component: FAQ,
    public: true,
    sideRoutes: SideRoutes,
  },
  publicorders: {
    title: 'Orders',
    icon: { type: 'unordered-list' },
    path: '/dashboard/publicorders',
    highlighted: '/dashboard/publicorders',
    component: Orders,
    public: true,
    showOnLanding: false,
    logo: {
      logo: require('../assets/img/leaflink-logo.png'),
      width: 110,
      multiplier: 0.25,
    },
  },
};

export const getPublicRoutes = () => {
  return Object.keys(Routes).filter(k => !!get(Routes, [k, 'public'])).map(k => get(Routes, k));
}