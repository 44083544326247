/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Component } from 'react'
import { TableList, TableListHeader } from '../../_shared/table-list';
import { Input, Icon, Checkbox, Tooltip, Modal } from 'antd';
import Row from '../../_shared/row';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getAccounts } from '../../_shared/services/accounts.service';
import { syncQuickbooks } from '../../_shared/services/quickbooks.service';
import { get, upperFirst } from 'lodash';
import { Button } from '../../_shared/button';
import SStyles from '../../_shared/styles';
import colors from '../../_shared/colors';
import { exportToCsv } from '../../actions/utils';
import moment from 'moment';
import { Profile } from '../profile';
import { ROLES } from '../../models/account';

const COLS = [
  {
    title: 'Company Name',
    dataIndex: 'data.company.Licensee',
    width: '25%',
    render: (text, row) => {
      const hasMismatch = get(row, 'data.notification.qbMismatch');
      return (
        <div>
          <span>{text}</span>
          {hasMismatch && 
            <Tooltip placement="top" title={`This users account doesn't match any known customer in Quickbooks.`}>
              <Icon type="exclamation-circle" style={{ marginLeft: 10, color: colors.error }} />
            </Tooltip>}
        </div>
      );
    }
  },
  {
    title: 'License Number',
    dataIndex: 'data.company[License #]',
    width: '15%'
  },
  {
    title: 'Address',
    dataIndex: 'data.company[Street Address]',
    width: '20%'
  },
  {
    title: 'Phone',
    dataIndex: 'data.company.phone',
    width: '10%'
  },
  {
    title: 'Email',
    dataIndex: 'email',
    width: '20%'
  },
  {
    title: 'Role',
    dataIndex: 'role',
    width: '10%',
    render: (text, row) => {
      const role = get(row, 'data.role');
      const admins = [0, 3];

      return (
        <div>
          {upperFirst(admins.indexOf(role) > -1 ? 'Admin' : get(ROLES, role, 'User'))}
        </div>
      )
    }
  },
]

export class AccountsComponent extends Component {
  state = {
    allAccounts: [],
    accounts: [],
    loading: false,
    error: undefined,
    success: undefined,
    syncing: false,
    editModal: undefined,
    searchTerm: '',
  }
  componentDidMount = () => {
    this.getAccounts();
  }
  getAccounts = async () => {
    try {
      this.setState({ loading: true });
      const accounts = await getAccounts();
      this.setState({ allAccounts: accounts, accounts: accounts.filter(a => get(a, 'data.verified')), loading: false }, () => {
        if (this.state.searchTerm) {
          this.filterAccounts();
        }
      });
    } catch (err) {
      this.setState({ loading: false, error: err.message });
    }
  }
  filterAccounts = () => {
    const { allAccounts, searchTerm } = this.state;
    const st = searchTerm.toLowerCase();
    const accounts = allAccounts.filter(a => {
      const name = get(a, 'data.company.Licensee', '').toLowerCase();
      const email = get(a, 'data.email', '').toLowerCase();
      const id = ([...get(a, 'data.company[License #]', ''), ...get(a, 'data.company.licenseNumbers', ['']) ]).join(',');

      return name.indexOf(st) > -1 || id.indexOf(st) > -1 || email.indexOf(st) > -1;
    })
    this.setState({ accounts });
  }
  selectAccount = (record, index) => {
    const editAccount = { ...record, data: { ...get(record, 'data', {}), ...record } }
    this.setState({ editModal: { state: { editAccount } } });
  };
  onSearch = () => {
    this.filterAccounts();
  }
  createAccount = () => {
    this.props.history.push('/dashboard/profile', {
      isCreate: true,
      newUser: true,
    });
  }
  syncQuickbooks = async () => {
    if(this.state.syncing) return;

    this.setState({ syncing: true });
    await syncQuickbooks();
    await this.getAccounts();
    this.setState({ syncing: false });
  }
  onCheckChange = (e) => {
    const checked = e.target.checked;
    const { allAccounts } = this.state;
    
    this.setState({
      accounts: allAccounts.filter(a => checked ? !get(a, 'data.verified') : get(a, 'data.verified'))
    })
  }
  exportAccounts = () => {
    const rows = [COLS.map(c => c.title)];
    this.state.accounts.map(order => {
      rows.push(COLS.map(c => get(order, c.dataIndex, '')))
    })
    exportToCsv(`Accounts-${moment().toISOString()}.csv`, rows);
  }
  closeEditModal = (shouldUpdate) => {
    this.setState({ editModal: undefined });
    if (shouldUpdate) {
      this.getAccounts();
    }
  }
  render() {
    const {
      accounts,
      loading,
      syncing,
      editModal,
    } = this.state;

    return (
      <div>
        <TableListHeader>
          <Row css={css(`width: 1000px;`)}>
            <Input.Search
              placeholder={'Search by Company Name, License Number, or Email...'}
              enterButton
              onChange={e => this.setState({ searchTerm: e.target.value })}
              onSearch={this.onSearch}
            />
            <Checkbox onChange={this.onCheckChange} css={css(SStyles.row, `margin-left: 10px;`)}>
              Show Non Verfied Users
            </Checkbox>
          </Row>
          <Button
            onClick={this.exportAccounts}
            outline
          >
            Export accounts
          </Button>
          <Button
            onClick={this.syncQuickbooks}
            outline
          >
            <Icon type="sync" spin={syncing} />
            Sync Quickbooks
          </Button>
          <Button
            onClick={this.createAccount}
          >
            <Icon type="plus" />
            Create Account
          </Button>
        </TableListHeader>
        <TableList
          data={accounts}
          loading={loading}
          columns={COLS}
          rowClick={this.selectAccount}
          onRefresh={this.getAccounts}
        />
        <AccountModal editModal={editModal} onClose={this.closeEditModal} />
      </div>
    )
  }
}

export const AccountModal = props => {
  const { editModal, onClose } = props;
  
  return (
    <Modal
      title={`Edit Account`}
      visible={!!editModal}
      width={'90%'}
      style={{ top: 30 }}
      onOk={() => onClose()}
      onCancel={() => onClose()}
      footer={(null)}
    >
      {editModal && <Profile {...editModal} onClose={onClose} isModal={true} />}
    </Modal>
  )
}

export const Accounts = connect(sState => ({ sState }))(withRouter(AccountsComponent));