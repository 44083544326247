/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { useState } from 'react';
import { message, DatePicker, Button } from 'antd';
import moment from 'moment';
import styles from './styles'
import { getOrdersRange, runSignatureReport } from '../../_shared/services/orders.service';
import { getCols } from '../schedule/schedule.component';
import { get, set, size } from 'lodash';
import { exportToCsv, jparse, orderIsRejected } from '../../actions/utils';
import { getDataFromGuid } from '../../_shared/driver-search';
import { getStatusIcon, OrderStatus, Rejections } from '../../models/order';
import { useStore } from 'react-redux';
import { getRoutes } from '../../_shared/services/routes.service';

const DEL_STATUS = {
  '0': 'Not deleted',
  '1': 'Deleted',
  '2': 'Canceled'
}

export const Reports = props => {
  const store = useStore();
  const [state, setState] = useState({
    loading: false,
    saving: false,
    selectedDate: [moment().subtract(1, 'month'), moment()],
  })

  const startGenerate = async () => {
    try {
      setState(s => ({ ...s, loading: true }));
      const orders = await getOrdersRange({ selectedDate: state.selectedDate });

      const ALLCOLS = [...getCols({ props: { sState: store.getState() }, isReport: true }), 
        { title: 'Delivery Date', value: row => moment(get(row, 'data.order.deliveryDate')).format('L') }, 
        { title: 'Route', value: (row) => get(jparse(get(row, 'data.order.route')), 'name', '') },
        { title: 'Deleted', value: (row) => get(DEL_STATUS, get(row, 'deleted', 0)) },
        { title: 'Delivery Status', value: row => getStatusIcon(row).title },
      ];
      const rows = [ALLCOLS.map(c => c.title)];
      orders.map(order => {
        rows.push(ALLCOLS.map(c => c.value ? c.value(order) : get(order, c.dataIndex, '')))
      })
      exportToCsv(`DataReport-${moment().toISOString()}.csv`, rows);
    } catch (err) {
      message.error(`Failed to generate report: ${err.message}`);
    } finally {
      setState(s => ({ ...s, loading: false }));
    }
  }

  const timestampReport = async () => {
    try {
      setState(s => ({ ...s, loading: true }));
      const orders = await getOrdersRange({ selectedDate: state.selectedDate });

      const getDateApproved = o => {
        const hasApproved = get(o, 'data.order.dateApproved');
        return hasApproved || get(o, 'lastUpdated');
      }

      const ALLCOLS = [
        { title: 'Vendor', value: row => get(row, 'data.pickupCompany.Licensee', '') },
        { title: 'Date Placed', value: row => moment(get(row, 'created')).format('L') }, 
        { title: 'Time Placed', value: row => moment(get(row, 'created')).format('hh:mma') }, 
        { title: 'Approved Delivery Date', value: row => moment(get(row, 'deliveryDate')).format('L') }, 
        { title: 'Date Approved', value: row => moment(getDateApproved(row)).format('L') }, 
        { title: 'Time Approved', value: row => moment(getDateApproved(row)).format('hh:mma') }, 
        { 
          title: 'Day Difference', 
          value: (row) => {
            const placed = moment(get(row, 'created'));
            const approved = moment(get(row, 'deliveryDate'));
            return `${(approved.diff(placed, 'hours') / 24).toFixed(2)} days`;
          }
        },
        { 
          title: 'Time Difference', 
          value: (row) => {
            const placed = moment(get(row, 'created'));
            const approved = moment(getDateApproved(row));
            const dur = moment.duration(approved.diff(placed, 'minutes'), 'minutes');
            return `${dur.days()}:${dur.hours()}:${dur.minutes()}`;
          }
        },
      ];
      const rows = [ALLCOLS.map(c => c.title)];
      orders.filter(o => get(o, 'data.order.status', 0) >= 1 && !get(o, 'deleted', 0)).map(order => {
        rows.push(ALLCOLS.map(c => c.value ? c.value(order) : get(order, c.dataIndex, '')))
      })
      exportToCsv(`TimeStampReport-${moment().toISOString()}.csv`, rows);
    } catch (err) {
      message.error(`Failed to generate report: ${err.message}`);
    } finally {
      setState(s => ({ ...s, loading: false }));
    }
  }

  const rejectionReport = async () => {
    try {
      setState(s => ({ ...s, loading: true }));
      const orders = await getOrdersRange({ selectedDate: state.selectedDate });

      const ALLCOLS = [
        { title: 'Rejection Date', value: row => moment(get(row, 'data.order.rejectedDate')).format('L') }, 
        { title: 'Vendor', value: row => get(row, 'data.pickupCompany.Licensee', '') },
        { title: 'Destination', value: row => get(row, 'data.destCompany.Licensee', '') },
        { title: 'Reason', value: row => get(Rejections.find(r => r.value === get(row, 'data.order.rejected')), 'title') },
        { title: 'Return Date', value: row => moment(get(row, 'deliveryDate')).format('L') }, 
        { title: 'Route', value: row => {
          const route = jparse(get(row, 'data.order.route'));
          return get(route, 'name', 'n/a');
        } }, 
        { title: 'Driver', value: row => {
          const { driver } = getDataFromGuid(get(row, 'data.order.driver.delivery'));
          return get(driver, 'name', 'n/a');
        } }, 
      ];
      const rows = [ALLCOLS.map(c => c.title)];
      orders.filter(o => orderIsRejected(o) && !get(o, 'deleted', 0)).map(order => {
        rows.push(ALLCOLS.map(c => c.value ? c.value(order) : get(order, c.dataIndex, '')))
      })
      exportToCsv(`RejectionReport-${moment().toISOString()}.csv`, rows);
    } catch (err) {
      message.error(`Failed to generate report: ${err.message}`);
    } finally {
      setState(s => ({ ...s, loading: false }));
    }
  }

  const profitReport = async () => {
    try {
      setState(s => ({ ...s, loading: true }));
      const [_orders, routes] = await Promise.all([
        getOrdersRange({ selectedDate: state.selectedDate }),
        getRoutes(),
      ]);

      const validStatuses = [OrderStatus.delivered, OrderStatus.inTransit, OrderStatus.orderApproved, OrderStatus.orderPickedUp];
      const orders = _orders.filter(o => !get(o, 'deleted', 0) && validStatuses.includes(get(o, 'data.order.status')) && !!get(o, 'data.order.route'));

      const orderRoutes = {};

      orders.map(o => {
        const route = jparse(get(o, 'data.order.route'));
        const proute = routes.find(r => r.id == route.parent);
        const croute = get(proute, 'data.rows', []).find(r => r.zipCode == route.zipCode);

        const obj = {
          ...o,
          price: parseFloat(get(croute, 'price', 0)),
          croute,
        };

        set(orderRoutes, proute.id, {
          proute,
          data: [
            ...get(orderRoutes, [proute.id, 'data'], []),
            obj,
          ]
        })
      })

      const ALLCOLS = [...getCols({ props: { sState: store.getState() }, isReport: true }), 
        { title: 'Price', value: row => `$${get(row, 'price')}` }, 
      ];

      const rows = [];

      Object.keys(orderRoutes).map(k => {
        const { proute, data } = orderRoutes[k];

        rows.push([get(proute, 'name')]);
        rows.push(ALLCOLS.map(c => c.title));

        let total = 0;
        data.map(o => {
          rows.push(ALLCOLS.map(c => c.value ? c.value(o) : get(o, c.dataIndex, '')))
          total += o.price;
        })

        rows.push(['Total Revenue', `$${total.toFixed(2)}`]);
        rows.push(['']);
      })

      exportToCsv(`ProfitabilityReport-${moment().toISOString()}.csv`, rows);
    } catch (err) {
      message.error(`Failed to generate report: ${err.message}`);
    } finally {
      setState(s => ({ ...s, loading: false }));
    }
  }

  const signatureReport = async () => {
    try {
      setState(s => ({ ...s, loading: true }));
      const orders = await runSignatureReport();

      if (!size(orders)) {
        return message.success('No orders missing signatures');
      }

      const ALLCOLS = [...getCols({ props: { sState: store.getState() }, isReport: true }), 
        { title: 'Delivery Date', value: row => moment(get(row, 'data.order.deliveryDate')).format('L') }, 
        { title: 'Route', value: (row) => get(jparse(get(row, 'data.order.route')), 'name', '') },
        { title: 'Deleted', value: (row) => get(DEL_STATUS, get(row, 'deleted', 0)) },
        { title: 'Delivery Status', value: row => getStatusIcon(row).title },
      ];
      const rows = [ALLCOLS.map(c => c.title)];
      orders.map(order => {
        rows.push(ALLCOLS.map(c => c.value ? c.value(order) : get(order, c.dataIndex, '')))
      })
      exportToCsv(`SignatureReport-${moment().toISOString()}.csv`, rows);
    } catch (err) {
      message.error(err.toString());
    } finally {
      setState(s => ({ ...s, loading: false }));
    }
  }

  const isbringgpage = window.location.href.indexOf('bringgreport') > -1;

  return (
    <div>
      {!isbringgpage && <div>
        <DatePicker.RangePicker
          css={css(styles.input)}
          loading={state.loading}
          value={state.selectedDate}
          onChange={selectedDate => setState(s => ({ ...s, selectedDate }))}
        />
        <Button type="primary" css={css(styles.btn)} loading={state.loading} onClick={startGenerate}>Export Orders</Button>
        <Button type="primary" css={css(styles.btn)} loading={state.loading} onClick={timestampReport}>Time Stamp Report</Button>
        <Button type="primary" css={css(styles.btn)} loading={state.loading} onClick={rejectionReport}>Rejection Report</Button>
        <Button type="primary" css={css(styles.btn)} loading={state.loading} onClick={profitReport}>Route Profitability Report</Button>
        <Button type="primary" css={css(styles.btn)} loading={state.loading} onClick={signatureReport}>Signature Report</Button>
      </div>}
    </div>
  )
}