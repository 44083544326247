import { get } from 'lodash';
import { instance } from './api.service';

export const getVehicles = async (where) => {
  let resp = {};
  if (where) {
    resp = await instance.post(`/vehicles`, {
      where,
    });
  } else {
    resp = await instance.get(`/vehicles`, { data: {} });
  }
  return get(resp, 'data.data', []);
};

export const createVehicle = async (vehicle) => {
  const resp = await instance.post('/vehicles/create', {
    vehicle,
  });
  return get(resp, 'data.data', null);
};

export const updateVehicle = async (vehicle) => {
  const resp = await instance.post('/vehicles/update', {
    vehicle,
  });
  return get(resp, 'data.data', null);
};

export const deleteVehicle = async (vehicle) => {
  const resp = await instance.post('/vehicles/delete', {
    vehicle
  });
  return get(resp, 'data.driver', null);
};

export const getFleetData = async () => {
  const resp = await instance.get(`/vehicles/fleetmanager`, { data: {} });
  return get(resp, 'data.data', []);
}

export const updateFleetData = async (data) => {
  const resp = await instance.post(`/vehicles/fleetmanager`, data);
  return get(resp, 'data.data', []);
}