import { get } from 'lodash';
import { instance } from './api.service';
import { fileToBuffer } from '../../actions/utils';

export const getAppConfig = async () => {
  const resp = await instance.get(`/utils/config`, { data: {} });
  return get(resp, 'data.data', []);
};

export const uploadFile = async (file) => {
  const buff = await fileToBuffer(file);
  const resp = await instance.post(`/utils/uploadfile`, { file: buff });
  return get(resp, 'data.result', {});
};