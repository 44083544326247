export const styles = {
  container: `
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  loader: `
    position: absolute;
    right: 10px;
  `
}