import { get, set, first } from 'lodash';
import { useSelector, useDispatch, useStore } from 'react-redux';

export const useView = props => {
  const view = useSelector(state => state.view);
  const store = useStore();
  const dispatch = useDispatch();

  const setView = (sel, value) => {
    const vstate = { ...store.getState().view };
    set(vstate, sel, value);
    dispatch({ type: 'SET_VIEW', payload: vstate });
  }

  const toggleDrawer = () => {
    const vstate = { ...store.getState().view };
    set(vstate, 'drawerOpen', !vstate.drawerOpen);
    dispatch({ type: 'SET_VIEW', payload: vstate });
  }

  return {
    view,
    setView,
    toggleDrawer,
  }
}