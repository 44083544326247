/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { useRef } from 'react';
import { Tabs } from 'antd';
import { Schedule } from './schedule';
import { Drivers } from './drivers';
import { Vehicles } from './vehicles';
import { get } from 'lodash';
import { useAuth } from '../../_shared/hooks/auth';

export const DriveSchedule = props => {
  const schedRef = useRef();
  const { loggedIn, userHasRole } = useAuth();

  const onChange = e => {
    if (e == 0) {
      get(schedRef, 'current.getData', () => 0)();
    }
  }

  if (!loggedIn) {
    return <Schedule ref={schedRef} />
  }

  const isDriver = userHasRole(2);

  return (
    <Tabs defaultActiveKey="0" onChange={onChange}>
      <Tabs.TabPane tab="Schedule" key="0">
        <Schedule ref={schedRef} />
      </Tabs.TabPane>
      {!isDriver && <Tabs.TabPane tab="Drivers" key="1">
        <Drivers />
      </Tabs.TabPane>}
      {!isDriver && <Tabs.TabPane tab="Vehicles" key="2">
        <Vehicles />
      </Tabs.TabPane>}
    </Tabs>
  )
}