import getConfig from '../../config';
import { get } from 'lodash';
import { instance } from './api.service';

export const getAccounts = async () => {
  const resp = await instance.get(`/users`, { data: {} });
  return get(resp, 'data.users', []);
};

export const createInvite = async (user) => {
  const config = getConfig();
  const resp = await instance.post(`/users/invite`, { user, config });
  return get(resp, 'data.invite', []);
};

export const sendInviteEmail = async (user) => {
  const config = getConfig();
  const resp = await instance.post(`/users/inviteemail`, { user, config });
  return get(resp, 'data.success', true);
};

export const getInvites = async where => {
  const resp = await instance.post(`/users/invites`, { where });
  return get(resp, 'data.invites', []);
}

export const updateInvites = async invites => {
  const resp = await instance.post(`/users/invites/update`, { invites });
  return get(resp, 'data.invites', []);
}