/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { useState } from 'react';
import { message, DatePicker, Button } from 'antd';
import moment from 'moment';
import styles from './styles'
import { getOrdersRangeForUser } from '../../_shared/services/orders.service';
import { getCols } from '../schedule/schedule.component';
import { get } from 'lodash';
import { exportToCsv, jparse } from '../../actions/utils';
import { useSelector } from 'react-redux';
import { useStore } from 'react-redux';
import { getStatusIcon } from '../../models/order';

const DEL_STATUS = {
  '0': 'Not deleted',
  '1': 'Deleted',
  '2': 'Canceled'
}

export const UserReports = props => {
  const store = useStore();
  const [state, setState] = useState({
    loading: false,
    saving: false,
    selectedDate: [moment().subtract(1, 'month'), moment()],
  })

  const user = useSelector(state => state.user);

  const startGenerate = async () => {
    try {
      setState(s => ({ ...s, loading: true }));
      const orders = await getOrdersRangeForUser({ selectedDate: state.selectedDate, user });

      const ALLCOLS = [...getCols({ props: { sState: store.getState() }, isReport: true }), 
        { title: 'Delivery Date', value: row => moment(get(row, 'data.order.deliveryDate')).format('L') }, 
        { title: 'Route', value: (row) => get(jparse(get(row, 'data.order.route')), 'name', '') },
        { title: 'Deleted', value: (row) => get(DEL_STATUS, get(row, 'deleted', 0)) },
        {
          title: 'Order Placed By',
          value: row => get(row, 'user.email', ''),
        },
        { title: 'Delivery Status', value: row => getStatusIcon(row).title },
      ];
      const rows = [ALLCOLS.map(c => c.title)];
      orders.map(order => {
        rows.push(ALLCOLS.map(c => c.value ? c.value(order) : get(order, c.dataIndex, '')))
      })
      exportToCsv(`DataReport-${moment().toISOString()}.csv`, rows);
    } catch (err) {
      message.error(`Failed to generate report: ${err.message}`);
    } finally {
      setState(s => ({ ...s, loading: false }));
    }
  }

  const timestampReport = async () => {
    try {
      setState(s => ({ ...s, loading: true }));
      const orders = await getOrdersRangeForUser({ selectedDate: state.selectedDate, user });

      const getDateApproved = o => {
        const hasApproved = get(o, 'data.order.dateApproved');
        return hasApproved || get(o, 'lastUpdated');
      }

      const ALLCOLS = [
        { title: 'Vendor', value: row => get(row, 'data.pickupCompany.Licensee', '') },
        { title: 'Date Placed', value: row => moment(get(row, 'created')).format('L') }, 
        { title: 'Time Placed', value: row => moment(get(row, 'created')).format('hh:mma') }, 
        { title: 'Approved Delivery Date', value: row => moment(get(row, 'deliveryDate')).format('L') }, 
        { title: 'Date Approved', value: row => moment(getDateApproved(row)).format('L') }, 
        { title: 'Time Approved', value: row => moment(getDateApproved(row)).format('hh:mma') }, 
        { 
          title: 'Day Difference', 
          value: (row) => {
            const placed = moment(get(row, 'created'));
            const approved = moment(get(row, 'deliveryDate'));
            return `${(approved.diff(placed, 'hours') / 24).toFixed(2)} days`;
          }
        },
        { 
          title: 'Time Difference', 
          value: (row) => {
            const placed = moment(get(row, 'created'));
            const approved = moment(getDateApproved(row));
            const dur = moment.duration(approved.diff(placed, 'minutes'), 'minutes');
            return `${dur.days()}:${dur.hours()}:${dur.minutes()}`;
          }
        },
        {
          title: 'Order Placed By',
          value: row => get(row, 'user.email', ''),
        }
      ];
      const rows = [ALLCOLS.map(c => c.title)];
      orders.filter(o => get(o, 'data.order.status', 0) >= 1 && !get(o, 'deleted', 0)).map(order => {
        rows.push(ALLCOLS.map(c => c.value ? c.value(order) : get(order, c.dataIndex, '')))
      })
      exportToCsv(`TimeStampReport-${moment().toISOString()}.csv`, rows);
    } catch (err) {
      message.error(`Failed to generate report: ${err.message}`);
    } finally {
      setState(s => ({ ...s, loading: false }));
    }
  }

  const isbringgpage = window.location.href.indexOf('bringgreport') > -1;

  return (
    <div>
      {!isbringgpage && <div>
        <DatePicker.RangePicker
          css={css(styles.input)}
          loading={state.loading}
          value={state.selectedDate}
          onChange={selectedDate => setState(s => ({ ...s, selectedDate }))}
        />
        <Button type="primary" css={css(styles.btn)} loading={state.loading} onClick={startGenerate}>Export Orders</Button>
        <Button type="primary" css={css(styles.btn)} loading={state.loading} onClick={timestampReport}>Time Stamp Report</Button>
      </div>}
    </div>
  )
}