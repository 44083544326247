/** @jsx jsx */
import { useState, useEffect } from 'react';
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import { 
  buildErrorMsgFromForm, 
  DateTool, 
  userHasRole,
} from '../../actions/utils';
import { withRouter } from 'react-router-dom';
import { Form, Input, Alert, Select, AutoComplete, DatePicker, Modal, message, Icon, Popover, Checkbox } from 'antd';
import SStyles from '../../_shared/styles';
import { Button } from '../../_shared/button';
import styles from './route.styles';
import { first, get, set, size, take } from 'lodash';
import ScreenTitle from '../../_shared/screen-title';
import Link from '../../_shared/link';
import moment from 'moment';
import colors from '../../_shared/colors';
import { EditableFormTable } from '../../_shared/editable-table';
import { _Route, _Row } from '../../models/route';
import { deleteRoute, updateRoute, createRoute } from '../../_shared/services/routes.service';
import { Calendar } from "react-modern-calendar-datepicker";
import Row from '../../_shared/row';

const EditCell = props => {
  const [state, setState] = useState({
    options: [
      { title: 'Days of the week', id: 'daysOfWeek' },
      { title: 'Strict Days', id: 'strictDays' },
    ],
    selected: 'daysOfWeek',
    days: [],
    strictDays: [],
  })

  useEffect(() => {
    const isStrict = DateTool.isDateArray(props.value);
    if (isStrict) {
      const strictDays = props.value.map(v => DateTool.dateToCal(v));
      setState(s => ({ ...s, selected: 'strictDays', strictDays }));
    } else {
      setState(s => ({ ...s, days: props.value, selected: 'daysOfWeek' }));
    }
  }, []);

  const changeValue = (val, key) => {
    props.setFieldsValue({
      [props.dataIndex]: val,
    })
  }

  useEffect(() => {
    if (state.selected === 'strictDays') {
      const arr = state.strictDays.map(sd => DateTool.calToDate(sd));
      console.log({
        arr,
      })
      changeValue(arr);
    }
  }, [state.strictDays]);

  return (
    <div>
      <Select value={state.selected} onChange={e => setState(s => ({ ...s, selected: e }))}>{state.options.map(o => <Select.Option key={o.id} value={o.id}>{o.title}</Select.Option>)}</Select>

      {state.selected === 'daysOfWeek' && <Checkbox.Group onChange={changeValue} defaultValue={props.value} options={props.options} />}

      {state.selected === 'strictDays' && <div>
        <Row>
          <Button title="all" size="small" onClick={() => setState(s => ({ ...s, strictDays: DateTool.generateArray().map(d => DateTool.dateToCal(d)) }))} />
        </Row>
        <Calendar shouldHighlightWeekends value={state.strictDays} onChange={d => setState(s => ({ ...s, strictDays: d  }))} />
      </div>}
    </div>
  )
}

export const ROUTECOLS = [
  {
    title: 'City',
    dataIndex: 'city',
    editable: true
  },
  {
    title: 'Zip Code',
    dataIndex: 'zipCode',
    editable: true
  },
  {
    title: 'Days of the week',
    dataIndex: 'dayOfTheWeek',
    editable: true,
    // inputType: 'checkbox',
    options: [
      { label: 'Mon', value: 'mon', val: 1 },
      { label: 'Tues', value: 'tue', val: 2 },
      { label: 'Wed', value: 'wed', val: 3 },
      { label: 'Thurs', value: 'thur', val: 4 },
      { label: 'Fri', value: 'fri', val: 5 },
      { label: 'Sat', value: 'sat', val: 6 },
      { label: 'Sun', value: 'sun', val: 0 },
    ],
    customInput: (_this) => <EditCell {..._this} />,
    render: (text, record) => {
      const tex = DateTool.isDateArray(text) ? DateTool.arrToDates(text, 'M/D/YY').join(', ') : (text || []).join(', ');
      const maxlen = 50;
      return (
        <span>
          {size(tex) > maxlen ? `${tex.substring(0, maxlen)}...` : tex}
        </span>
      )
    }
  },
  {
    title: 'Overnight',
    dataIndex: 'overnight',
    editable: true,
    inputType: 'checkbox',
    required: false,
    options: [
      { label: 'Overnight', value: 'true', val: 0 },
    ],
    render: (text, record) => {
      text = !size(text) ? ['false'] : text;
      return (
        <span>
          {(text || []).join(', ')}
        </span>
      )
    }
  },
  {
    title: 'Standard Price',
    dataIndex: 'price',
    editable: true,
    props: {
      addonBefore: "$"
    }
  },
]

class RouteComponent extends Component {
  state = {
    error: null,
    success: null,
    loading: false,
    isEdit: false,
    route: new _Route(),
    canLeave: true,
    toDisplay: [
      {
        title: 'Route Name',
        var: 'name',
        options: {
          validateTrigger: 'onBlur',
          rules: [
            {
              max: 100,
              message: 'Route Name is limited to 100 characters. ',
            },
            {
              required: true,
              message: 'Route Name is required. ',
            },
          ],
        },
      },
      {
        title: 'Route Start Time',
        var: 'data.startTime',
        type: 'time',
        options: {
          validateTrigger: 'onBlur',
          rules: [
            {
              required: true,
              message: 'Route Start Time is required. ',
            },
          ],
        },
      },
    ],
  };
  componentDidMount = async () => {
    const { state } = this.props.history.location;

    if (state && state.editRoute) {
      const route = new _Route(state.editRoute);
      route.data.rows = get(route, 'data.rows', []).map(r => new _Row(r));
      this.state.route = { ...route };
      this.state.isEdit = true;
      this.setState(this.state, () => {
        this.setFieldsOriginal(this.state.route);
      });
    }

    this.unblock = this.props.history.block((_nextLocation) => {
      if (!this.state.canLeave) {
        this.state.nextLocation = _nextLocation.pathname;
        this.goBack();
        return false;
      }
    });
  };
  componentWillUnmount = () => {
    this.unblock && this.unblock();
  };
  submit = async (e) => {
    e.preventDefault();
    if (this.state.loading) {
      return;
    }

    this.state.loading = true;
    this.state.error = null;
    this.setState(this.state);

    const { form } = this.props;
    const { route, isEdit } = this.state;

    form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        const error = buildErrorMsgFromForm(err);
        return this.setState({ error, loading: false });
      }

      try {
        const _route = { 
          ...route,
          ...values,
          data: {
            ...route.data,
            startTime: get(values, 'data.startTime'),
          }
        };

        if (isEdit) {
          await updateRoute(_route);
        } else {
          await createRoute(_route);
        }

        message.success(`Route ${isEdit ? 'edited' : 'created'} Successfully!`);

        //TODO: updatespreadsheet

        this.setState({ loading: false }, () => this.goBack(true));
      } catch (err) {
        console.error(err);
        message.error(`Route ${isEdit ? 'edited' : 'created'} failed!`);
        this.setState({
          loading: false,
          error: `Route creation failed: ${err.message}!`
        });
      }
    });
  };
  deleteRoute = () => {
    Modal.confirm({
      title: 'Delete Route?',
      content: 'Are you sure you want to delete this route?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        this.setState({ loading: true });
        await deleteRoute(this.state.route);
        this.goBack(true);
      }
    })
  }
  setFieldsOriginal = (order) => {
    const { toDisplay } = this.state;
    const {
      form: { setFieldsValue },
    } = this.props;

    toDisplay.map(item => {
      const val = get(order, item.var);
      if (val !== undefined) {
        setFieldsValue({ [item.var]: item.type === 'datepicker' ? val && moment(val) : val });
      }
    });
  };
  goBack = (force = false) => {
    const onOk = () => {
      const { nextLocation } = this.state;
      const { history } = this.props;
      this.state.canLeave = true;
      if (nextLocation) {
        history.push(nextLocation);
      } else {
        history.goBack();
      }
    };

    const onCancel = () => 0;

    const isEdit = this.state.org ? true : false;

    if (force || this.state.canLeave) {
      onOk();
    } else {
      Modal.confirm({
        title: 'Navigation Confirmation',
        content: `Are you sure you want to navigate away from ${isEdit ? 'Edit' : 'Create'} Route?  The ${isEdit ? '' : 'new'} data will not be ${isEdit ? 'saved' : 'created'} and any progress will be lost unless you select the "Submit" button below.`,
        onOk,
        onCancel,
        okText: 'Leave',
      });
    }
  }
  updateRows = (newData) => {
    set(this.state, 'route.data.rows', newData);
    this.setState({ ...this.state, canLeave: false });
  }
  addRow = () => {
    const rows = get(this.state, 'route.data.rows', []);
    set(this.state, 'route.data.rows', [
      ...rows,
      new _Row(),
    ]);
    this.setState({ ...this.state });
  }
  render() {
    const { sState, form: { getFieldDecorator } } = this.props;
    const { 
      error,
      success,
      loading,
      route,
      isEdit
    } = this.state;

    const toDisplay = this.state.toDisplay;

    return (
      <div className="Profile" css={css(SStyles.container, styles.container)}>

        {route && <Link css={css(`margin-right: auto;`)} onClick={() => this.goBack()}>{`< Back`}</Link>}
        
        <ScreenTitle title={(
          <div>
            <span>{isEdit ? "Edit Route" : "Create Route"}</span>{loading && <Icon css={css('margin-left: 5px;')} type="loading" />}
          </div>
        )} />

        {!!error && (
          <Alert
            message={error}
            type={'error'}
            closable
            css={css(styles.alertMargin)}
            onClose={() => this.setState({ error: null })}
          />
        )}

        {!!success && (
          <Alert
            message={success}
            type={'success'}
            closable
            css={css(styles.alertMargin)}
            onClose={() => this.setState({ success: null })}
          />
        )}

        <div css={css(SStyles.formContainer, styles.formContainer)}>
          <Form layout="vertical" onSubmit={this.submit}>
            {toDisplay.map((item, i) => {
              if(item.divider) {
                return <div css={css(styles.divider)}>{item.divider}</div>
              }

              const canDisplay = item.canDisplay ? item.canDisplay() : true;
              const label = item.info ? (
                <div>
                  <span>{item.title}</span>
                  <Popover overlayStyle={{ maxWidth: 250 }} content={item.info}>
                    <Icon css={css(`color: ${colors.highlight}; margin-left: 6px;`)} type="info-circle" theme="filled" />
                  </Popover>
                </div>
              ) : item.title;

              return !canDisplay ? null : (
                <Form.Item key={i} label={label} required={item.required} css={css(item.styles)}>
                  <div>
                    {getFieldDecorator(item.var, item.options)(
                      item.type === 'dropdown' && item.opts ? (
                        <Select placeholder={item.placeholder} onChange={() => this.forceUpdate()}>
                          {(typeof item.opts === 'function' ? item.opts() : item.opts).map((opt, i) => (
                            <Select.Option key={i} value={opt.value}>
                              {opt.title}
                            </Select.Option>
                          ))}
                        </Select>
                      ) : item.type === 'datepicker' ?
                      (
                        <DatePicker />
                      ) : item.type === 'autocomplete' ? (
                        <AutoComplete
                          placeholder={item.placeholder}
                          dataSource={item.opts}
                        />
                      ) : item.type === 'textarea' ? (
                        <Input.TextArea rows={4} placeholder={item.placeholder} />
                      ) : item.type === 'time' ? (
                        <Input type="time" placeholder={item.placeholder} />
                      ) : (
                        <Input placeholder={item.placeholder} />
                      )
                    )}
                    {item && item.subText && item.subText(item)}
                  </div>
                </Form.Item>
              );
            })}

            <EditableFormTable 
              data={get(route, 'data.rows', [])}
              columns={ROUTECOLS}
              updateData={this.updateRows}
              addRow={this.addRow}
              rowKey={'id'}
            />

            <Form.Item css={css`clear: both;`}>
              <div css={css(styles.buttonContainer)}>
                <Button
                  loading={loading}
                  onClick={this.submit}
                  type={"primary"}
                  css={css(styles.button)}
                >
                  {'Submit'}
                </Button>

                {userHasRole([0], sState) && isEdit && <Button
                  loading={loading}
                  onClick={this.deleteRoute}
                  outline={true}
                  css={css(styles.button)}
                >
                  {'Delete Route'}
                </Button>}
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export const Route = Form.create()(withRouter(RouteComponent));
