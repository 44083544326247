import colors from '../../_shared/colors';

export default {
  calSelectContainer: `
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;
    width: 100%;
    margin-bottom: 10px;
  `,
  table: `
    tr > td {
      padding: 0px;
      position: relative;
      height: 150px;
    }
    tr > td:last-child {
      padding: 8px;
    }
    tr > td > div {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      font-size: 11px;
      overflow: auto;
    }
  `,
  column: `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  bold: `
    font-weight: bold;
  `,
  buffer: `
    padding: 8px;
  `,
  select: `
    min-width: 150px;
  `,
  invalid: `
    background-color: ${colors.errorLight};
  `,
  isSix: `
    background-color: ${colors.warningLight};
  `,
  isTraining: `
    background-color: ${colors.lightBlue};
  `,
  isCancelled: `
    background-color: ${colors.maroon};
  `,
  printOnly: `
    position: absolute;
    right: 0px;
  `,
  yellowIcon: `
    color: ${colors.warning};
    margin-right: 5px;
  `
}

export const Globals = `
  @media print {
    .ant-tabs-nav-wrap {
      display: none;
    }

    tr > td:last-child,
    tr > th:last-child {
      display: none;
    }
  } 

  .dropdownContainer {
    width: 300px !important;
  }

  .dropdownContainer .alreadySelected {
    background-color: ${colors.warningLight};
  }

  .dropdownContainer .doubleBooked {
    background-color: ${colors.errorLight};
  }
`