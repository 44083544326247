/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import Content from './change-email.content';
import { getContent, cleanCopy, validateEmail } from '../../actions/utils';
import { InputLabel } from '../../_shared/input-label/input-label.component';
import { Button } from '../../_shared/button';
import { withRouter } from 'react-router-dom';
import styles from '../authentication.styles';
import { Alert, message } from 'antd';
import { cols } from '../sign-up';
import { get } from 'lodash';
import { sendValidationEmail, updateMyEmail } from '../../_shared/services/authentication.service';

const Cols = cleanCopy([
  ...cols,
]);
Cols[0].selected = false;
Cols[1].selected = true;

class ChangeEmailComponent extends Component {
  state = {
    code: '',
    newEmail: '',
    error: null,
    loading: false,
    sentNewEmail: false,
  };
  changeVal = (e, key) => {
    this.setState({ [key]: e.target.value });
  };
  submitNewEmail = async () => {
    const { newEmail } = this.state;
    if (this.state.loading) { return };

    try {
      this.setState({ loading: true });
      await sendValidationEmail(newEmail);
      message.success('An email was sent to the new Email with a verification pin.');
      this.setState({
        loading: false,
        sentNewEmail: true,
      })
    } catch (err) {
      this.setState({
        loading: false,
        error: `Error: ${err.message}`,
      })
    }
  }
  submitCode = async () => {
    if (this.state.loading) {
      return;
    }

    this.state.loading = true;
    this.setState(this.state);

    try {
      const { newEmail, code } = this.state;

      const newUser = await updateMyEmail(newEmail, code);

      debugger;
      this.props.setCurrentUser(newUser);
      this.props.history.goBack();
    } catch (err) {
      const error = get(err, 'response.data.error', err.message);
      this.setState({
        error,
        loading: false,
      });
    }
  };
  render() {
    const { code, error, loading, newEmail, sentNewEmail } = this.state;
    const content = getContent(Content);
    const btnEnabled = !sentNewEmail ? validateEmail(newEmail) : code;

    return (
      <div className="Verify" css={css(styles.container)}>

        <p css={css(styles.title)}>{content.title}</p>

        {!!error && (
          <Alert
            message={error}
            type={'error'}
            closable
            onClose={() => this.setState({ error: null })}
          />
        )}

        <div css={css(styles.inputContainer)}>
          <InputLabel
            label={'New Email'}
            value={newEmail}
            disabled={sentNewEmail}
            onChange={(e) => this.changeVal(e, 'newEmail')}
          />

          {sentNewEmail && <InputLabel
            label={content.label}
            value={code}
            onChange={e => this.changeVal(e, 'code')}
          />}

          <div css={css(styles.buttonContainer, `margin-top: 10px;`)}>
            <Button
              loading={loading}
              title={!sentNewEmail ? 'Send Pin' : 'Update Email'}
              onClick={!sentNewEmail ? this.submitNewEmail : this.submitCode}
              disabled={!btnEnabled}
            />
          </div>
        </div>
      </div>
    );
  }
}

export const ChangeEmail = withRouter(ChangeEmailComponent);
