/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import Content from './verify.content';
import { getContent, cleanCopy } from '../../actions/utils';
import { InputLabel } from '../../_shared/input-label/input-label.component';
import { Button } from '../../_shared/button';
import { withRouter } from 'react-router-dom';
import Logo from '../../_shared/logo';
import SharedStyles from '../../_shared/styles';
import styles from '../authentication.styles';
import { Alert } from 'antd';
import NumberedHeader from '../../_shared/numbered-header';
import { cols } from '../sign-up';
import { createUser } from '../../_shared/services/manage-users.service';
import { get } from 'lodash';

const Cols = cleanCopy([
  ...cols,
]);
Cols[0].selected = false;
Cols[1].selected = true;

class VerifyComponent extends Component {
  state = {
    code: '',
    error: null,
    loading: false,
  };
  changeVal = (e) => {
    this.setState({ code: e.target.value });
  };
  submit = async () => {
    if (this.state.loading) {
      return;
    }

    this.state.loading = true;
    this.setState(this.state);

    try {
      const { sState } = this.props;
      const { code } = this.state;
      const { location } = this.props;
      const fromLink = get(location, 'state.fromLink');

      const user = sState.user;

      const resp = await this.props.verify(user.email, code);
      
      await this.props.getUserData();

      if (resp.error) {
        this.setState({ loading: false, error: resp.error });
      } else {
        this.props.history.push('/dashboard/profile', {
          isCreate: fromLink ? false : true,
        });
      }
    } catch (err) {
      this.setState({
        error: err.message,
        loading: false,
      });
    }
  };
  render() {
    const { code, error, loading } = this.state;
    const btnDisabled = !code;
    const content = getContent(Content);

    return (
      <div className="Verify" css={css(styles.container)}>
        <div css={css(SharedStyles.logoWrap)}>
          <Logo />
        </div>

        <NumberedHeader
          cols={Cols}
        />

        <p css={css(styles.title)}>{content.title}</p>

        {!!error && (
          <Alert
            message={error}
            type={'error'}
            closable
            onClose={() => this.setState({ error: null })}
          />
        )}

        <div css={css(styles.inputContainer)}>
          <InputLabel
            label={content.label}
            value={code}
            onChange={this.changeVal}
          />

          <div css={css(styles.buttonContainer, `margin-top: 10px;`)}>
            <Button
              loading={loading}
              title={content.button}
              onClick={this.submit}
              disabled={btnDisabled}
            />
          </div>
        </div>
      </div>
    );
  }
}

export const Verify = withRouter(VerifyComponent);
