import colors from '../_shared/colors';

export default {
  container: `
    display: flex;
    flex: 1;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  `,
  title: `
    color: ${colors.highlight};
    font-size: 12px;
    font-weight: bold;
  `,
  subTitle: `
    font-size: 12px;
  `,
  error: `
    color: ${colors.error};
    font-size: 14px;
    text-align: center;
    max-width: 300px;
  `,
  inputContainer: `
    margin-top: 30px;
    width: 80%;
    max-width: 300px;
  `,
  fgPassword: `
    position: relative;
    top: -60px;
    color: ${colors.labelColor};
    font-size: 10px;
    float: right;
    cursor: pointer;
  `,
  buttonContainer: `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0;
  `,
  button: `
    margin: 5px;
  `,
  linkContainer: `
    position: relative;
    bottom: -50px;
  `
};
