import { get } from 'lodash';
import { removeEmpty } from '../../actions/utils';
import { instance } from './api.service';

export const getRoute = async (id) => {
  const resp = await instance.get(`/route/${id}`, { data: {} });
  return get(resp, 'data.route', null);
};

export const getRoutes = async (where) => {
  let resp = {};
  if (where) {
    resp = await instance.post(`/routes`, {
      where,
    });
  } else {
    resp = await instance.get(`/routes`, { data: {} });
  }
  return get(resp, 'data.routes', []);
};

export const createRoute = async (route) => {
  const resp = await instance.post('/routes/create', {
    route,
  });
  return get(resp, 'data.route', null);
};

export const updateRoute = async (route) => {
  const resp = await instance.post('/routes/update', {
    route: removeEmpty(route),
  });
  return get(resp, 'data.route', null);
};

export const deleteRoute = async (route) => {
  const resp = await instance.post('/routes/delete', {
    route: removeEmpty(route),
  });
  return get(resp, 'data.route', null);
};

export const findRoutesFromZip = async (zip) => {
  const resp = await instance.post('/routes/find', {
    zip,
  })
  return get(resp, 'data.routes', []);
}