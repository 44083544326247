/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Component } from 'react'
import SStyles from '../styles';
import colors from '../colors';

export default class ScreenTitle extends Component {
	render() {
		const { title } = this.props;
		return (
      <div css={css(SStyles.row, `
        font-size: 20px;
        color: ${colors.highlight};
        font-weight: bold;
        align-items: center;
        justify-content: center;
        margin: 10px;
        margin-bottom: 20px;
      `)}>
        {title}
			</div>
		)
	}
}
