/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import colors from '../colors';
import { Button } from 'antd';

export class _Button extends Component {
  render() {
    const { 
      title, 
      disabled, 
      outline, 
      children, 
      dark,
      type,
      hoverText,
    } = this.props;

    return (
      <Button
        {...this.props}
        title={hoverText || title}
        css={css(type === 'danger' ? '' : 
          `
            background-color: ${
              disabled
                ? colors.disabledColor
                : outline
                ? colors.white
                : dark
                ? colors.buttonDark
                : colors.highlight
            };
            border: 1px solid ${
            disabled
              ? colors.disabledColor
              : dark
                ? colors.buttonDark
                : colors.highlight
            };
          `,
          `
          color: ${
            disabled ? colors.white : outline ? colors.highlight : colors.white
          };
          font-size: 14px;
          cursor: pointer;
          border-radius: 0px;
        `,
          this.props.css
        )}
      >
        {title || children}
      </Button>
    );
  }
}
