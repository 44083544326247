/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import { HeaderStyles } from './table-list.styles';

export class TableListHeader extends Component {
  render() {
    const { children, hide, className } = this.props;
    return (
      <div className={className} css={css(HeaderStyles.container, hide && HeaderStyles.hide)}>
        {children}
      </div>
    );
  }
}
