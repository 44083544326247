export default {
  container: `
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    
    .linkContainer {
      text-align: left;
      margin-bottom: 20px;

      .title {
        font-size: 20px;
        font-weight: bold;
      }
    }
  `,
}