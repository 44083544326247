/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import ScreenComponent from '../hooks/useScreen';
import { get } from 'lodash';

import logoimg from '../../assets/img/logo_one.png';
import logoslim from '../../assets/img/logo_one_slim.png';
import logoorg from '../../assets/img/logo.png';
import { getConfig } from '../../actions/utils';

const LOGOS = {
  new: { 
    small: {
      src: logoslim, 
      dims: { 
        width: 60,
        mult: 0.49,
      }
    },
    default: {
      src: logoimg,
    },
    css: `
      filter: invert(61%) sepia(88%) saturate(333%) hue-rotate(91deg) brightness(100%) contrast(84%);
    `,
    imgcss: `
      filter: brightness(0%) saturate(100%);
    `
  },
  orig: { 
    small: {
      src: logoorg,
      dims: { 
        width: 90,
        mult: 0.30,
      },
    }, 
    default: {
      src: logoorg,
      dims: {
        mult: 0.30,
      }
    },
    css: ``,
    imgcss: ``
  }
}

const MULT = 0.625;
const DimsDefault = {
  small: { width: 50, mult: MULT },
  default: { width: 500, mult: MULT },
};

export class Logo extends Component {
  render() {
    const { small, style, onClick } = this.props;

    const logo = (() => {
      let src;
      let dims;
      let css;
      let imgcss;

      const lconfig = getConfig('logoType', 'orig');
      const size = small ? 'small' : 'default';

      const obj = get(LOGOS, lconfig, LOGOS.orig);

      src = this.props.logo || get(obj, [size, 'src']);
      dims = { ...get(DimsDefault, size, {}), ...get(obj, `${size}.dims`, {}) };
      css = get(obj, 'css', '');
      imgcss = get(obj, 'imgcss', '');

      if (this.props.width) {
        dims.width = this.props.width;
      } 

      if (this.props.mult) {
        dims.mult = this.props.mult;
      }
      
      if (this.props.screen.isMobile) {
        dims = { ...dims, width: get(this, 'props.screen.windowSize.innerWidth', dims.width) - 20 }
      }

      return { src, dims, css, imgcss }
    })();

    return (
      <div
        className={'Logo'}
        css={css(`
          width: ${logo.dims.width}px;
          height: ${logo.dims.width * logo.dims.mult}px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #cecece;
          cursor: ${onClick ? 'pointer' : 'default'};
          position: relative;
          ${logo.css}
        `)}
        style={style}
        onClick={onClick}
      >
        <img
          alt={'GPS'}
          css={css`
            width: 100%;
            height: 100%;
            ${logo.imgcss}
          `}
          src={logo.src}
        />
      </div>
    );
  }
}

export default ScreenComponent(Logo);