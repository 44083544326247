/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { Form as AForm, Select, Input, Checkbox, DatePicker } from 'antd';
import { get, isObject } from 'lodash';
import { AutoComplete } from '../autocomplete';

export const Form = AForm.create()(forwardRef((props, ref) => {
  const { toDisplay, onSubmit, form } = props;
  const { getFieldDecorator } = form;

  const onChange = (e) => {
    props.onChange && props.onChange(e, form)
  }

  useEffect(() => {
    props.registerForm && props.registerForm(form);
  }, [])

  useEffect(() => {
    const { setFieldsValue } = form;

    if (props.defaults) {
      const validKeys = props.toDisplay.filter(td => get(td, 'canDisplay', () => true)()).map(td => td.var);

      const newObj = {};
      const loopObj = (obj, key = '') => {
        for (let k in obj) {
          const _k = `${key ? key + '.' : ''}${k}`;
          const val = get(obj, k);
          if (val && isObject(val) && !get(val, 'toDate')) {
            loopObj(val, _k);
          } else {
            if (validKeys.includes(_k)) {
              newObj[_k] = val;
            }
          }
        }
      }

      loopObj(props.defaults);

      setFieldsValue(newObj);
    }
  }, [props.defaults]);

  useImperativeHandle(ref, () => ({ form }), [props])

  const layout = get(props, 'layout', 'vertical');

  return (
    <AForm style={props.style || {}} layout={layout} onSubmit={e => onSubmit(e, props.form)} onChange={onChange}>
      {/* tslint:disable-next-line: no-any*/}
      {toDisplay.map((item, i) => {
        const canDisplay = item.canDisplay ? item.canDisplay() : true;
        const itemOptions =
          typeof item.options === 'function'
            ? item.options()
            : item.options;
        return !canDisplay ? null : (
          <AForm.Item key={i} label={item.title} css={css(item.style)} id={item.var}>
            {getFieldDecorator(item.var, itemOptions)(
              item.type === 'dropdown' && item.opts ? (
                <Select
                  {...(item.props || {})}
                  placeholder={item.placeholder}
                  onChange={e => setTimeout(() => onChange(e), 100)}
                  getPopupContainer={() => document.getElementById(item.var)}
                >
                  {/* tslint:disable-next-line: no-any*/}
                  {item.opts().map((opt, i) => (
                    <Select.Option key={i} value={opt.value}>
                      {opt.title || opt.label}
                    </Select.Option>
                  ))}
                </Select>
              ) : item.type === 'checkbox' ? (
                <Checkbox {...(item.props || {})} />
              ) : item.type === 'date' ? <DatePicker /> : item.type === 'AutoComplete' ? (
                <AutoComplete {...(item.props || {})} onSelect={(val, opt) => {
                  setTimeout(() => {
                    form.setFieldsValue({ [item.var]: val });
                    onChange(val);
                  }, 100);
                }} />
              ) : item.type === 'password' ? <Input.Password /> : (
                <Input {...(item.props || {})} />
              )
            )}
            {item.subContent && item.subContent(item)}
          </AForm.Item>
        );
      })}
    </AForm>
  )
}))