/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Component } from 'react'
import SStyles from '../styles'

export default class Row extends Component {
  render() {
    return (
      <div css={css(SStyles.row)}
        {...this.props}
      />
    )
  }
}
