/** @jsx jsx */
import { css, Global, jsx } from '@emotion/core';
import { Component } from 'react';
import { Table, Select, Icon } from 'antd';
import SharedStyles from '../styles';
import { Globals, TableStyles } from './table-list.styles';
import { DndProvider, DragSource, DropTarget } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import Row from '../row';

let dragingIndex = -1;

class BodyRow extends Component {
  render() {
    const { isOver, connectDragSource, connectDropTarget, connectDragPreview, moveRow, ...restProps } = this.props;
    const style = { ...restProps.style };

    let { className } = restProps;
    if (isOver) {
      if (restProps.index > dragingIndex) {
        className += ' drop-over-downward';
      }
      if (restProps.index < dragingIndex) {
        className += ' drop-over-upward';
      }
    }

    const [fchild, ...restchildren] = this.props.children;

    return connectDropTarget(connectDragPreview(
      <tr {...restProps} className={className} style={style}>
        <td>
          {connectDragSource(<div className="dragIcon"><Icon type="vertical-align-middle" /></div>)}
          {fchild}
        </td>
        {restchildren}
      </tr>
    ))
  }
}

const rowSource = {
  beginDrag(props) {
    dragingIndex = props.index;
    return {
      index: props.index,
    };
  },
};

const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  },
};

const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource('row', rowSource, connect => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
  }))(BodyRow),
);

if (typeof Array.prototype.move === "undefined") {
  Array.prototype.move = function(from, to, on = 1) {
    this.splice(to, 0, ...this.splice(from, on))
  }
}

export class TableList extends Component {
  state = {
    selectedRowKeys: this.props.selectedRowKeys || [],
    selectedRows: [],
    loading: false,
    height: 500,
    pageSize: this.props.pageSize || 25,
    currentPage: 1,
  };
  calcTableSize = () => {
    const container = document.getElementById('ContentContainer');

    if (container) {
      const cHeight = container.offsetHeight;
      const subtract = 200;
      const height = cHeight - subtract;
      this.setState({
        height,
      });
    }
  };
  componentWillUnmount = () => {
    window.removeEventListener('resize', this.calcTableSize);
  };
  componentDidMount = () => {
    setTimeout(() => {
      this.calcTableSize();
    }, 500);
    window.addEventListener('resize', this.calcTableSize);
  };
  selectedRowsChange = () => {
    const { onSelectedRowsChange } = this.props;
    if (onSelectedRowsChange) {
      onSelectedRowsChange(this.state.selectedRowKeys, this.state.selectedRows);
    }
  };
  tableChange = e => {
    const { current } = e;
    this.setState({ currentPage: current });
  }
  rowSelection = () => {
    const { selectedRowKeys } = this.state;

    return {
      selectedRowKeys,
      onChange: (keys, selectedRows) => {
        this.setState({ selectedRowKeys: keys, selectedRows }, this.selectedRowsChange);
      },
    };
  };
  rowClick = (record, rowIndex) => {
    const { rowClick } = this.props;
    if (rowClick) {
      rowClick(record, rowIndex);
    }
  };
  changeSelect = (v) => {
    this.setState({ pageSize: v });
    setTimeout(() => this.forceUpdate(), 20);
  };
  renderPageSelect = () => {
    const { pageSize } = this.state;

    const pageOptions = [
      { label: '25', value: 25 },
      { label: '50', value: 50 },
      { label: '100', value: 100 },
    ];

    const pagination = document.getElementsByClassName(
      'ant-pagination'
    )[0];
    let width = 0;
    if (pagination) {
      width = pagination.offsetWidth;
    }

    return !this.props.showPagination ? null : (
      <div
        className="no-print"
        css={css(
          SharedStyles.row,
          `width: 140px; 
          position: relative;
          top: -40px;
          float: right;
          right: ${width}px;
        `
        )}
      >
        <label css={css(`margin-right: 10px;`)}>Page Size</label>
        <Select size="small" onChange={this.changeSelect} value={pageSize}>
          {pageOptions.map(option => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      </div>
    );
  };
  componentDidUpdate = (pProps) => {
    if (pProps.data.length !== this.props.data.length) {
      this.changeSelect(this.state.pageSize);
      this.setState({ selectedRowKeys: [] }, this.selectedRowsChange);
    }
    if ((pProps.selectedRowKeys || []).join('') !== (this.props.selectedRowKeys || []).join('')) {
      this.setState({ selectedRowKeys: this.props.selectedRowKeys }, this.selectedRowsChange);
    }
  };
  clearSelected = () => {
    this.setState({ selectedRowKeys: [] }, this.onSelectedRowsChange);
  }
  renderRefresh = () => {
    return (
      <div css={css(TableStyles.refresh)}>
        <Icon spin={this.props.loading} type="sync" onClick={this.props.onRefresh} theme="outlined" />
      </div>
    )
  }
  moveRow = (dragIndex, hoverIndex) => {
    const { data, onReorder } = this.props;

    const d = [...data];
    d.move(dragIndex, hoverIndex);
    onReorder && onReorder(d);
    this.setState({ selectedRowKeys: [], selectedRows: [] }, this.onSelectedRowsChange);
  };
  render() {
    const { height, pageSize, selectedRowKeys } = this.state;
    const {
      columns,
      data,
      canSelectRows,
      loading,
      implementScroll,
      showPagination,
      scrollHeight,
      rowClick,
      onRefresh,
      draggable,
    } = this.props;

    const main = (
      <div style={{ position: 'relative' }}>
        <Global styles={Globals} />
        <div className='print-only'>
          <Row className="printheader">
            {columns.map(c => <div style={{ width: c.width || `${100 / columns.length}%` }}>{c.title}</div>)}
          </Row>
        </div>
        <Table
          className={draggable ? 'drag-table' : ''}
          selectedRowKeys={selectedRowKeys}
          rowKey={this.props.rowKey}
          onRow={(record, rowIndex) => {
            return {
              index: rowIndex,
              moveRow: this.moveRow,
              onClick: () => this.rowClick(record, rowIndex),
            };
          }}
          onChange={this.tableChange}
          pagination={showPagination && { pageSize }}
          size={'small'}
          scroll={implementScroll ? { y: scrollHeight || height } : undefined}
          {...this.props}
          rowSelection={canSelectRows ? this.rowSelection() : undefined}
          columns={columns}
          dataSource={data}
          loading={loading}
          components={draggable ? { body: { row: DragableBodyRow } } : undefined}
          rowClassName={(item, index) => `${rowClick ? 'canRowClick' : ''} tbl-list-row-${index % 2}`}
        />
        {onRefresh && this.renderRefresh()}
        {data.length > 25 && this.renderPageSelect()}
      </div>
    )

    if (draggable) {
      return (
        <DndProvider backend={HTML5Backend}>
          {main}
        </DndProvider>
      )
    }

    return main;
  }
}

TableList.defaultProps = {
  canSelectRows: true,
  implementScroll: true,
  showPagination: true,
  rowKey: r => r.id,
};
