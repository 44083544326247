/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import Content from './forgot-password.content';
import { getContent, buildErrorMsgFromForm } from '../../actions/utils';
import { Button } from '../../_shared/button';
import { withRouter } from 'react-router-dom';
import Logo from '../../_shared/logo';
import { Form, Input, Alert, message } from 'antd';
import styles from '../authentication.styles';
import SharedStyles from '../../_shared/styles';
import Link from '../../_shared/link';

export class ForgotPasswordComponent extends Component {
  state = {
    loading: false,
    error: null,
  };
  goToLogin = () => {
    this.props.history.push('/login');
  };
  submit = async (e) => {
    e.preventDefault();
    if (this.state.loading) {
      return;
    }

    this.state.loading = true;
    this.state.error = null;
    this.setState(this.state);

    this.props.form.validateFieldsAndScroll(async (err, values) => {
      try {
        if (err) {
          const error = buildErrorMsgFromForm(err);
          return this.setState({
            error,
            loading: false,
          });
        }

        const email = values.email.toLowerCase();

        const resp = await this.props.forgotPassword(email);
        if (resp.emailSent) {
          message.success('An email has been sent with your Password');
          this.props.history.push('/login');
        }
      } catch (err) {
        if (err.code && err.code === 'UserNotFoundException') {
          this.setState({
            error: 'That email does not exist. Please try again',
            loading: false,
          });
        } else {
          this.setState({
            error: err.message,
            loading: false,
          });
        }
      }
    });
  };
  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { error, loading } = this.state;
    const btnDisabled = !getFieldValue('email');
    const content = getContent(Content);

    return (
      <div className="ForgotPassword" css={css(styles.container)}>
        <div css={css(SharedStyles.logoWrap)}>
          <Logo />
        </div>

        <p css={css(styles.title)}>{content.title}</p>
        <p css={css(styles.subTitle)}>{content.subTitle}</p>

        {!!error && (
          <Alert
            message={error}
            type={'error'}
            closable
            onClose={() => this.setState({ error: null })}
          />
        )}

        <div css={css(styles.inputContainer)}>
          <Form layout="vertical" onSubmit={this.submit}>
            <Form.Item label={content.emailLabel}>
              {getFieldDecorator('email', {
                validateTrigger: 'onBlur',
                rules: [
                  {
                    type: 'email',
                    message: 'The input is not a valid E-mail. ',
                  },
                  {
                    required: true,
                    message: 'The input is not a valid E-mail. ',
                  },
                  {
                    max: 60,
                    message: 'Email is limited to 60 characters. ',
                  },
                ],
              })(<Input />)}
            </Form.Item>

            <Form.Item>
              <div css={css(styles.buttonContainer + `flex-direction: column; margin-top: 20px;`)}>
                <Button
                  loading={loading}
                  title={content.button}
                  onClick={this.submit}
                  disabled={btnDisabled}
                />

                <Link
                  css={css`
                    margin-top: 40px;
                  `}
                  onClick={this.goToLogin}
                >
                  {content.link}
                </Link>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export const ForgotPassword = Form.create()(
  withRouter(ForgotPasswordComponent)
);
