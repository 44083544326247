import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Icon } from 'antd';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',

  background: isDragging ? 'rgba(0,0,0,0.1)' : undefined,
  borderRadius: 5,
  paddingBottom: 5,
  paddingTop: 5,

  ...draggableStyle
});

const getListStyle = ({ isDraggingOver, isMobile, ...props }) => ({
  display: !isMobile && !props.verticalOnly ? 'flex' : 'block',
  flexDirection: !isMobile && !props.verticalOnly ? 'row' : 'column',
  flexWrap: 'wrap',
});

export const SortableList = props => {
  const [items, setItems] = useState(props.items);

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const _items = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    props.onSort && props.onSort(_items);
  }

  useEffect(() => {
    setItems(props.items);
  }, [props.items]);

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle({ ...props, isDraggingOver: snapshot.isDraggingOver })}
          >
            {items.map((item, index) => (
              <Draggable key={item.key} draggableId={item.key} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <div {...provided.dragHandleProps}>
                      <Icon type="column-height" />
                    </div>
                    <div style={{ marginLeft: 15, width: '100%' }}>{typeof item.content === 'function' ? item.content() : item.content}</div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default SortableList;