/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { useState, useEffect, useRef } from 'react';
import { message, DatePicker, Button, Input, Checkbox } from 'antd';
import styles from './reconciliation.styles';
import moment from 'moment';
import LNSearch from '../../_shared/ln-search';
import { debounce, get, set } from 'lodash';
import { getReconciliation, updateOrder } from '../../_shared/services/orders.service';
import { TableList } from '../../_shared/table-list';
import { getLicAddress, size, stopProp, toMoney } from '../../actions/utils';
import { useSelector } from 'react-redux';
import { AdminOrderButton } from '../orders/orders.component';

export const EditRow = (t, row, key, props) => {
  const hasRecon = get(row, 'data.recon');
  if (hasRecon && !props.forceRender) { return t }

  return (
    <Input disabled={props.disabled} onClick={stopProp} value={t} title={t} loading={props.saving} onChange={e => props.onChangeText(e.target.value, row,  key)} />
  )
}

export const Reconciliation = props => {
  const user = useSelector(state => get(state, 'user.email'))
  const [state, setState] = useState({
    loading: false,
    saving: false,
    selectedDate: [moment().subtract(1, 'week'), moment()],
    vendor: undefined,// { 'License #': '404R-00176' },
    data: [],
    selectedRows: [],
    accountsOnly: true,
  })
  const LNRef = useRef();

  const loadData = () => {
    if (size(state.selectedDate) && state.vendor) {
      retrieveData();
    } else {
      setState(s => ({ ...s, data: [] }));
    }
  }

  useEffect(() => {
    loadData();
  }, [state.selectedDate, state.vendor]);

  const retrieveData = async () => {
    try {
      setState(s => ({ ...s, loading: true }));
      const data = (await getReconciliation({ 
        selectedDate: state.selectedDate,
        vendor: state.vendor,
      })).sort((_a, _b) => {
        const a = !!get(_a, 'data.recon');
        const b = !!get(_b, 'data.recon');
        const adate = moment(get(_a, 'data.order.deliveryDate')).startOf('day');
        const bdate = moment(get(_b, 'data.order.deliveryDate')).startOf('day');

        const aisPickup = get(_a, 'isPickup');
        const bisPickup = get(_a, 'isPickup');
        const ad = get(_a, `data.${aisPickup ? 'pickupCompany' : 'destCompany'}.DBA`)
        const bd = get(_b, `data.${bisPickup ? 'pickupCompany' : 'destCompany'}.DBA`)
        
        if (a == b) {
          if (adate == bdate) {
            return ad.localeCompare(bd, 'en', { numeric: true })
          }
          return adate - bdate;
        }
        return a - b;
      })

      setState(s => ({ ...s, data, selectedRows: [] }))
    } catch (err) {
      message.error(err.message);
    } finally {
      setState(s => ({ ...s, loading: false }));
    }
  }

  const selectDate = date => {
    setState(s => ({ ...s, selectedDate: date }))
  }

  const saveOrder = debounce(async (order) => {
    try {
      setState(s => ({ ...s, saving: true }));
      await updateOrder(order);
    } catch (err) {
      message.error(`${err.message}`);
    } finally {
      setState(s => ({ ...s, saving: false }));
    }
  }, 800, { leading: false });

  const onChangeText = (val, row, key) => {
    const orderIndex = state.data.findIndex(o => o.id === row.id);
    set(state, `data.${orderIndex}.${key}`, val);
    setState(s => ({ ...s, data: [...state.data] }))
    saveOrder(get(state, `data.${orderIndex}`));
  };

  const handleVendorSelect = v => {
    const vendor = get(LNRef, 'current.state.licenseNumbersAll', []).find(l => get(l, 'License #') === v);
    setState(s => ({ ...s, selectedRows: [], vendor }))
  }

  const reorderRows = rows => {
    setState(s => ({ ...s, data: rows }));
  }

  const onSelectedRowsChange = (keys) => {
    setState(s => ({ ...s, selectedRows: keys }));
  }

  const onPrint = async () => {
    var cssPagedMedia = (function () {
      var style = document.createElement('style');
      document.head.appendChild(style);
      return function (rule) {
        style.innerHTML = rule;
      };
    }());
  
    cssPagedMedia.size = function (size) {
      cssPagedMedia('@page {size: ' + size + '}');
    };
    
    cssPagedMedia.size('portrait');
    window.print();
    cssPagedMedia.size('landscape');

    await Promise.all(printRows.map(async order => {
      if (order.id) {
        set(order, 'data.recon', { date: moment().toISOString(), user })
        await updateOrder(order);
      }
    }))
    retrieveData();
  }

  let total = 0;
  const printRows = state.selectedRows.sort().map(key => {
    const row = get(state, `data`, []).find(d => d.id == key);
    const amt = parseFloat((get(row, 'data.billing.amount', 0) || 0).toString().replace('$', ''));
    total += amt;
    return row;
  });

  if (size(printRows)) {
    const toAdd = {};
    set(toAdd, 'data.billing.amount', toMoney(total));
    printRows.push(toAdd);
  }

  const canShowPrint = !!size(printRows);

  return (
    <div css={css(styles.container)}>
      <div className="no-print" css={css(styles.calContainer)}>
        <div css={css(styles.input, styles.row)}>
          <LNSearch ref={LNRef} onSelect={handleVendorSelect} accountsOnly={state.accountsOnly} />
          <Checkbox checked={state.accountsOnly} style={{ width: 130, marginLeft: 10, }} onChange={() => setState(s => ({ ...s, accountsOnly: !state.accountsOnly }))}>{'GPS Only'}</Checkbox>
        </div>
        
        <DatePicker.RangePicker
          css={css(styles.input)}
          loading={state.loading}
          value={state.selectedDate}
          onChange={selectDate}
        />

        <AdminOrderButton onClose={loadData} />

        {canShowPrint && 
          <Button 
            onClick={onPrint} 
            css={css(styles.printBtn)} 
            className="no-print"
            type="primary" 
            icon="printer"
          >
            Print / Export
          </Button>}
      </div>

      <div className="print-only">
        <div css={css(styles.headerRow)}>{`GPS Vendor Reconciliation Form`}</div>
        <div css={css(styles.headerSub)}>{`Vendor Name: ${get(state, 'vendor.DBA') || get(state, 'vendor.Licensee', 'none')} Date: ${moment().format('L')}`}</div>
        
        <TableList
          data={printRows}
          columns={printCols()}
          canSelectRows={false}
          showPagination={false}
          implementScroll={false}
        />

        <div css={css(styles.printLowerText)}>
          <br /><br />
          <div><b>{`Courier: `}</b>{`By signing this agreement, I understand that I am responsible for the aforementioned payments and paperwork, and agree to deliver them to the appropriate vendor. I have verified this information with a GPS manager.`}</div>
          <br />
          <div css={css(styles.row)}>
            <div css={css(styles.sig)}>{`Courier Signature:______________________________`}</div>
            <div css={css(styles.sig)}>{`Manager Signature:______________________________`}</div>
          </div>
          
          <br /><br />
          <div><b>{`Vendor: `}</b>{`By signing this agreement, I agree that the aforementioned payments and paperwork have been delivered to the correct vendor facility, and I have verified that the information provided is correct and accurate. All payment discrepancies need to be reported to GPS management within 48 hours. GPS is not liable for any payment discrepancies reported after 48 hours.`}</div>
          <br />
          <div css={css(styles.row)}>
            <div css={css(styles.sig)}>{`Vendor Rep (Print):______________________________`}</div>
            <div css={css(styles.sig)}>{`Vendor Rep (Sign):______________________________`}</div>
          </div>
        </div>
      </div>

      <div className="no-print">
        <TableList
          draggable
          loading={state.loading}
          pageSize={250}
          data={state.data}
          onReorder={reorderRows}
          columns={getCols({ onChangeText, ...state })}
          onSelectedRowsChange={onSelectedRowsChange}
          onRefresh={loadData}
          selectedRowKeys={state.selectedRows}
        />
      </div>
    </div>
  )
}

const getCols = props => {
  return [
    {
      title: 'Order ID',
      dataIndex: 'orderId',
      width: '8%',
      render: (text, row) => {
        const hasRecon = get(row, 'data.recon');
        return <span style={{ fontWeight: hasRecon ? 'bold' : 'normal' }}>{text}</span>
      }
    },
    {
      title: 'Delivery Date',
      dataIndex: 'data.order.deliveryDate',
      width: '7%',
      render: (t) => {
        return moment(t).format('L');
      }
    },
    {
      title: 'Notes',
      dataIndex: 'data.order.notes',
      width: '4%',
      render: (t, row) => {
        return <div css={css(styles.ellipsis)} title={t}>{t}</div>
      }
    },
    {
      title: 'Billing Notes',
      dataIndex: 'data.order.billingNotes',
      width: '7%',
      render: (t, row) => {
        return EditRow(t, row, 'data.order.billingNotes', props);
      }
    },
    {
      title: 'Box Count',
      dataIndex: 'data.order.weight',
      width: '8%',
      render: (t, row) => {
        return EditRow(t, row, 'data.order.weight', props);
      }
    },
    {
      title: 'Vendor',
      dataIndex: 'data.pickupCompany.Licensee',
      width: '13%',
      render: (t, row) => {
        const name = 'pickupCompany';
        const compName = get(row, `data.${name}.DBA`, get(row, `data.${name}.Licensee`));
        const addr = getLicAddress(get(row, 'data.order.pickupLN'));
        const text = `${compName || ''}${compName ? ' - ' : ''}${addr}`;
        return <span>{text}</span>
      }
    },
    {
      title: 'Destination',
      dataIndex: 'data.destCompany.Street Address',
      width: '13%',
      render: (t, row) => {
        const isPickup = get(row, 'isPickup');
        const name = isPickup ? 'pickupCompany' : 'destCompany';
        const compName = get(row, `data.${name}.DBA`, get(row, `data.${name}.Licensee`));
        const compAddress = get(row, `data.${name}.Street Address`);
        const zip = get(row, `data.${name}.Zip`);
        const text = isPickup ? 'GPS' : `${compName || ''}${compName ? ' - ' : ''}${compAddress}${zip ? ', ' + zip : ''}`;
        return <span>{text}</span>
      }
    },
    {
      title: 'City',
      dataIndex: 'data.destCompany.City',
      width: '5%',
      render: (t, row) => {
        const isPickup = get(row, 'isPickup');
        const text = isPickup ? get(row, 'data.pickupCompany.City') : get(row, 'data.destCompany.City');
        return <span>{text}</span>
      }
    },
    {
      title: 'Invoice',
      dataIndex: 'data.billing.invoice',
      width: '7%',
      render: (t, row) => {
        return EditRow(t, row, 'data.billing.invoice', props);
      }
    },
    {
      title: 'Amount',
      dataIndex: 'data.billing.amount',
      width: '7%',
      render: (t, row) => {
        return EditRow(t, row, 'data.billing.amount', props);
      }
    },
    {
      title: 'Method',
      dataIndex: 'data.billing.method',
      width: '7%',
      render: (t, row) => {
        return EditRow(t, row, 'data.billing.method', props);
      }
    },
    {
      title: 'Manifest',
      dataIndex: 'data.billing.manifest',
      width: '7%',
      render: (t, row) => {
        return EditRow(t, row, 'data.billing.manifest', props);
      }
    },
    {
      title: 'GPS Invoice',
      dataIndex: 'data.billing.gpsinvoice',
      width: '7%',
      render: (t, row) => {
        return EditRow(t, row, 'data.billing.gpsinvoice', { ...props, forceRender: true });
      }
    },
  ]
}

const printCols = props => {
  return [
    {
      title: 'Order ID',
      dataIndex: 'orderId',
      width: '15%',
    },
    {
      title: 'Destination',
      dataIndex: 'data.destCompany.Street Address',
      width: '20%',
      render: (t, row) => {
        const isPickup = get(row, 'isPickup');
        const name = isPickup ? 'pickupCompany' : 'destCompany';
        const compName = get(row, `data.${name}.DBA`, get(row, `data.${name}.Licensee`));
        const compAddress = get(row, `data.${name}.Street Address`);
        const zip = get(row, `data.${name}.Zip`);
        const text = isPickup ? 'GPS' : `${compName || ''}${compName ? ' - ' : ''}${compAddress}${zip ? ', ' + zip : ''}`;
        if (!zip) { return null }
        return text;
      }
    },
    {
      title: 'City',
      dataIndex: 'data.destCompany.City',
      width: '10%',
      render: (t, row) => {
        const isPickup = get(row, 'isPickup');
        const text = isPickup ? get(row, 'data.pickupCompany.City') : get(row, 'data.destCompany.City');
        return text
      }
    },
    {
      title: 'Date',
      dataIndex: 'deliveryDate',
      width: '10%',
      render: (t, row) => {
        if (!t) { return '' }
        return moment(t).format('L');
      }
    },
    {
      title: 'Invoice',
      dataIndex: 'data.billing.invoice',
      width: '15%',
      render: (t) => {
        return t || '';
      }
    },
    {
      title: 'Amount',
      dataIndex: 'data.billing.amount',
      width: '15%',
      render: (t) => {
        return t || '';
      }
    },
    {
      title: 'Method',
      dataIndex: 'data.billing.method',
      width: '15%',
      render: (t) => {
        return t || '';
      }
    },
  ]
}