import { get } from 'lodash';
import { instance } from './api.service';

export const getDrivers = async (where) => {
  let resp = {};
  if (where) {
    resp = await instance.post(`/drivers`, {
      where,
    });
  } else {
    resp = await instance.get(`/drivers`, { data: {} });
  }
  return get(resp, 'data.data', []);
};

export const createDriver = async (driver) => {
  const resp = await instance.post('/drivers/create', {
    driver,
  });
  return get(resp, 'data.data', null);
};

export const updateDriver = async (driver) => {
  const resp = await instance.post('/drivers/update', {
    driver,
  });
  return get(resp, 'data.data', null);
};

export const deleteDriver = async (driver) => {
  const resp = await instance.post('/drivers/delete', {
    driver
  });
  return get(resp, 'data.driver', null);
};