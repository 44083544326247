export default {
  container: `
    align-items: center;
    justify-content: center;
  `,
  formContainer: `
    width: 500px;
    max-width: 500px;
  `,
  alertMargin: `
    margin-bottom: 40px;
  `,
  buttonContainer: `
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
  `,
  button: `
    margin: 5px;
  `,
  deleteButton: ``
}