import colors from '../_shared/colors';
import { get } from 'lodash';

export class _Location {
  constructor(props = {}) {
    for (let key in props) {
      this[key] = props[key];
    }
  }
  address = ''
  address2 = ''
  city = ''
  state = ''
  zip = ''
}

export const OrderStatus = {
  orderReceived: 0,
  orderApproved: 1,
  inTransit: 2,
  delivered: 3,
  exception: 4,
  fullrejection: 5,
  partialrejection: 6,
  orderPickedUp: 7,
  orderCancelled: 8,
}

export const CHECKINICON = 'vertical-align-bottom';

export const deliveryStatus = [
  { title: 'Order Received', value: OrderStatus.orderReceived, color: colors.success, icon: 'clock-circle' },
  { title: 'Order Approved', value: OrderStatus.orderApproved, color: colors.success, icon: 'check' },
  { title: 'Order Picked Up', value: OrderStatus.orderPickedUp, color: colors.success, icon: 'check-square' },
  { title: 'In Transit', value: OrderStatus.inTransit, color: colors.warning, icon: 'car' },
  { title: 'Delivered', value: OrderStatus.delivered, color: colors.success, icon: 'check-circle' },
  { 
    title: 'Exception', 
    value: OrderStatus.exception, 
    color: colors.warning, 
    icon: 'exclamation-circle',
  },
  { title: 'Full Rejection', value: OrderStatus.fullrejection, color: colors.error, icon: 'minus-square' },
  { title: 'Partial Rejection', value: OrderStatus.partialrejection, color: colors.error, icon: 'minus-circle' },
  { title: 'Order Cancelled', value: OrderStatus.orderCancelled, color: colors.error, icon: 'close-circle' },
]

export const getStatusIcon = (order, opts = {}) => {
  opts = {
    isAdmin: true,
    ...opts,
  }

  const status = get(order, 'data.order.status', 0);
  const dstat = deliveryStatus.find(d => d.value == status);

  //show approved icons for non admin users instead of exception
  if (dstat.value == OrderStatus.exception && !opts.isAdmin) {
    return deliveryStatus.find(d => d.value == OrderStatus.orderApproved);
  }

  return dstat;
}

export const Rejections = [
  { title: "Incorrect Quantity on the Manifest", value: 1, },
  { title: "Wrong Product on the Manifest", value: 2, },
  { title: "Missing Product on the Manifest", value: 3, },
  { title: "Missing Package Tags", value: 4, },
  { title: "Poor Product Quality", value: 5, },
  { title: "Terms Issue", value: 6, },
  { title: "Receiver didn't purchase product", value: 7, },
  { title: "Other", value: 8, },
]

export class _Order {
  constructor(props = {}) {
    for (let key in props) {
      this[key] = props[key];
    }
  }
  order = {}
  destCompany = {}
  pickupCompany = {}
  createdAt = new Date().toISOString();
  createdBy = undefined;
  updatedAt = new Date().toISOString();
  updatedBy = undefined;
  dateSubmitted = undefined;
  deliveryDate = undefined;
}

// id: guid,
// data: order,
// userId: currentUser.id,
// deliveryDate: order.deliveryDate,
// orderId: `${pickupLN}-${uid()}`,
// created //auto
// lastupdated //auto