import { get } from 'lodash';
import { instance } from './api.service';

export const getMapState = async (id) => {
  const resp = await instance.get(`/maps/${id}`, { data: {} });
  return get(resp, 'data.data', {});
};

export const updateMapState = async (state) => {
  const resp = await instance.post(`/maps/update`, state);
  return get(resp, 'data.data', []);
};