import colors from '../../_shared/colors';

export default {
  container: `
    align-items: center;
    justify-content: center;
  `,
  formContainer: `
    width: 600px;
    max-width: 600px;
  `,
  alertMargin: `
    margin-bottom: 40px;
  `,
  buttonContainer: `
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
  `,
  subText: `
    background-color: ${colors.disabledLight};
    font-size: 12px;
    text-align: left;
    padding: 2px 5px;
  `,
  button: `
    margin: 5px;
  `,
  uploadContainer: `
    width: 80%;
  `,
  divider: `
    font-weight: bold;
    color: ${colors.highlight};
  `,
  dlTemplate: `
    position: absolute;
    left: 10px;
    top: 10px;
  `,
  row: `
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  column: `
    display: flex;
    flex-direction: column;
    align-items: center;
  `
}