/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import colors from '../colors';

export class TableTitleHeader extends Component {
  render() {
    const { children } = this.props;
    return (
      <span
        css={css(`
          color: ${colors.highlight};
          font-size: 16px;
          font-weight: bold;
        `)}
      >
        {children}
      </span>
    );
  }
}
