/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import { 
  buildErrorMsgFromForm, 
  cleanCopy, 
  validPhoneNumber,
  getBase64,
  userIsVerfied,
  userHasRole,
  copyToClipboard,
  getConfig,
  getAllLnsFromUser,
} from '../../actions/utils';
import { withRouter } from 'react-router-dom';
import Logo from '../../_shared/logo';
import { Form, Input, Alert, Select, Upload, Icon, message, Modal, Tag } from 'antd';
import SStyles from '../../_shared/styles';
import { Button } from '../../_shared/button';
import NumberedHeader from '../../_shared/numbered-header';
import { cols } from '../../authentication/sign-up';
import styles from './profile.styles';
import { flatten, get, merge, set, uniqBy } from 'lodash';
import { updateUser, createUser, getUser, deleteUser } from '../../_shared/services/manage-users.service';
import { MultiSelectSearch } from '../../_shared/multi-select-search';
import ScreenTitle from '../../_shared/screen-title';
import Link from '../../_shared/link';
import Row from '../../_shared/row';
import { ROLES, _Account } from '../../models/account';
import { sendEmail } from '../../_shared/services/email.service';
import CONFIG from '../../config';
import LNSearch from '../../_shared/ln-search';
import colors from '../../_shared/colors';
import ScreenComponent from '../../_shared/hooks/useScreen';
import { Sigpad } from '../../_shared/sigpad';
import { getUsersMatchingLn } from '../../_shared/services/authentication.service';
import { EditableFormTable } from '../../_shared/editable-table';

const config = CONFIG();

const Cols = cleanCopy(cols);
Cols[0].selected = false;
Cols[1].selected = false;
Cols[2].selected = true;

const formWidth = 500;
const padding = 10;
const uploadwidth = 112;

const VERIFIYOPTIONS = [
  { title: 'Verified', value: true },
  { title: 'Suspended', value: 'suspended' },
  { title: 'Not Verified', value: false },
]

const USER_COLS = [
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Verified',
    dataIndex: 'data.verified',
    editable: true,
    inputType: 'select',
    options: VERIFIYOPTIONS,
    render: (text) => {
      return `${text}`
    }
  }
]

class ProfileComponent extends Component {
  state = {
    error: null,
    success: null,
    loading: true,
    user: this.props.sState.user,
    imageUrl: '',
    isCreate: false,
    isNewUser: false,
    editAccount: false,
    toDisplay: [
      {
        title: 'Email',
        var: 'email',
        canDisplay: () => this.state.isNewUser,
        options: {
          validateTrigger: 'onBlur',
          rules: [
            {
              type: 'email',
              message: 'The input is not a valid E-mail. ',
            },
            {
              required: true,
              message: 'The input is not a valid E-mail. ',
            },
          ],
        },
      },
      {
        title: 'Company Logo',
        var: 'company.logo',
        type: 'imageupload',
        styles: `
          float: left;
          width: ${uploadwidth + padding}px;
        `
      },
      {
        title: 'Company Name (LLC)',
        var: 'company.Licensee',
        styles: `
          float: left;
          width: ${this.props.screen.isMobile ? 'auto' : formWidth - uploadwidth - padding}px;
          padding-left: ${padding};
          clear: right;
        `,
        options: {
          rules: [
            {
              required: true,
              message: 'Company Name is required. ',
            },
            {
              max: 100,
              message: 'Company Name is limited to 100 characters. ',
            },
          ],
        },
      },
      {
        title: 'Company Name (DBA)',
        var: 'company.Licensee DBA',
        styles: `
          float: left;
          width: ${this.props.screen.isMobile ? 'auto' : formWidth - uploadwidth - padding}px;
          padding-left: ${padding};
          clear: right;
        `,
        options: {
          rules: [
            {
              required: true,
              message: 'Company Name DBA is required. ',
            },
            {
              max: 100,
              message: 'Company Name is limited to 100 characters. ',
            },
          ],
        },
      },
      {
        title: 'Contact Name',
        var: 'name',
        styles: `
          clear: both;
        `,
        options: {
          rules: [
            {
              required: true,
              message: 'Contact Name is required. ',
            },
            {
              max: 100,
              message: 'Contact Name is limited to 100 characters. ',
            },
          ],
        },
      },
      {
        title: 'Phone',
        var: 'company.phone',
        styles: `
          clear: both;
        `,
        options: {
          validateTrigger: 'onBlur',
          rules: [
            {
              required: true,
              message: 'Phone is required. ',
            },
            {
              validator: (rule, value, cb) => {
                const passes = !value || validPhoneNumber(value);
                cb(passes ? undefined : rule.message);
              },
              message: 'Phone needs to be a valid phone number. ',
            },
            {
              max: 16,
              message: 'Phone is limited to 16 characters. ',
            },
          ],
        },
      },
      {
        title: 'Billing Contact Name',
        var: 'company.billingContactName',
        canDisplay: () => !userHasRole(2, this.props.sState),
        styles: `
          clear: both;
        `,
        options: {
          rules: [
            {
              required: true,
              message: 'Billing Contact Name is required. ',
            },
          ],
        },
      },
      {
        title: 'Billing Contact Email',
        var: 'company.billingContactEmail',
        canDisplay: () => !userHasRole(2, this.props.sState),
        styles: `
          clear: both;
        `,
        options: {
          rules: [
            {
              required: true,
              message: 'Billing Contact Email is required. ',
            },
            {
              type: 'email',
              message: 'The input is not a valid E-mail. ',
            },
          ],
        },
      },
      {
        title: 'Billing Address',
        var: 'company.billingAddress',
        canDisplay: () => !userHasRole(2, this.props.sState),
        styles: `
          clear: both;
        `,
        options: {
          rules: [
            {
              required: true,
              message: 'Billing Address is required. ',
            },
          ],
        },
      },
      {
        title: 'License Number',
        var: 'company[License #]',
        canDisplay: () => !userHasRole(2, this.props.sState),
        styles: `
          clear: both;
        `,
        props: {
          disabled: (() => {
            const isAdmin = userHasRole(0, this.props.sState);
            return !get(this, 'state.isNewUser', false) && !isAdmin;
          })(),
        },
        options: {
          rules: [
            {
              required: true,
              message: 'License Number is required. ',
            },
          ],
        },
      },
      {
        title: 'Additional License Numbers',
        var: 'company.licenseNumbers',
        canDisplay: () => !userHasRole(2, this.props.sState),
        type: 'lnsearch',
        styles: `
          clear: both;
        `,
      },
      {
        title: 'Street Address',
        var: 'company[Street Address]',
        canDisplay: () => !userHasRole(2, this.props.sState),
        styles: `
          clear: both;
        `,
        options: {
          rules: [
            {
              required: true,
              message: 'Street Address is required. ',
            },
          ],
        },
      },
      {
        title: 'Signature',
        var: 'signature',
        type: 'sigpad',
        canDisplay: () => getConfig('sigs.show') && userHasRole([0, 2], this.props.sState) && !this.state.isNewUser && !this.state.isCreate,
        styles: `
          clear: both;
        `,
        props: {
          onSave: result => {
            const { form } = this.props;
            form.setFieldsValue({ signature: { ...result, date: new Date().toISOString() } });
          }
        }
      },
      {
        title: 'Do you want to receive order notifications?',
        var: 'alerts.email',
        type: 'dropdown',
        styles: `
          clear: both;
        `,
        opts: [
          { title: 'Yes', value: true },
          { title: 'No', value: false },
        ],
        placeholder: 'Yes',
        options: {
          initialValue: true,
        }
      },
      {
        title: 'Public Orders Link',
        var: 'publicorderslink',
        styles: `
          clear: both;
        `,
        val: () => this.externalLink(),
        canDisplay: () => userHasRole([0], this.props.sState),
        options: {
        },
        props: {
          disabled: true,
          addonAfter: <Icon type="copy" onClick={() => {
            copyToClipboard(this.externalLink())
            message.success('Link copied successfully!')
          }} />
        }
      },
      {
        title: 'Verified',
        var: 'verified',
        type: 'dropdown',
        canDisplay: () => userHasRole([0], this.props.sState),
        styles: `
          clear: both;
        `,
        opts: VERIFIYOPTIONS
      },
      {
        title: 'Same Day Pickup Allowed',
        var: 'sameDayPickup',
        type: 'dropdown',
        canDisplay: () => userHasRole([0], this.props.sState),
        styles: `
          clear: both;
        `,
        opts: [
          { title: 'Allowed', value: true },
          { title: 'Not Allowed', value: false },
        ]
      },
      {
        title: 'Role',
        var: 'role',
        type: 'dropdown',
        canDisplay: () => userHasRole([0], this.props.sState),
        styles: `
          clear: both;
        `,
        opts: Object.keys(ROLES).filter(k => ROLES[k] !== 'not defined').map(k => ({ title: ROLES[k], value: parseInt(k) })),
      },
    ]
  };
  componentDidUpdate(pProps) {
    const compKey = 'sState.user.company.licenseNumbers';
    if (get(pProps, compKey) !== get(this.props, compKey)) {
      this.setState({ user: get(this, 'props.sState.user', {}) }, this.setFieldsOriginal);
    }
  }
  canAddLNS = () => {
    return userHasRole([0,1,2,3], this.props.sState);
  }
  handleSelectChange = (e, item, dir = 'add') => {
    if (!e) { return };

    const currentTags = get(this, `state.user.${item.var}`, []);

    const comp = get(this, item.var);
    if (comp && comp.selectOption) {
      comp.selectOption('');
    }

    const { setFieldsValue } = this.props.form;

    if (dir === 'add') {
      set(this, `state.user.${item.var}`, [
        ...currentTags,
        e,
      ]);
    } else if (dir === 'remove') {
      set(this, `state.user.${item.var}`, currentTags.filter(t => t !== e));
    }

    setFieldsValue({
      [item.var]: get(this, `state.user.${item.var}`, []),
    });
  }
  externalLink = () => {
    const { user } = this.state;
    return `${config.appUrl}/dashboard/publicorders?userid=${user.id}`;
  }
  componentDidMount = async () => {
    const location = this.props.state ? { state: this.props.state } : get(this, 'props.history.location');
    if (location && location.state) {
      const isNewUser = get(location, 'state.newUser', false);
      const locationUser = get(location, 'state.editAccount.data');

      this.setState({ 
        isCreate: location.state.isCreate || false,
        fullUser: get(location, 'state.editAccount'),
        editAccount: location.state.editAccount ? true : false,
        user: isNewUser ? new _Account() : locationUser || this.state.user, 
        isNewUser,
      }, this.setFieldsOriginal);
    } else {
      this.setFieldsOriginal();
    }
  };
  submit = async (e) => {
    e.preventDefault();
    if (this.state.loading) {
      return;
    }

    this.state.loading = true;
    this.state.error = null;
    this.setState(this.state);

    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        const error = buildErrorMsgFromForm(err);
        return this.setState({ error, loading: false });
      }

      const { isNewUser } = this.state;

      try {
        const { user, imageUrl } = this.state;
  
        const usr = merge({}, user, values);
  
        usr.company.logo = imageUrl;

        const isVerified = userIsVerfied(user);

        if (isNewUser) {
          try {
            const userExists = await getUser(usr.email);
            if (userExists && userExists.id) {
              return this.setState({
                loading: false,
                error: 'This email already exists. Please create account with a different email.',
              })
            }

            await createUser({
              ...usr,
              verified: true,
            });
            await sendEmail({
              to: usr.email,
              subject: 'GPS Service Invitation',
              html: `
                You have been invited to Green Parcel Service's online portal. Use the link below to sign up.

                <a href="${config.appUrl}/signMeUp?email=${encodeURIComponent(usr.email)}">Sign Up</a>
              `
            })
            message.success('Account created successfully!');
            this.back(true);
          } catch (err) {
            this.setState({
              loading: false,
              error: `Account creation failed: ${err.message}!`
            })
          }
        } else {
          await updateUser(usr);
          await this.props.getUserData();

          if (usr.verified && !user.verified) {
            //user has become verified by admin, so send them email
            sendEmail({
              to: user.email,
              subject: 'GPS Account Verified',
              html: `Congratulations! Your account (${usr.email}) has been verified. <br /><br />
                Login <a href="${config.appUrl}">Here</a>
                `
            })
          }

          if (!isVerified) {
            const hasSubmittedPreviously = get(user, 'hasSentEmail');

            if (!hasSubmittedPreviously) {
              usr.hasSentEmail = true;
              await updateUser(usr);
              await sendEmail({
                to: getConfig('signupEmails', config.adminEmails),
                subject: 'New User Application',
                html: `
                User Email: ${usr.email} has submitted a new Application.
                <br /><br />
                <a href="${config.appUrl}/validateUser?email=${usr.email}">Verify New Account</a>
                `
              })
            }

            return this.setState({
              success: 'Your profile has been created successfully. Your account is being reviewed and you will receive an email (check your spam folder) once you are Validated. Thank you.',
              loading: false,
              user: usr,
            })
          }
    
          this.setState({
            loading: false,
            success: 'Profile updated successfully!',
            user: usr,
          });

        }
        if (this.props.isModal) { this.back(true) };
        // updateSpreadsheet();
      } catch (err) {
        this.setState({
          loading: false,
          error: `Profile ${isNewUser ? 'creation' : 'update'} failed: ${err.message}!`
        })
      }
    });
  };
  beforeUpload = (file) => {
    return new Promise((resolve, reject) => {
      getBase64(file, (imageUrl) => {
        this.setState({ imageUrl });
        reject();
      })
    })
  }
  loadAttachedAccounts = async () => {
    const userlns = getAllLnsFromUser(this.state.user);
    
    const allusers = uniqBy(flatten(await Promise.all(userlns.all.map(async ln => {
      return await getUsersMatchingLn(ln);
    }))), 'id');

    this.setState({ attachedUsers: allusers })
  }
  updateData = async (data, row) => {
    try {
      this.setState({ attachedUsers: data })
      await updateUser({ ...row, ...get(row, 'data', {}), data: undefined });
    } catch (err) {
      message.error('Failed to update user, please try again.')
    }
  }
  setAllSuspended = async (val) => {
    try {
      this.setState({ loading: true });
      await Promise.all(this.state.attachedUsers.map(async u => {
        set(u, 'data.verified', val);
        await updateUser({ ...u, ...get(u, 'data', {}), data: undefined });
      }))
    } catch (err) {
      message.error(err.message);
    } finally {
      this.setState({ loading: false });
    }
  }
  setFieldsOriginal = () => {
    const { toDisplay, user } = this.state;
    const {
      form: { setFieldsValue },
    } = this.props;
    
    toDisplay.map(item => {
      const val = item.val ? item.val(user) : get(user, item.var);
      setFieldsValue({ [item.var]: val });
      if(item.var === 'company.logo' && val) {
        this.setState({ imageUrl: val });
      }
    });

    this.setState({ loading: false }, () => {
      if (userHasRole(0, this.props.sState)) {
        this.loadAttachedAccounts();
      }
    })
  };
  navigate = (cb, shouldUpdate) => {
    const { isModal, onClose } = this.props;
    if (isModal && onClose) {
      onClose(shouldUpdate);
    } else {
      cb();
    }
  }
  back = (shouldUpdate) => {
    this.navigate(this.props.history.goBack, shouldUpdate);
  }
  deleteUser = async () => {
    Modal.confirm({
      title: 'Delete User?',
      content: 'Are you sure you want to delete this user? This cannot be undone.',
      onCancel: () => 0,
      onOk: async () => {
        try {
          this.setState({ loading: true });
          await deleteUser(this.state.fullUser);
          message.success('User deleted');
          this.back(true);
        } catch (err) {
          const message = get(err, 'response.data.error', err.message);
          this.setState({
            error: `Error deleting user: ${message}!`
          });
        } finally {
          this.setState({ loading: false });
        }
      }
    });
  }
  render() {
    const { form: { getFieldDecorator }, sState, isModal, screen } = this.props;
    const { 
      error,
      success,
      loading,
      toDisplay,
      imageUrl,
      user,
      isCreate,
      editAccount,
      isNewUser,
      attachedUsers,
    } = this.state;

    const uploadButton = (
      <div>
        <Icon type={loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    return (
      <div className="Profile" css={css(SStyles.container, styles.container)}>
        {isCreate && !isNewUser && <div css={css(SStyles.logoWrap)}>
          <Logo />
        </div>}

        {isCreate && !isNewUser && <NumberedHeader cols={Cols} />}

        {editAccount && !isModal && <Row css={css(`align-items: flex-start;`)}>
          <Link onClick={() => this.back()}>{'< Back'}</Link>  
        </Row>}

        {!isCreate && <ScreenTitle title={editAccount ? "Edit Account" : "My Profile"} />}
        
        {isNewUser && <ScreenTitle title={"Create New Account"} />}

        {!!error && (
          <Alert
            message={error}
            type={'error'}
            closable
            css={css(styles.alertMargin)}
            onClose={() => this.setState({ error: null })}
          />
        )}

        {!!success && (
          <Alert
            message={success}
            type={'success'}
            closable
            css={css(styles.alertMargin)}
            onClose={() => this.setState({ success: null })}
          />
        )}

        <div css={css(SStyles.formContainer, styles.formContainer, screen.isMobile && `width: 100%;`)}>
          <Form layout="vertical" onSubmit={this.submit}>
            {toDisplay.map((item, i) => {
            const canDisplay = item.canDisplay ? item.canDisplay() : true;
            return !canDisplay ? null : (
              <Form.Item key={i} label={item.title} css={css(item.styles)}>
                <div>
                  {getFieldDecorator(item.var, item.options)(
                    item.type === 'dropdown' && item.opts ? (
                      <Select {...(item.props || {})} placeholder={item.placeholder} >
                        {item.opts.map((opt, i) => (
                          <Select.Option key={i} value={opt.value}>
                            {opt.title}
                          </Select.Option>
                        ))}
                      </Select>
                    ) : item.type === 'imageupload' ? (
                        <Upload
                          accept={'image/x-png,image/gif,image/jpeg'}
                          name="avatar"
                          listType="picture-card"
                          className="avatar-uploader"
                          showUploadList={false}
                          beforeUpload={this.beforeUpload}
                        >
                          {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                        </Upload>
                    ) : item.type === 'sigpad' ? <Sigpad {...(item.props || {})} {...(loading ? {} : get(user, `${item.var}`, {}))} /> : item.type === 'lnsearch' ? (
                      <div>
                        <LNSearch
                          ref={comp => set(this, item.var, comp)}
                          onSelect={(e) => this.handleSelectChange(e, item)}
                          canAdd={this.canAddLNS()}
                        />
                        <div css={css`
                          margin-top: 5px;
                          align-items: flex-start;
                          display: flex;
                        `}>
                          {(Array.isArray(get(user, item.var, [])) ? get(user, item.var, []) : []).map(ln => {
                            return (
                              <Tag
                                css={css`
                                  margin-bottom: 5px;
                                `}
                                color={colors.tagDark}
                                key={ln}
                                closable
                                onClose={() => this.handleSelectChange(ln, item, 'remove')}
                              >
                                {ln}
                              </Tag>
                            )
                          })}
                        </div>
                      </div>
                    ) : item.type === 'multiselectsearch' ? (
                      <MultiSelectSearch
                        onTagsChange={tags => {
                          set(user, item.var, tags);
                        }}
                        dataSource={item.data()}
                        selected={get(user, item.var, [])}
                      />
                    ) : (
                      <Input {...(item.props || {})} />
                    )
                  )}
                </div>
              </Form.Item>
            );
          })}

          <Form.Item>
            <div css={css(styles.buttonContainer)}>
              <Button
                loading={loading}
                onClick={this.submit}
                type={"primary"}
                css={css(styles.button)}
              >
                {'Save'}
              </Button>

              {userHasRole(0, sState) && editAccount && <Button
                loading={loading}
                onClick={this.deleteUser}
                type={"danger"}
                css={css(styles.deleteButton)}
              >
                {'Delete'}
              </Button>}
            </div>
          </Form.Item>
        </Form>
        </div>
        
        {!!attachedUsers && <div>
          <ScreenTitle title={"Attached Accounts"} />
          <Row style={{ justifyContent: 'flex-end' }}>
            <Button title="Set all suspended" loading={this.state.loading} onClick={() => this.setAllSuspended('suspended')} />
            <Button style={{ marginLeft: 5 }} title="Set all verified" loading={this.state.loading} onClick={() => this.setAllSuspended(true)} />
          </Row>
          <EditableFormTable
            data={attachedUsers}
            updateData={this.updateData}
            columns={USER_COLS}
            rowKey="id"
            canAdd={false}
          />
        </div>}
      </div>
    );
  }
}

export const Profile = ScreenComponent(Form.create()(withRouter(ProfileComponent)));
