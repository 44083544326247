import { guid } from '../actions/utils';

export class _Route {
  constructor(props = {}) {
    for (let key in props) {
      this[key] = props[key];
    }
  }
  id = guid();
  name = '';
  data = {
    rows: [],
  };
  created = new Date();
  lastUpdated = new Date();
}

export class _Row {
  constructor(props = {}) {
    for (let key in props) {
      this[key] = props[key];
    }
  }
  id = guid();
  city = '';
  zipCode = '';
  dayOfTheWeek = [];
  biWeekly = false;
  overnight = [];
}