import colors from '../_shared/colors';

export default {
  thinkWrap: `
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.overlay};
    z-index: 99999;
  `,
  icon: `
    font-size: 50px;
  `,
};
