import * as colors from "../colors";

export const Globals = `
  tr.drop-over-downward > td {
    border-bottom: 2px dashed ${colors.highlight};
  }

  tr.drop-over-upward > td {
    border-top: 2px dashed ${colors.highlight};
  }

  tr {
    position: relative;
  }

  .dragIcon {
    cursor: move;
    padding: 2px 2px;
  }
`;

export const HeaderStyles = {
  container: `
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1 1 auto;
  `,
  hide: `
    position: absolute;
    top: -999999999999999px;
  `,
};

export const TableStyles = {
  refresh: `
    position: absolute;
    right: 0;
    top: 8px;

    i {
      color: ${colors.highlight};
      font-weight: bold;
    }
  `
}