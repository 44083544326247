/** @jsx jsx */
import { useState, useEffect } from 'react';
import { css, jsx } from '@emotion/core';
import { EditableFormTable } from '../../../_shared/editable-table';
import { ExtraEditRender, ExtraRender } from '../../../_shared/editable-table/templates';
import { size, get, orderBy} from 'lodash';
import { guid } from '../../../actions/utils';
import { getVehicles, updateVehicle, deleteVehicle } from '../../../_shared/services/vehicles.service';
import styles from './styles';

const V_EXTRAS = [
  { title: 'Cash Van', id: 'cashVan', type: 'boolean' },
  { title: 'Pickup Van', id: 'pickupVan', type: 'boolean' },
  { title: 'Maint', id: 'maint', type: 'boolean' },
  { title: 'Notes', id: 'notes', type: 'textarea' },
  { title: 'Vehicle Radius', id: 'vRadius', type: 'number' },
]

export const COLS = [
  {
    title: 'Vehicle',
    dataIndex: 'name',
    editable: true
  },
  {
    title: 'License',
    dataIndex: 'lic',
    editable: true
  },
  {
    title: 'Vin',
    dataIndex: 'vin',
    editable: true
  },
  {
    title: 'Misc',
    dataIndex: 'data',
    editable: true,
    customInput: (_this) => <ExtraEditRender cell={_this} extras={V_EXTRAS} />,
    render: (text, record) => <ExtraRender extras={V_EXTRAS} record={record} />
  },
]

export const Vehicles = props => {
  const [rows, setRows] = useState([]);

  const getData = async () => {
    const data = await getVehicles();
    setRows(orderBy(data, 'name'));
  }

  useEffect(() => { getData(); }, []);

  const updateData = async d => {
    const newrows = d.map(r => r.id);
    const missingrows = rows.filter(r => newrows.indexOf(r.id) === -1);
    
    setRows([ ...d ]);
    await Promise.all(d.map(async dd => {
      await updateVehicle(dd)
    }))

    if (!!size(missingrows)) {
      await Promise.all(missingrows.map(async dd => {
        await deleteVehicle(dd)
      }))
    }
  }

  const addRow = () => {
    setRows([
      ...rows,
      {
        id: guid(),
        name: '',
        lic: '',
        vin: '',
        data: {}
      }
    ])
  }

  const rowClass = (record) => {
    if (get(record, 'data.maint') === true) {
      return 'row-warning';
    }
  }

  return (
    <div css={css(styles.table)}>
      <EditableFormTable 
        data={rows}
        columns={COLS}
        updateData={updateData}
        rowClassName={rowClass}
        addRow={addRow}
        rowKey={'id'}
        addButton={'Add Vehicle'}
      />
    </div>
  )
}