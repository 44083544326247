/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { useState, useEffect } from 'react';
import useDebounce from '../../_shared/hooks/useDebounce';
import { Input, message, Select } from 'antd';
import { getRawLicense, uppercaseFirst } from '../../actions/utils';
import { get, flatten, size } from 'lodash';
import styles from './styles';
import { findRoutesFromZip } from '../../_shared/services/routes.service';
import { TableList } from '../../_shared/table-list';

export const ROUTECOLS = [
  {
    title: 'Route',
    dataIndex: 'parent.name',
    width: '19%',
  },
  {
    title: 'City',
    dataIndex: 'city',
    width: '24%',
  },
  {
    title: 'Zip Code',
    dataIndex: 'zipCode',
    width: '19%',
  },
  {
    title: 'Days of the week',
    dataIndex: 'dayOfTheWeek',
    width: '24%',
    render: (text, record) => {
      return (
        <span>
          {(text || []).map(d => uppercaseFirst(d)).join(', ')}
        </span>
      )
    }
  },
  {
    title: 'Price Per Parcel',
    dataIndex: 'price',
    width: '10%',
    render: text => {
      return (
        <span>{!!text ? `$${text}` : ''}</span>
      )
    }
  },
]

export const ScheduleSearch = props => {
  const [state, setState] = useState({
    search: '',
    loading: false,
    data: [],
    max: 250,
    dayOpts: [
      { label: 'Mon', value: 'mon', val: 1 },
      { label: 'Tues', value: 'tue', val: 2 },
      { label: 'Wed', value: 'wed', val: 3 },
      { label: 'Thurs', value: 'thur', val: 4 },
      { label: 'Fri', value: 'fri', val: 5 },
      { label: 'Sat', value: 'sat', val: 6 },
      { label: 'Sun', value: 'sun', val: 0 },
    ],
    days: ['mon', 'tue', 'wed', 'thur', 'fri'],
  })

  const searchval = useDebounce(state.search, 750);

  const onChange = e => {
    setState({ ...state, search: e.target.value })
  };

  const Search = async () => {
    try {
      setState(s => ({ ...s, loading: true }));

      const data = [];

      const obj = get(window, 'KEYMAPS.routes', {});

      const sval = (searchval || '').toLowerCase();
      const isLN = sval.indexOf('-') > -1;

      if (isLN) {
        const lic = getRawLicense(sval.toUpperCase());
        if (lic && lic.Zip) {
          const routes = await findRoutesFromZip(lic.Zip);
          return setState(s => ({ ...s, data: flatten(routes.map(r => get(r, 'data.rows', []).map(rr => ({ ...rr, parent: r })) ) ) }));
        }
      }

      const searches = ['city', 'zipCode'];

      if (searchval) {
        for (let k in obj) {
          const item = obj[k];
          const rows = get(item, 'data.rows', []);
  
          rows.map(r => {
            const { dayOfTheWeek } = r;
  
            const has = searches.find(f => { //matched either city or zip
              const val = get(r, f, '').toLowerCase();
              return val.indexOf(sval) > -1;
            })
  
            if (has && state.days.find(d => dayOfTheWeek.indexOf(d) > -1)) {
              data.push({ ...r, parent: item });
            }
          })
  
          if (size(data) >= state.max) {
            break;
          }
        }
      }
      
      setState(s => ({ ...s, data }));
    } catch (err) {
      message.error(err.message);
    } finally {
      setState(s => ({ ...s, loading: false }));
    }
  }

  useEffect(() => {
    Search();
  }, [searchval, state.days]);

  return (
    <div>
      <div css={css(styles.row)}>
        <Input.Search
          value={state.search}
          onChange={onChange}
          loading={state.loading}
          placeholder='Search by City, Zipcode, or License Number'
          autoFocus
          allowClear
          style={{ marginRight: 10 }}
        />
        <Select
          mode="multiple"
          placeholder="Days of the week"
          value={state.days}
          onChange={e => setState(s => ({ ...s, days: e }))}
          style={{ width: '100%' }}
        >
          {state.dayOpts.map(d => <Select.Option value={d.value}>{d.label}</Select.Option>)}
        </Select>
      </div>

      <TableList
        columns={ROUTECOLS}
        data={state.data}
        loading={state.loading}
        canSelectRows={false}
        pageSize={50}
      />
    </div>
  )
}