/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState, useEffect } from 'react';
import useDebounce from '../../_shared/hooks/useDebounce';
import { Input, message } from 'antd';
import { getOrder } from '../../_shared/services/orders.service';
import { Order } from '../order';
import { wait } from '../../actions/utils';
import { last, get } from 'lodash';

const INPUTBARCODE = 'INPUTBARCODE';

export const stripOrderUrl = str => {
  return last((str || '').split('/'));
}

export const Checkin = props => {
  const [state, setState] = useState({
    id: '',
    loading: false,
    order: null,
  })

  const isCheckout = get(window, 'location.pathname', '').indexOf('/checkout') > -1;

  const id = useDebounce(state.id, 750);

  const retrieveOrder = async (orderid) => {
    try {
      setState(s => ({ ...s, loading: true }));
      const order = await getOrder(orderid);
      setState(s => ({ ...s, order }));
    } catch (err) {
      setState(s => ({ ...s, order: null }));
      message.error('Order not found');
    } finally {
      setState(s => ({ ...s, loading: false }))
      highlightInput();
    }
  }

  const highlightInput = () => {
    document.getElementById(INPUTBARCODE).select();
  }

  const onChange = e => {
    setState({ ...state, id: stripOrderUrl(e.target.value) })
  };

  const onClose = async shouldUpdate => {
    if (shouldUpdate) {
      message.success(`Order checked ${isCheckout ? 'out' : 'in'} successfully!`);
    }
    highlightInput();
    const shouldNull = shouldUpdate && isCheckout;
    setState(s => ({ ...s, order: shouldNull ? null : s.order, id: shouldNull ? '' : s.id, loading: true }));
    await wait();
    setState(s => ({ ...s, loading: false }));
  }

  useEffect(() => {
    if (!!id) { 
      retrieveOrder(id);
    } else {
      setState(s => ({ ...s, order: null }));
    }
  }, [id]);

  return (
    <div>
      <Input.Search
        id={INPUTBARCODE}
        value={state.id}
        onChange={onChange}
        loading={state.loading}
        placeholder='Scan barcode'
        autoFocus
        allowClear
      />
      {!state.loading && state.order && <Order state={{ editOrder: state.order, showCheckout: isCheckout }} isModal={true} onClose={onClose} />}
    </div>
  )
}