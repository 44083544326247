/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import { buildErrorMsgFromForm, getConfig } from '../../actions/utils';
import { withRouter } from 'react-router-dom';
import Logo from '../../_shared/logo';
import { Form, Input, Alert, Checkbox } from 'antd';
import styles from '../authentication.styles';
import SharedStyles from '../../_shared/styles';
import { Button } from '../../_shared/button';
import NumberedHeader from '../../_shared/numbered-header';
import { getInvites } from '../../_shared/services/accounts.service';
import { get } from 'lodash';
import LNSearch from '../../_shared/ln-search';

const content = {
  signUp: 'Register with your Email and License Number',
  fromLink: 'Register with your Email',
  buttonSignUp: 'Sign Up',
  licenseLabel: 'License Number',
  emailLabel: 'Email',
  passwordLabel: 'Password',
  passwordLabel2: 'Confirm Password'
};

export const cols = [
  { text: 'License Number', selected: true },
  { text: 'Verify Email' },
  { text: 'Create Profile' },
];

class SignUpComponent extends Component {
  state = {
    error: null,
    loading: false,
    fromLink: false,
    email: null,
    agree: false,
  };
  componentDidMount = async () => {
    const { location, form } = this.props;
    const fromLink = get(location, 'state.fromLink');

    const state = get(this, 'props.history.location.state');

    if (state) {
      form.setFieldsValue(state);
    }

    if (fromLink) {
      const email = get(location, 'state.email');
      form.setFieldsValue({ email });
      this.setState({ fromLink, email, loading: false });
    }
  };
  signUpSubmit = async (e) => {
    e.preventDefault();
    if (this.state.loading || !this.state.agree) {
      return;
    }

    this.state.loading = true;
    this.state.error = null;
    this.setState(this.state);

    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        const error = buildErrorMsgFromForm(err);
        return this.setState({ error, loading: false });
      }
      const { email, password, licenseNumber } = values;

      const _email = email.toLowerCase();

      try {
        let company = this.state.fromLink ? {} : this.LNSEARCH.state.licenseNumbersAll.find(l => get(l, 'License #') === licenseNumber);
        if (this.state.fromLink) {
          const invite = (await getInvites({ email: _email })).find(inv => !get(inv, 'data.notified'));
          company = get(invite, 'user.company', {});
        }
        if (!company) {
          return this.setState({
            loading: false,
            error: 'Please choose a valid License #',
          })
        }
        await this.props.signUp(_email, password, company, { fromLink: this.state.fromLink });
        this.props.history.push('/verify', {
          email: _email,
          password,
          company,
          fromLink: this.state.fromLink,
        });
      } catch (err) {
        this.setState({
          error: err.message,
          loading: false,
        })
      }
    });
  };
  goBack = () => {
    const { getFieldValue } = this.props.form;
    this.props.history.push('/login', { 
      email: getFieldValue('email'),
      password: getFieldValue('password'),
    });
  }
  render() {
    const { getFieldDecorator, getFieldValue, setFieldsValue } = this.props.form;
    const { error, loading, fromLink, agree } = this.state;
    const btnDisabled = !(
      !!agree &&
      getFieldValue('email') && 
      getFieldValue('password') && 
      getFieldValue('password2') && 
      (fromLink || getFieldValue('licenseNumber')) &&
      (getFieldValue('password') ===
      getFieldValue('password2'))
    );
    
    return (
      <div className="SignUp" css={css(styles.container)}>
        <div css={css(SharedStyles.logoWrap)}>
          <Logo />
        </div>

        {!fromLink && <NumberedHeader
          cols={cols}
        />}

        <p css={css(styles.title)}>
          {fromLink ? content.fromLink : content.signUp}
        </p>

        {!!error && (
          <Alert
            message={error}
            type={'error'}
            closable
            onClose={() => this.setState({ error: null })}
          />
        )}
        
        <div css={css(styles.inputContainer, `max-width: 500px;`)}>
          <Form layout="vertical" onSubmit={this.signInSubmit}>
            {!fromLink && <Form.Item label={content.licenseLabel}>
              {getFieldDecorator('licenseNumber', {
                validateTrigger: 'onBlur',
                rules: [
                  {
                    required: true,
                    message: 'License Number is required. ',
                  },
                ],
              })(
                <LNSearch ref={comp => this.LNSEARCH = comp} onSelect={e => setFieldsValue({ licenseNumber: e })} />
              )}
            </Form.Item>}
            <Form.Item label={content.emailLabel}>
              {getFieldDecorator('email', {
                validateTrigger: 'onBlur',
                rules: [
                  {
                    type: 'email',
                    message: 'The input is not a valid E-mail. ',
                  },
                  {
                    required: true,
                    message: 'The input is not a valid E-mail. ',
                  },
                  {
                    max: 100,
                    message: 'Email is limited to 60 characters. ',
                  },
                ],
              })(<Input disabled={fromLink} />)}
            </Form.Item>
            <Form.Item label={content.passwordLabel}>
              {getFieldDecorator('password', {
                validateTrigger: 'onBlur',
                rules: [
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ],
              })(<Input.Password />)}
            </Form.Item>
            <Form.Item label={content.passwordLabel2}>
              {getFieldDecorator('password2', {
                validateTrigger: 'onBlur',
                rules: [
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ],
              })(<Input.Password />)}
            </Form.Item>

            <Checkbox checked={agree} onChange={() => this.setState({ agree: !agree })}><span>I have read and agree to the <a target='_blank' href={getConfig('account.toslink', 'https://docs.google.com/document/d/1iME8aWO_tR60q8j5X16MDzxUCAdfyKGY9TziZIoDq-A/edit?usp=sharing')}>Terms of Service</a></span></Checkbox>

            <Form.Item>
              <div css={css(styles.buttonContainer)}>
                <Button
                  loading={loading}
                  onClick={this.signUpSubmit}
                  disabled={btnDisabled}
                  type={"primary"}
                  css={css(styles.button)}
                >
                  {content.buttonSignUp}
                </Button>

                <Button
                  outline={true}
                  loading={loading}
                  onClick={this.goBack}
                  css={css(styles.button)}
                >
                  {'Cancel'}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export const SignUp = Form.create()(withRouter(SignUpComponent));
