export const VehicleStatus = [
  { value: 0, title: 'Inactive' },
  { value: 1, title: 'Active' },
]

export const TrueFalse = [
  { value: 0, title: 'No' },
  { value: 1, title: 'Yes' },
]

export const Radius = [
  { value: 'long', title: 'Long' },
  { value: 'mid', title: 'Mid' },
  { value: 'local', title: 'Local' },
]

export const OwnedStatus = [
  { value: 'owned', title: 'Owned' },
  { value: 'leased', title: 'Leased' },
]