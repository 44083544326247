import { Modal } from 'antd';
import React from 'react';
import { Form } from '../../_shared/form';
import Row from '../../_shared/row';
import { Button } from '../../_shared/button';

export const MaintTypes = [
  { title: 'Oil Change', change: 'mileage_loc', value: 'change_oil' },
  { title: 'Tire Change', change: 'mileage_tirechange', value: 'change_tires' },
  { title: 'Brake Job', change: 'mileage_brakes', value: 'change_brakes' },
  { title: 'Other', change: 'other', value: 'change_other' },
]

const required = {
  options: {
    rules: [
      { required: true, }
    ]
  }
}

export const MaintForm = props => {
  const formProps = {
    onSubmit: props.onSubmit,
    toDisplay: [
      {
        var: 'vehicleId',
        type: 'dropdown',
        title: 'Select Vehicle',
        opts: () => props.vehicles || [],
        ...required,
      },
      {
        var: 'date',
        type: 'date',
        title: 'Date',
        ...required,
      },
      {
        var: 'mileage',
        title: 'Current Mileage',
        props: {
          type: 'number'
        },
        ...required,
      },
      {
        var: 'type',
        type: 'dropdown',
        title: 'Type of Maintenance',
        opts: () => MaintTypes,
        ...required,
      },
      {
        var: 'other',
        title: 'If other please explain type of maintenance'
      },
      {
        var: 'cost',
        title: 'Cost of Maintenance',
        props: {
          type: 'number',
          addonBefore: "$",
        },
        ...required,
      },
    ]
  }

  const renderFooter = () => {
    return (
      <Row style={{ justifyContent: 'flex-end' }}>
        <Button title="Cancel" outline onClick={props.onCancel} />
        <Button title="Save" onClick={props.onOk} />
        <Button title="Save and Add" onClick={props.onSaveAndAdd} />
      </Row>
    )
  }

  return (
    <Modal {...props} footer={renderFooter()}>
      {!!props.visible && <Form {...formProps} registerForm={props.registerForm} />}
    </Modal>
  )
}