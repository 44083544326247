import JSZip from "jszip";
import axios from "axios";
import { saveAs } from "file-saver";
import { get } from 'lodash';

//call this function to download all files as ZIP archive
export const downloadAllToZip = async (opts = {}) => {
  try {
    const files = get(opts, 'files', []);
    const zipFileName = get(opts, 'zipFileName', 'hello.zip');

    const zip = new JSZip();

    const ishttp = window.location.protocol === 'http:'
    const fixurl = (url) => {
      return ishttp ? url.replace('https', 'http') : url;
    }

    const download = (item) => {
      //download single file as blob and add it to zip archive
      return axios.get(fixurl(item.url), { responseType: "blob" }).then((resp) => {
        zip.file(item.name, resp.data);
      });
    };

    const arrOfFiles = files.map(download);

    await Promise.all(arrOfFiles);

    const blob = await zip.generateAsync({ type: "blob" })
    
    saveAs(blob, zipFileName);
  } catch (err) {
    throw err;
  }
};