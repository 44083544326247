/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { useState, useEffect } from 'react';
import { message, DatePicker, Input, Checkbox, Icon } from 'antd';
import styles from './styles';
import moment from 'moment';
import { debounce, get, set } from 'lodash';
import { getOrders, updateOrder } from '../../_shared/services/orders.service';
import { TableList } from '../../_shared/table-list';
import { getLicAddress, getManifestUrl, orderHasManifest, size, stopProp } from '../../actions/utils';
import { downloadAllToZip } from '../../actions/ziputil';
import { useSelector } from 'react-redux';
import { sortOrders, OrderModal, orderSearch } from '../orders/orders.component';
import { Button } from '../../_shared/button';
import { OrderStatus, getStatusIcon } from '../../models/order';

export const EditRow = (t, row, key, props) => {
  const hasRecon = get(row, 'data.recon');
  if (hasRecon && !props.forceRender) { return t }

  return (
    <Input disabled={props.disabled} onClick={stopProp} value={t} title={t} loading={props.saving} onChange={e => props.onChangeText(e.target.value, row,  key)} />
  )
}

export const ManifestDownload = props => {
  const userId = useSelector(st => get(st, 'user.id'))
  const [state, setState] = useState({
    loading: false,
    saving: false,
    selectedDate: [moment().subtract(4, 'week'), moment()],
    data: [],
    filteredData: null,
    selectedRows: [],
    incoming: false,
    editModal: undefined,
    downloading: false,
    searchVal: '',
  })

  const loadData = () => {
    if (size(state.selectedDate)) {
      retrieveData();
    } else {
      setState(s => ({ ...s, data: [] }));
    }
  }

  useEffect(() => {
    loadData();
  }, [state.selectedDate, state.incoming]);

  const retrieveData = async () => {
    try {
      setState(s => ({ ...s, loading: true }));
      const data = (await getOrders({ 
        userId, 
        between: state.selectedDate,
        isIncoming: state.incoming,
      })).filter(o => {
        const h = orderHasManifest(o);
        return h.hasFile && h.isSigned && [OrderStatus.delivered, OrderStatus.fullrejection, OrderStatus.partialrejection].includes(get(o, 'data.order.status'));
      }).sort(sortOrders);

      setState(s => ({ ...s, data, selectedRows: [] }))
    } catch (err) {
      message.error(err.message);
    } finally {
      setState(s => ({ ...s, loading: false }));
    }
  }

  const selectDate = date => {
    setState(s => ({ ...s, selectedDate: date }))
  }

  const saveOrder = debounce(async (order) => {
    try {
      setState(s => ({ ...s, saving: true }));
      await updateOrder(order);
    } catch (err) {
      message.error(`${err.message}`);
    } finally {
      setState(s => ({ ...s, saving: false }));
    }
  }, 800, { leading: false });

  const onChangeText = (val, row, key) => {
    const orderIndex = state.data.findIndex(o => o.id === row.id);
    set(state, `data.${orderIndex}.${key}`, val);
    setState(s => ({ ...s, data: [...state.data] }))
    saveOrder(get(state, `data.${orderIndex}`));
  };

  const reorderRows = rows => {
    setState(s => ({ ...s, data: rows }));
  }

  const onSelectedRowsChange = (keys) => {
    setState(s => ({ ...s, selectedRows: keys }));
  }

  const closeEditModal = (shouldUpdate) => {
    setState(s => ({ ...s, editModal: undefined }));
    if (shouldUpdate) {
      loadData();
    }
  }

  const downloadManifests = async () => {
    try {
      setState(s => ({ ...s, downloading: true }))
      const manUrls = [];

      state.data.map(d => {
        const isSelected = state.selectedRows.includes(d.id);
        if (isSelected) {
          const manDetail = orderHasManifest(d);

          if (manDetail.isSigned && manDetail.hasFile) {
            manUrls.push({ url: getManifestUrl(manDetail.file), name: `${d.orderId}_${get(d, 'data.billing.manifest')}.pdf` });
          }
        }
      })

      await downloadAllToZip({ files: manUrls, zipFileName: `ManifestDownload-${moment().toISOString()}.zip` });
    } catch (err) {
      message.error(err.toString());
    } finally {
      setState(s => ({ ...s, downloading: false }))
    }
  }

  const changeSearch = e => {
    const searchVal = e.target.value;
    setState(s => ({ ...s, filteredData: null, searchVal }));
  }

  const onSearch = () => {
    const filteredData = orderSearch(state.data, state.searchVal);
    setState(s => ({ ...s, filteredData }))
  }

  return (
    <div css={css(styles.container)}>
      <div className="no-print" css={css(styles.calContainer)}>
        <Input.Search
          placeholder={'Search by Order ID, Manifest No., Name or Address...'}
          enterButton
          onSearch={onSearch}
          value={state.searchVal}
          onChange={changeSearch}
          style={{ width: '50%', marginRight: 10 }}
        />

        <DatePicker.RangePicker
          css={css(styles.input)}
          loading={state.loading}
          value={state.selectedDate}
          onChange={selectDate}
        />
        
        <div css={css(styles.input, styles.row)}>
          <Checkbox checked={state.incoming} style={{ width: 200, marginLeft: 10, marginRight: 10 }} onChange={() => setState(s => ({ ...s, incoming: !state.incoming }))}>{'Incoming Orders'}</Checkbox>
          {!!size(state.selectedRows) && <Button loading={state.downloading} title="Download Manifests" onClick={downloadManifests} />}
        </div>
        
      </div>

      <div className="no-print">
        <TableList
          loading={state.loading}
          pageSize={250}
          data={state.filteredData || state.data}
          onReorder={reorderRows}
          columns={getCols({ onChangeText, ...state })}
          onSelectedRowsChange={onSelectedRowsChange}
          onRefresh={loadData}
          selectedRowKeys={state.selectedRows}
        />
      </div>

      <OrderModal editModal={state.editModal} onClose={closeEditModal} />
    </div>
  )
}

const getCols = props => {
  return [
    {
      title: 'Order ID',
      dataIndex: 'orderId',
      width: '14%',
    },
    {
      title: 'Delivery Date',
      dataIndex: 'data.order.deliveryDate',
      width: '14%',
      render: (t) => {
        return moment(t).format('L');
      }
    },
    {
      title: 'Vendor',
      dataIndex: 'data.pickupCompany.Licensee',
      width: '14%',
      render: (t, row) => {
        const name = 'pickupCompany';
        const compName = get(row, `data.${name}.DBA`, get(row, `data.${name}.Licensee`));
        const addr = getLicAddress(get(row, 'data.order.pickupLN'));
        const text = `${compName || ''}${compName ? ' - ' : ''}${addr}`;
        return <span>{text}</span>
      }
    },
    {
      title: 'Destination',
      dataIndex: 'data.destCompany.Street Address',
      width: '14%',
      render: (t, row) => {
        const isPickup = get(row, 'isPickup');
        const name = isPickup ? 'pickupCompany' : 'destCompany';
        const compName = get(row, `data.${name}.DBA`, get(row, `data.${name}.Licensee`));
        const compAddress = get(row, `data.${name}.Street Address`);
        const zip = get(row, `data.${name}.Zip`);
        const text = isPickup ? 'GPS' : `${compName || ''}${compName ? ' - ' : ''}${compAddress}${zip ? ', ' + zip : ''}`;
        return <span>{text}</span>
      }
    },
    {
      title: 'City',
      dataIndex: 'data.destCompany.City',
      width: '13%',
      render: (t, row) => {
        const isPickup = get(row, 'isPickup');
        const text = isPickup ? get(row, 'data.pickupCompany.City') : get(row, 'data.destCompany.City');
        return <span>{text}</span>
      }
    },
    {
      title: 'Invoice',
      dataIndex: 'data.billing.invoice',
      width: '13%',
    },
    {
      title: 'Manifest',
      dataIndex: 'data.billing.manifest',
      width: '13%',
      render: (t, r) => {
        const manDetail = orderHasManifest(r);
        if (manDetail.isSigned && manDetail.hasFile) {
          const url = getManifestUrl(manDetail.file);
          const onClick = e => {
            stopProp(e);
            window.open(url, '_blank').focus();
          }

          return <a onClick={onClick}>Manifest</a>
        }
        return null;
      }
    },
    {
      title: 'Status',
      dataIndex: 'data.order.status',
      width: '3%',
      render: (text, record) => {
        const ico = getStatusIcon(record, { isAdmin: false });

        return (
          <Icon
            type={ico.icon}
            title={ico.title}
            css={css(`color: ${ico.color};`)}
          />
        );
      },
    },
  ]
}