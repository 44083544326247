import { getOrders } from "../_shared/services/orders.service";
import { get, take, orderBy, unset } from 'lodash';
import { setOrders } from './orders/orders.container';
import { getDataFromGuid } from "../_shared/driver-search";
import { getDriverByEmail, userHasRole, wait } from "../actions/utils";
import { setCurrentUser } from "../authentication/authentication.actions";

export const retrieveData = (opts = {}) => async (
  dispatch,
  getState
) => {
  try {
    opts = {
      isMobile: false,
      ...(opts || {}),
    }
    
    const sState = getState();
    const userId = get(sState, 'user.id');
    const isDriver = userHasRole([2], sState);
    await wait(); //need to make sure we have the drivers and stuff

    const takeAmt = isDriver ? 0 : opts.isMobile ? 200 : 300;

    const orders = take(orderBy((await getOrders({ userId })).map(o => {
      const hasDrivers = get(o, 'data.order.driver');
      const DriverData = (() => {
        if (hasDrivers && Object.keys(hasDrivers).length > 0) {
          const { delivery, pickup } = hasDrivers;
          return {
            delivery: getDataFromGuid(delivery),
            pickup: getDataFromGuid(pickup),
          }
        } else {
          return {}
        }
      })();
      unset(o, 'data._original_');
      unset(o, 'data.order.signature');
      return { 
        ...o, 
        DriverData,
      }
    }), 'created', 'desc'), takeAmt);
    
    dispatch(setOrders(orders));
    
    if (isDriver) {
      const driver = getDriverByEmail(get(sState, 'user.email'));
      const user = {
        ...get(getState(), 'user', {}),
        driver: driver || {},
      }
      dispatch(setCurrentUser(user));
    }
  } catch (err) {
    console.warn(err);
  }
};
