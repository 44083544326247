/** @jsx jsx */
import { useState, useEffect } from 'react';
import { css, jsx } from '@emotion/core';
import { EditableFormTable } from '../../_shared/editable-table';
import { get, orderBy, set, size } from 'lodash';
import { createVehicle, deleteVehicle, getFleetData, updateFleetData, updateVehicle } from '../../_shared/services/vehicles.service';
import styles from './styles';
import { OwnedStatus, Radius, TrueFalse, VehicleStatus } from '../../models/vehicle';
import { useScreen } from '../../_shared/hooks/useScreen';
import { isIOS, saveWorksheet, wait } from '../../actions/utils';
import { uploadFile } from '../../_shared/services/utils.service';
import { useAuth } from '../../_shared/hooks/auth';
import Row from '../../_shared/row';
import { Button } from '../../_shared/button';
import { MaintForm, MaintTypes } from './maint-form';
import { message } from 'antd';
import moment from 'moment';
import ExcelJS from 'exceljs';

const renderValue = (v, lib = []) => {
  return <div>{get(lib.find(t => t.value == v), 'title', 'N/A')}</div>;
}

const renderCalc = (record, _vals = [], calc, opts = {}) => {
  const vals = _vals.map(v => parseFloat(get(record, v, 0)))

  const val = calc(vals);

  if (isNaN(val)) { return <div>N/A</div> }

  let style = {};

  if (opts.compare) {
    style = val >= opts.compare ? { backgroundColor: 'red' } : {};
  }

  return <div style={style}>{val}</div>
}

export const COLS = (_this) => ([
  {
    title: 'Vehicle',
    dataIndex: 'name',
    editable: true,
    width: 200,
    fixed: 'left',
    render: (text, record) => {
      return (
        <div>
          <div>{get(record, 'name')}</div>
          <div>{get(record, 'lic')}</div>
        </div>
      )
    }
  },
  {
    title: 'Year',
    dataIndex: 'year',
    editable: true,
    width: 200,
  },
  {
    title: 'Vin',
    dataIndex: 'vin',
    editable: true,
    width: 200,
    render: (text, record) => {
      return (
        <div>
          <div>{get(record, 'vin')}</div>
        </div>
      )
    }
  },
  {
    title: 'Plate',
    dataIndex: 'lic',
    editable: true,
    width: 200,
  },
  {
    title: 'Vehicle Status',
    dataIndex: 'status',
    editable: true,
    width: 200,
    inputType: 'select',
    options: VehicleStatus,
    render: v => renderValue(v, VehicleStatus),
  },
  {
    title: 'Registration Paperwork',
    dataIndex: 'data.regpaperwork',
    editable: true,
    width: 200,
    required: false,
    inputType: 'upload',
    uploadFile: async file => {
      const result = await uploadFile(file);
      return result.filename;
    },
  },
  {
    title: 'Reg Expiration Date',
    dataIndex: 'data.reg_exp',
    editable: true,
    width: 200,
    required: false,
    inputType: 'date'
  },
  {
    title: 'Insurance',
    dataIndex: 'data.insurance',
    editable: true,
    width: 200,
    required: false,
    inputType: 'upload',
    uploadFile: async file => {
      const result = await uploadFile(file);
      return result.filename;
    },
  },
  {
    title: 'AAA ID',
    dataIndex: 'data.aaa_id',
    editable: true,
    width: 200,
    required: false,
  },
  {
    title: 'Tire Size',
    dataIndex: 'data.tiresize',
    editable: true,
    width: 200,
    required: false,
  },
  {
    title: 'Total Mileage',
    dataIndex: 'mileage',
    editable: _this.isAdmin,
    width: 200,
    required: false,
    inputType: 'number'
  },
  {
    title: 'Mileage at last oil change',
    dataIndex: 'data.mileage_loc',
    editable: true,
    width: 200,
    required: false,
    inputType: 'number'
  },
  {
    title: 'Oil Change Needed (3,500 mi)',
    dataIndex: 'data.calc1',
    editable: false,
    width: 200,
    required: false,
    render: (text, record) => {
      return renderCalc(record, ['mileage', 'data.mileage_loc'], v => v[0] - v[1], { compare: 3500 })
    }
  },
  {
    title: 'Mileage at last tire change',
    dataIndex: 'data.mileage_tirechange',
    editable: true,
    width: 200,
    required: false,
    inputType: 'number'
  },
  {
    title: 'Tire Change Needed (35,000 mi)',
    dataIndex: 'data.calc2',
    editable: false,
    width: 200,
    required: false,
    render: (text, record) => {
      return renderCalc(record, ['mileage', 'data.mileage_tirechange'], v => v[0] - v[1], { compare: 35000 })
    }
  },
  {
    title: 'Mileage at last break job',
    dataIndex: 'data.mileage_brakes',
    editable: true,
    width: 200,
    required: false,
    inputType: 'number'
  },
  {
    title: 'Break Change Mileage (35,000 mi)',
    dataIndex: 'data.calc3',
    editable: false,
    width: 200,
    required: false,
    render: (text, record) => {
      return renderCalc(record, ['mileage', 'data.mileage_brakes'], v => v[0] - v[1], { compare: 35000 })
    }
  },
  {
    title: 'Winter Tires Currently in Use (Y/N)',
    dataIndex: 'data.wintertires',
    editable: true,
    width: 200,
    required: false,
    inputType: 'select',
    options: TrueFalse,
    render: v => renderValue(v, TrueFalse),
  },
  {
    title: 'Spare Tire',
    dataIndex: 'data.spare',
    editable: true,
    width: 200,
    required: false,
    inputType: 'select',
    options: TrueFalse,
    render: v => renderValue(v, TrueFalse),
  },
  {
    title: 'Auto Socks/Chains In Vehicle',
    dataIndex: 'data.chains',
    editable: true,
    width: 200,
    required: false,
    inputType: 'select',
    options: TrueFalse,
    render: v => renderValue(v, TrueFalse),
  },
  {
    title: 'Auto Sock Size',
    dataIndex: 'data.autosocksize',
    editable: true,
    width: 200,
    required: false,
  },
  {
    title: 'Camera Installed',
    dataIndex: 'data.camera',
    editable: true,
    width: 200,
    required: false,
    inputType: 'select',
    options: TrueFalse,
    render: v => renderValue(v, TrueFalse),
  },
  {
    title: 'Alarm Installed',
    dataIndex: 'data.alarm',
    editable: true,
    width: 200,
    required: false,
    inputType: 'select',
    options: TrueFalse,
    render: v => renderValue(v, TrueFalse),
  },
  {
    title: 'Radius(Long, Mid, Local)',
    dataIndex: 'data.radius',
    editable: true,
    width: 200,
    required: false,
    inputType: 'select',
    options: Radius,
    render: v => renderValue(v, Radius),
  },
  {
    title: 'Status',
    dataIndex: 'data.ownerstatus',
    editable: true,
    width: 200,
    required: false,
    inputType: 'select',
    options: OwnedStatus,
    render: v => renderValue(v, OwnedStatus),
  },
  {
    title: 'Addtional Vehicle Mainteance',
    dataIndex: 'data.add_maint',
    editable: true,
    width: 200,
    required: false,
  },
  {
    title: 'Notes',
    dataIndex: 'data.notes',
    editable: true,
    width: 200,
    required: false,
  },
])

export const FleetManager = props => {
  const { windowSize } = useScreen();
  const [state, setState] = useState({
    loading: true,
    rows: [],
  });
  const [maintModal, setMaintModal] = useState({
    visible: false,
    onClose: () => {
      setMaintModal(s => ({ ...s, visible: false }))
    }
  })

  const { userHasRole } = useAuth();

  const isAdmin = userHasRole(0);

  const getData = async () => {
    try {
      setState(s => ({ ...s, loading: true }))
      const rows = await getFleetData();
      const sorted = rows.map(r => {
        const reg_exp = get(r, 'data.reg_exp');
        if (reg_exp && typeof reg_exp !== 'string') {
          set(r, 'data.reg_exp', undefined);
        }
        return r;
      }).sort((a, b) => {
        let astat = get(a, 'status');
        let bstat = get(b, 'status');

        if (astat == 0) { astat = -1 }
        if (bstat == 0) { bstat = -1 }

        if (astat == bstat) {
          return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
        }

        return bstat - astat;
      })
      setState(s => ({ ...s, loading: false, rows: sorted }))
    } catch (err) {
      console.error(err);
    } finally {
      setState(s => ({ ...s, loading: false }))
    }
  }

  useEffect(() => { getData(); }, [state.week]);

  const updateData = async (d, row, index, type) => {
    setState(s => ({ ...s, rows: [...d] }));

    const method = row.id ? (type === 'delete' ? deleteVehicle : updateVehicle) : createVehicle;
    await method({ ...row, maint: undefined }).catch(err => {
      alert(`Failed to update fleet: ${err.messsage}`);
    })
  }

  const rowClass = (record) => {
    if (get(record, 'data.maint') === true) {
      return 'row-warning';
    }
  }

  const addRow = async () => {
    setState(s => ({ ...s, rows: [...s.rows, {}] }))
    await wait(250);
    const elem = document.querySelector('.ant-table-scroll .ant-table-body');
    if (elem && elem.scrollTo) {
      elem.scrollTo(0, elem.clientHeight);
    }
  }

  const onBeforeUpdate = (oldrow, newrow, form) => {
    const oldMileage = parseInt(get(oldrow, 'mileage'));
    const newMileage = parseInt(get(newrow, 'mileage'));

    if (oldMileage > newMileage) {
      
      const conf = window.confirm('You entered mileage less then the originally listed mileage. Are you sure you wish to continue?');
      if (!conf) {
        const error = new Error('Mileage cannot be less then original.');  
        form.setFields({
          mileage: {
            value: newMileage,
            errors: [error]
          }
        })
        throw error;
      }
    }
  }

  const toggleModal = () => {
    let form;

    const finalize = () => {
      return new Promise((resolve, reject) => {
        form.validateFieldsAndScroll(async (err, vals) => {
          try {
            if (err) { return reject(err); }
    
            const vehicle = state.rows.find(v => v.id === vals.vehicleId);

            vals.mileage = parseInt(vals.mileage);
    
            if (!vehicle) { 
              message.error('unable to find vehicle');
              return reject();
            }
    
            if (vehicle.mileage && vals.mileage < parseInt(vehicle.mileage)) {
              message.error('Mileage cannot be less than current mileage.')
              return reject();
            }
    
            const mType = MaintTypes.find(m => m.value == vals.type);
    
            set(vehicle, 'mileage', vals.mileage);
            set(vehicle, `data.${mType.change}`, vals.mileage);
    
            const now = moment().toISOString();
            const maint = {
              vehicleId: vals.vehicleId,
              maintDate: vals.date.toISOString(),
              createdAt: now,
              updatedAt: now,
              data: vals,
            }

            await Promise.all([
              updateFleetData({ maint }),
              updateVehicle({ ...vehicle, maint: undefined }),
            ])

            getData();

            message.success('Maintenance has been updated.');
            resolve();
          } catch (err) {
            reject();
          }
        })
      })
    }

    setMaintModal(s => ({ 
      ...s, 
      visible: !s.visible, 
      onSubmit: finalize,
      onOk: async () => {
        await finalize();
        s.onClose();
      },
      onCancel: s.onClose,
      onSaveAndAdd: async () => {
        await finalize();
        form.resetFields();
      },
      registerForm: f => form = f,
      vehicles: state.rows.map(v => ({ title: `${v.name} - ${v.lic}`, value: v.id })) 
    }))
  }

  const exportData = async _record => {
    const book = new ExcelJS.Workbook();

    const Records = Array.isArray(_record) ? _record : [_record];

    Records.map((record, i) => {
      const name = `${record.name} - ${record.lic}`;
      const sheet = book.addWorksheet(name.replace('/', ''));

      sheet.addRow(['Vehicle Maintenance']);
      sheet.addRow(['Vehicle:', name]);

      const rows = orderBy(record.maint, 'maintDate').map(r => ([
        moment(r.maintDate).toDate(), 
        parseFloat(get(r, 'data.mileage', 0)),
        get(MaintTypes.find(m => m.value == get(r, 'data.type')), 'title', 'N/A'), 
        get(r, 'data.other', ''),
        parseFloat(get(r, 'data.cost', 0)),
      ]));

      const cols = [
        { id: 1, width: 30 },
        { id: 2, width: 30 },
        { id: 3, width: 30 },
        { id: 4, width: 30 },
        { id: 5, width: 30 },
      ]

      cols.map(r => sheet.getColumn(r.id).width = r.width);

      if (size(rows)) {
        const tableProps = {
          name: `Maint_${i}`,
          ref: 'A4',
          style: {
            showRowStripes: true,
          },
          columns: [
            { name: 'Maintenance Date', style: { numFmt: 'mm/dd/yyyy' } },
            { name: 'Mileage', style: { numFmt: '#,##0;' } },
            { name: 'Type' },
            { name: 'Other' },
            { name: 'Cost', style: { numFmt: '"$"#,##0.00;' } },
          ],
          rows,
        }

        sheet.addTable(tableProps);
      }
    })

    const buffer = await book.xlsx.writeBuffer();

    saveWorksheet(`${new Date().toISOString()}_export.xlsx`, buffer)
  }

  const renderEditButtons = (record) => {
    return (
      <div style={{ margin: `0 5px` }}>
        <Button size="small" title="Export" onClick={() => exportData(record)} />
      </div>
    )
  }

  return (
    <div css={css(styles.table({ isMac: isIOS() }))}>
      <Row style={{ justifyContent: 'flex-end' }}>
        <Button icon="plus" title="Add Maintenance" onClick={toggleModal} />
        <Button icon="export" style={{ marginLeft: 5 }} title="Export All Maintenance" onClick={() => exportData(state.rows)} />
      </Row>
      <EditableFormTable 
        data={state.rows}
        columns={COLS({ isAdmin }).map(c => ({ ...c, useDiv: true }))}
        loading={state.loading}
        updateData={updateData}
        onBeforeUpdate={onBeforeUpdate}
        rowClassName={rowClass}
        editProps={{ fixed: 'right', width: 170 }}
        confirmSave
        tableProps={{
          scroll: { 
            x: 1000, 
            y: windowSize.innerHeight - 300 
          }
        }}
        addRow={addRow}
        rowKey={'id'}
        addButton={'Add Vehicle'}
        editButtons={renderEditButtons}
      />
      
      <MaintForm {...maintModal} />
    </div>
  )
}