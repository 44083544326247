module.exports = {
  highlight: '#43d37d',
  highlightAlt: '#663445',
  labelColor: '#4d4d4d',
  inputBorder: '#efefef',
  disabledColor: '#bed3d7',
  disabledLight: '#f5f5f5',
  success: 'green',
  error: 'red',
  errorLight: 'rgba(255,0,0,0.2)',
  warning: 'orange',
  warningLight: 'rgba(255,165,0,0.2)',
  lightBlue: 'rgba(0,189,255,0.2)',
  maroon: 'rgba(176,0,177,0.3)',
  lightBackground: 'white',
  black: 'black',
  white: 'white',
  overlay: 'rgba(0,0,0,0.3)',
  table0: '#f0fff3',
  tagDark: '#4d4d4d',
  hrBorder: '#efefef',
  tableBorder: '#d6d6d6',
  buttonDark: '#666666',
  footerBg: '#9a9899',
  eventBg: '#663445',
  eventBgDark: '#4d2231',
  cardOutline: '#9e9e9e',
  infoRow: '#f0fbff',
  lightText: '#aeaeae',
}