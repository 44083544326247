/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React, { useState } from 'react';
import { Button, message, Modal } from 'antd';
import { feedback as styles } from './header.styles';
import { get, set } from 'lodash';
import { InputLabel } from '../../_shared/input-label/input-label.component';
import { sendEmail } from '../../_shared/services/email.service';
import config from '../../config';
import { useScreen } from '../../_shared/hooks/useScreen';

const DEFSTATE = JSON.stringify({
  open: false,
  loading: false,
  email: {
    subject: 'Feedback Email from OMS System',
    from: '',
    html: '',
  }
})

export default props => {
  const [state, setState] = useState({
    ...JSON.parse(DEFSTATE),
  })

  const { isMobile } = useScreen();

  const onClick = () => {
    setState(s => ({ ...s, open: true }));
  }

  const submit = async () => {
    try {
      const { feedbackEmails } = config();
      setState(s => ({ ...s, loading: true }));
  
      const { html, from, subject } = state.email;
      if (!html || !from) {
        return message.error('Please fill out all required fields before submitting');
      }
  
      await sendEmail({
        to: feedbackEmails,
        subject,
        html: `
          From: ${from}<br /><br />
          Message: ${html}<br /><br />
        `
      })
  
      setState({
        ...JSON.parse(DEFSTATE),
      })
  
      message.success(`Message sent successfully. Thank you for your feedback!`);
    } catch (err) {
      message.error(`Failed to send email: ${err.message}`);
      setState(s => ({ ...s, loading: false }));
    }
  }

  const changeContent = (e, key) => {
    const s = { ...state };
    set(s, key, e.target.value);
    setState(s);
  }

  if (isMobile) { return null };

  return (
    <>
      <Button type="primary" css={css(styles.button)} onClick={onClick}>
        Send Feedback
      </Button>

      <Modal 
        visible={get(state, 'open')}
        onOk={submit}
        confirmLoading={get(state, 'loading')}
        title={'Send Feedback'}
        onCancel={() => setState(s => ({ ...s, open: false }))}
      >
        <InputLabel label={'*From'} value={get(state, 'email.from')} onChange={e => changeContent(e, 'email.from')} />
        <InputLabel label={'*Message'} type="textarea" value={get(state, 'email.html')} onChange={e => changeContent(e, 'email.html')} />
      </Modal>
    </>
  )
}