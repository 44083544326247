/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React from 'react';
import { Table, Input, InputNumber, Popconfirm, Form, Icon, Checkbox, TimePicker, Select, Upload, message, DatePicker } from 'antd';
import { Button } from '../button';
import { get, last, set, size } from 'lodash';
import Row from '../row';
import moment from 'moment';
import { getFileUrl, mergeDeep } from '../../actions/utils';

const EditableContext = React.createContext();

class EditableCell extends React.Component {
  state = {
    loading: false,
  }
  getInput = (props) => {
    const addProps = this.props.props || {};

    if (this.props.inputType === 'number') {
      return <InputNumber {...addProps} />;
    } else if (this.props.inputType === 'select') {
      return (
        <Select style={{ width: '100%' }} {...addProps}>
          {this.props.options.map((opt, i) => <Select.Option key={i} value={opt.value}>{opt.title}</Select.Option>)}  
        </Select>
      )
    } else if (this.props.inputType === 'checkbox') {
      return <Checkbox.Group {...addProps} options={this.props.options} />
    } else if (this.props.inputType === 'singlecheckbox') {
      return <Checkbox {...addProps} />
    } else if (this.props.inputType === 'date') {
      return <DatePicker {...addProps} />
    } else if (this.props.inputType === 'TimePickerRange') {
      const di = get(this, 'props.dataIndex');

      const onChange = (val, opt, i, key) => {
        let value = props.getFieldValue(di);

        if (!value) {
          const values = this.props.options.map(v => v.defaultValue);
          props.setFieldsValue({
            di: values,
          })

          value = values;
        }

        set(value, [i, key], val.toISOString());

        props.setFieldsValue({
          [di]: value,
        });
      }

      const currentval = props.getFieldValue(di);

      return (
        <>
          {this.props.options.map((opt, i) => {
            const cval = get(currentval, [i]);
            const hascval = !!size(cval);
            return (
              <Row key={i}>
                <div style={{ width: 50 }}>{`${opt.label}: `}</div>
                <TimePicker {...opt} {...addProps} value={hascval ? moment(get(cval, 0)) : get(opt, 'defaultValue.0')} defaultValue={get(opt, 'defaultValue.0')} onChange={v => onChange(v, opt, i, '0')} />
                <TimePicker {...opt} {...addProps} value={hascval ? moment(get(cval, 1)) : get(opt, 'defaultValue.1')} defaultValue={get(opt, 'defaultValue.1')} onChange={v => onChange(v, opt, i, '1')} />
              </Row>
            )
          })}
        </>
      )
    } else if (this.props.inputType === 'upload') {
      const upl = {
        name: 'file',
        multiple: false,
        accept: ".pdf",
        showUploadList: false,
        customRequest: async (opts) => {
          try {
            this.setState({ loading: true });
            const value = await this.props.uploadFile(opts.file);
            props.setFieldsValue({
              [this.props.dataIndex]: value,
            })
            return false;
          } catch (err) {
            message.error(err);
          } finally {
            this.setState({ loading: false });
          }
        }
      };

      const hasFile = props.getFieldValue(this.props.dataIndex);

      return (
        <Upload {...upl}>
          {hasFile && <Button title="View File" onClick={() => window.open(getFileUrl(hasFile), '_blank')} style={{ marginRight: 5 }} />}
          <Button loading={this.state.loading}><Icon type="upload" /></Button>
        </Upload>
      )
    } else if (this.props.customInput) {
      return this.props.customInput({
        ...this.props,
        ...addProps,
        ...props,
      });
    } else if (this.props.inputType === 'textarea') {
      return <Input.TextArea {...addProps} />
    }
    return <Input {...addProps} />;
  };

  returnRender = () => {
    const { children, inputType, dataIndex, record } = this.props;
    const useDiv = get(this, 'props.useDiv', false);

    const renderTypes = ['upload'];

    let childs = children;
    const val = get(record, dataIndex);

    if (renderTypes.includes(inputType)) {
      const hasFile = val;
      childs = hasFile ? <Button hoverText="View File" onClick={() => window.open(getFileUrl(hasFile), '_blank')} title="View File" /> : null;
    } else if (inputType === 'date') {
      try {
        childs = val ? moment(val).format('L') : null;
      } catch (err) {
        childs = null;
      }
    }

    return useDiv ? <div>{childs}</div> : childs;
  }

  renderCell = (rprops) => {
    const { getFieldDecorator } = rprops;
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;
    
    let val = editing ? get(record, dataIndex) : null;

    if (inputType === 'date') {
      const v = moment(val);
      val = v.isValid() ? v : moment();
    }

    return (
      <td key={index} {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              ...restProps,
              rules: [
                {
                  required: this.props.required !== undefined || this.props.customInput ? this.props.required : true,
                  message: `Please Input ${title}!`,
                },
              ],
              initialValue: inputType === 'singlecheckbox' ? !!val : val,
              checked: !!val,
            })(this.getInput(rprops))}
          </Form.Item>
        ) : this.returnRender()}
      </td>
    );
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
  }
}

class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { editingKey: '' };
    this.extraColumns = [
      {
        ...(this.props.editProps || {}),
        title: 'Edit',
        dataIndex: 'edit',
        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          const key = this.rowKey(record);
          const confirmSave = get(this, 'props.confirmSave', false);

          const renderSave = (form) => {
            if (confirmSave) {
              return (
                <Popconfirm title="Sure you want to save?" onConfirm={() => this.save(form, key)}>
                  <a>Save</a>
                </Popconfirm>
              )
            } else {
              return  <a onClick={() => this.save(form, key)} style={{ marginRight: 8 }}>Save</a>
            }
          }
          
          return editable ? (
            <span>
              <EditableContext.Consumer>
                {form => renderSave(form)}
              </EditableContext.Consumer>
              <Popconfirm title="Sure to cancel?" onConfirm={() => this.cancel(key)}>
                <a>Cancel</a>
              </Popconfirm>
            </span>
          ) : (
            <Row style={{ flexDirection: 'row' }}>
              <a disabled={editingKey !== ''} onClick={() => this.edit(key)}>
                Edit
              </a>
              <Popconfirm title="Sure to delete?" onConfirm={() => this.delete(key)} icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}>
                <a style={{ color: 'red', marginLeft: 5 }}>Delete</a>
              </Popconfirm>
              {this.props.editButtons ? this.props.editButtons(record) : null}
            </Row>
            );
        },
      },
    ];
  }

  rowKey = (record) => {
    const rk = get(this.props, 'rowKey', 'id');
    if (typeof rk === 'function') {
      const key = rk(record);
      return key || get(record, 'id');
    } else {
      return get(record, rk, record.id);
    }
  }

  isEditing = record => this.rowKey(record) === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: '' });
  };

  delete = (key) => {
    const { data } = this.props;
    const newData = [...data];
    const index = newData.findIndex(item => key === this.rowKey(item));
    if (index > -1) {
      const updatedRow = newData[index];
      newData.splice(index, 1);
      this.setState({ editingKey: '' }, () => this.updateData(newData, updatedRow, index, 'delete'));
    }
  };

  updateData = (data, updatedRow, updatedIndex, action) => {
    const { updateData } = this.props;
    updateData && updateData(data, updatedRow, updatedIndex, action);
  }

  save(form, key) {
    form.validateFields((error, row) => {
      try {
        if (error) {
          console.warn(error);
          return;
        }
        const { data } = this.props;
        const newData = [...data];
        const index = newData.findIndex(item => key === this.rowKey(item));
  
        if (index > -1) {
          const item = newData[index];
          const fullrow = mergeDeep(item, row);
  
          if (this.props.onBeforeUpdate) {
            this.props.onBeforeUpdate(item, row, form)
          }
  
          newData.splice(index, 1, fullrow);
          this.setState({ editingKey: '' }, () => this.updateData(newData, fullrow, index, 'update'));
        } else {
          newData.push(row);
          this.setState({ editingKey: '' }, () => this.updateData(newData, row, index, 'add'));
        }
      } catch (err) {
        message.error(err);
      }
    });
  }

  edit(key) {
    this.setState({ editingKey: key });
  }

  addRow = async () => {
    const { addRow } = this.props;
    const key = addRow && await addRow();
    setTimeout(() => {
      const lastRow = last(get(this.props, 'data'));
      const lastRowId = key || get(lastRow, get(this, 'props.rowKey', 'id'));
      this.setState({ editingKey: lastRow ? lastRowId : '' })
    }, 50);
  }

  render() {
    const { data } = this.props;

    const rowKey = get(this.props, 'rowKey', 'id');

    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = [...this.props.columns, ...this.extraColumns].map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          ...col,
          record,
          inputType: col.inputType || 'text',
          options: col.options || [],
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });

    const rowClassName = (rec, index) => {
      const pclass = get(this, 'props.rowClassName', () => '')(rec, index);

      return `editable-row ${pclass}`;
    }

    const canAdd = get(this.props, 'canAdd', true);
    const addTop = get(this.props, 'addTop', false);

    return (
      <EditableContext.Provider value={this.props.form}>
        {canAdd && addTop && <Button css={css(``)} onClick={this.addRow}>
          <Icon type="plus" />
          {get(this, 'props.addButton', 'Add Route Location')}
        </Button>}
        <div className='print-only'>
          <Row className="printheader">
          {this.props.columns.map(c => <div style={{ width: c.width || `${100 / this.props.columns.length}%` }}>{c.title}</div>)}
          </Row>
        </div>
        <Table
          components={components}
          bordered
          rowKey={rowKey}
          dataSource={data}
          columns={columns}
          rowClassName={rowClassName}
          pagination={false}
          loading={this.props.loading}
          {...(this.props.tableProps || {})}
        />
        {canAdd && <Button css={css(`float: left;`)} onClick={this.addRow}>
          <Icon type="plus" />
          {get(this, 'props.addButton', 'Add Route Location')}
        </Button>}
      </EditableContext.Provider>
    );
  }
}

export const EditableFormTable = Form.create()(EditableTable);