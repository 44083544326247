/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import colors from '../_shared/colors';
import styled from '@emotion/styled';
import moment from 'moment';

const P = styled.p`
  color: white;
  font-size: 12px;
  line-height: 12px;
  margin: 20px;
  font-weight: bold;
`;

export class Footer extends Component {
  render() {
    return (
      <div
        css={css`
          background-color: ${colors.highlight};
          width: 100%;
          height: 50px;
          align-items: center;
          justify-content: flex-start;
          flex-direction: row;
          display: flex;
        `}
        className={'Footer no-print'}
      >
        <P>{`Privacy Policy © Copyright ${moment().year()}. All rights reserved`}</P>
        <P css={css(`margin-left: auto;`)}>GPS One</P>
      </div>
    );
  }
}
