import colors from './colors';
import { convertHex } from '../actions/utils';

export const GlobalInserts = `
  html, body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  #root {
    height: 100%;
  }
  
  .App {
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }

  .ant-input {
    border: 2px solid ${colors.inputBorder};
    border-radius: 0px;
  }

  .ant-form-item-label {
    padding-bottom: 0px;
  }

  .ant-row.ant-form-item {
    margin-bottom: 5px;
  }

  .ant-form-item-label label {
    font-size: 12px;
    font-weight: bold;
    color: ${colors.labelColor};
  }

  .ant-select-selection,
  .ant-input.ant-select-search__field {
    border: 2px solid ${colors.inputBorder};
    border-radius: 0px;
  }

  .ant-checkbox-inner {
    border-radius: 0px;
  }

  .ant-menu-item {
    font-size: 12px;
  }

  .ant-menu-item.ant-menu-item-selected {
    background-color: ${colors.white};
    width: 100%;
  }

  .ant-menu-item.ant-menu-item-selected::after {
    left: 0px;
    right: auto;
    border-width: 5px;
    border-color: ${colors.highlight};
  }

  .ant-table {
    border: none;
  }

  .ant-table .canRowClick {
    cursor: pointer;

    &:hover {
      background-color: ${convertHex(colors.highlight, 0.1)}
    }
  }

  .ant-table .tbl-list-row-0 {
    background-color: ${colors.table0};
  }

  .ant-table .ant-table-thead > tr > th {
    background-color: ${colors.white};
  }

  .any-table .ant-table-column-title {
    color: ${colors.labelColor};
  }

  .ant-input-group-addon button {
    border-radius: 0px;
  }

  .ant-input[disabled],
  .ant-select-disabled {
    background-color: ${colors.disabledLight};
    color: ${colors.black};
  }

  .ant-modal-content {
    border-radius: 0px;
  }

  button.ant-btn {
    border-radius: 0px;
  }

  .rbc-day-slot .rbc-event,
  .rbc-event {
    background-color: ${colors.eventBg};
    border: 1px solid ${colors.eventBgDark};
    border-radius: 0px;
  }

  .rbc-event.rbc-selected {
    background-color: ${colors.eventBgDark};
  }

  .rbc-current-time-indicator {
    background-color: ${colors.highlight};
  }

  .rbc-today {
    background-color: ${convertHex(colors.highlight, 0.1)};
  }

  .ant-card {
    border: 2px solid ${colors.inputBorder};
    border-radius: 0px;
  }

  .ant-card .ant-card-body {
    padding: 10px;
  }

  .ant-select-arrow {
    color: ${colors.highlight};
  }

  .ant-form-explain {
    text-align: left;
  }

  .ant-calendar-picker {
    width: 100%;
  }

  .print-only {
    display: none !important;
  }

  .printheader {
    font-weight: bold;
  }

  @media print {
    .print-only {
      display: block !important;
    }

    .no-print {
      display: none;
    }

    button {
      display: none;
    }

    thead {
      display: none;
    }
  }
`;

export default {
  container: `
    display: flex;
    flex: 1;
    flex-grow: 1;
    flex-direction: column;
    overflow-y: auto;
  `,
  logoWrap: `
    margin-bottom: 40px;
  `,
  row: `
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    width: 100%;
  `,
  formContainer: `
    max-width: 400px;
  `,
  formAlert: `
    margin-bottom: 10px;
  `,
  hr: `
    width: 100%;
    height: 2px;
  `,
  ellipsisText: `
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
  `,
};