import { get } from 'lodash';
import { removeEmpty, pullUserOutOfResp } from '../../actions/utils';
import { instance } from './api.service';

export const getUser = async (id, opts ={}) => {
  const resp = await instance.get(`/users/${id}`, { data: {} });
  return pullUserOutOfResp(resp, opts);
};

export const getUserById = async (id, opts = {}) => {
  const resp = await instance.get(`/users/id/${id}`, { data: {} });
  return pullUserOutOfResp(resp, opts);
};

export const getUsers = async (where) => {
  let resp = {};
  if (where) {
    resp = await instance.post(`/users`, {
      where,
    });
  } else {
    resp = await instance.get(`/users`, { data: {} });
  }
  return get(resp, 'data.users', []);
};

export const createUser = async (user) => {
  const resp = await instance.post('/users/create', {
    user: removeEmpty(user),
  });
  return get(resp, 'data.user.data', null);
};

export const updateUser = async (user) => {
  const resp = await instance.post('/users/update', {
    user: removeEmpty(user),
  });
  return get(resp, 'data.user.data', null);
};

export const deleteUser = async (user) => {
  const resp = await instance.post('/users/delete', {
    user: removeEmpty(user),
  }, { 
    withCredentials: true,
    credentials: 'include'
  });
  return get(resp, 'data.user.data', null);
};