
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import styles from './styles';

export const FAQ = props => {
  const links = [
    { title: 'Creating an account', link: 'https://docs.google.com/document/d/1NrtYorFMjOh05qZTcFRA1-zJ434QhIo6/edit?usp=sharing&ouid=109176380852507117745&rtpof=true&sd=true' },
    { title: 'Placing an order', link: 'https://docs.google.com/document/d/1lpURgpKCPHvqFqcd-Yix6sB9NiuGlvhc/edit?usp=sharing&ouid=109176380852507117745&rtpof=true&sd=true' },
    { title: 'Managing Orders', link: 'https://docs.google.com/document/d/1EKwDUzscLn6Y4gPSx2EI7ZRmoLYdg9Tw/edit?usp=sharing&ouid=109176380852507117745&rtpof=true&sd=true' },
    { title: 'Shipping Labels', link: 'https://docs.google.com/document/d/16CL9FSgwO4sCEFXhLek_LIxWl-hUfHJ9/edit?usp=sharing&ouid=109176380852507117745&rtpof=true&sd=true' },
    { title: 'Adding Users', link: 'https://docs.google.com/document/d/1g9VcGXyrn7FVYxsAggL8NerwWp0xmcdU/edit?usp=sharing&ouid=109176380852507117745&rtpof=true&sd=true' },
    { title: 'Check Out Feature', link: 'https://docs.google.com/document/d/1QZNEYD_A2dZK9aWGCWllcbJ9GMZO9Aho/edit?usp=sharing&ouid=109176380852507117745&rtpof=true&sd=true' },

    { title: 'How to Download the GPS Chrome Extension', link: 'https://docs.google.com/document/d/1KuUMWORXnk3BLdNYS6PCQWrUGQZKGO__/edit?usp=sharing&ouid=109176380852507117745&rtpof=true&sd=true' },
    { title: 'How to use the Manifest Creation Tool', link: 'https://docs.google.com/document/d/1eC_8TyX9NvyKwzKbULEH9aRaNpoYzi-_/edit?usp=sharing&ouid=109176380852507117745&rtpof=true&sd=true' },
    { title: 'How to Upload a Finished Manifest into GPS One', link: 'https://docs.google.com/document/d/16-0AxFcnY8MVPUxqDLWTC8lhHZMEYXNb/edit?usp=sharing&ouid=109176380852507117745&rtpof=true&sd=true' },
    { title: 'How to use the Manifest Download Feature', link: 'https://docs.google.com/document/d/1z9fE_IxWuojunzLuB3X3rf_Df_BdCOdd/edit?usp=sharing&ouid=109176380852507117745&rtpof=true&sd=true' },
    // { title: 'How to delete your account', link: 'https://docs.google.com/document/d/1F2jx2pABV8Uky33xWrK0sd-aCCN9HFme/edit?usp=sharing&ouid=103737434464892542058&rtpof=true&sd=true' },
    // { title: 'How to Download and Upload a Manifest to an Order', link: 'https://docs.google.com/document/d/16-0AxFcnY8MVPUxqDLWTC8lhHZMEYXNb/edit?usp=sharing&ouid=109176380852507117745&rtpof=true&sd=true' },
  ]
  
  return (
    <div css={css(styles.container)}>
      {links.map(l => {
        return (
          <div key={l.title} className='linkContainer'>
            <div className='title'>{l.title}</div>
            <a className='link' href={l.link} target="_blank">{'Click Here to Learn More'}</a>
          </div>
        )
      })}
    </div>
  )
}
