import { get, size } from 'lodash';
import { pickFrom, pullUserOutOfResp, userIsLoggedIn } from '../../actions/utils';
import { _Account } from '../../models/account';
import { instance } from './api.service';

export const getUsersMatchingLn = async ln => {
  const resp = await instance.get(`/users/licensenumber/${ln}`);
  return get(resp, 'data.users', []);
}

export const signUp = async (email, password, company, opts = {}) => {
  try {
    const ln = get(company, 'License #');
    const usersWithLn = await getUsersMatchingLn(ln);
    if ((!ln && !opts.fromLink) || (!!size(usersWithLn) && !opts.fromLink)) {
      throw new Error('Please contact your admin to be invited to this Company');
    }
    return await instance.post('/auth/signUp', {
      email,
      password,
      data: new _Account({ role: !size(usersWithLn) ? 3 : 4, company }), //if no users with this status, then we make them account admin
      method: 'email'
    })
  } catch (err) {
    const e = get(err, 'response.data.error', err.message);
    throw new Error(e);
  }
};

export const updateLogin = async () => {
  const resp = await instance.post('/auth/update', {}, {
    withCredentials: true,
  });
  return get(resp, 'data.user');
}

export const signIn = async (email, password) => {
  try {
    const resp = await instance.post('/auth/login', {
      email,
      password,
    }, {
      withCredentials: true,
      credentials: 'include'
    })
    return pullUserOutOfResp(resp);
  } catch (err) {
    throw err;
  }
};

export const confirmSignUp = async (email, pin) => {
  const res = await instance.post('/auth/verify', { email, pin }, { withCredentials: true });
  return get(res, 'data.user');
};

export const resendCode = async (emailAddress) => {
  console.log('setup');
}

export const changePassword = async (
  user,
  oldPassword,
  newPassword
) => {
  const resp = await instance.post('/auth/changepassword', { 
    user, 
    oldPassword, 
    newPassword 
  }, { withCredentials: true });
  return get(resp, 'data');
};

export const forgotPassword = async (email) => {
  const resp = await instance.post('/auth/forgotPassword', { email }, { withCredentials: true });
  return get(resp, 'data');
};

export const sendValidationEmail = async (email) => {
  const resp = await instance.post('/auth/sendValidation', { email }, { withCredentials: true });
  return get(resp, 'data');
};

export const updateMyEmail = async (email, pin) => {
  const resp = await instance.post('/auth/updateMyEmail', { email, pin }, { withCredentials: true });
  return pullUserOutOfResp(resp);
};

export const getToken = async (data = {}) => {
  const resp = await instance.post('/users/token', { ...data });
  return get(resp, 'data.token');
};

export const setUserToken = async (store) => {
  const sState = store.getState();
  const isLoggedIn = userIsLoggedIn(sState);

  const userData = isLoggedIn ? { ...sState.user } : { email: 'temp@temp.com' };

  const tokendata = pickFrom(userData, ['email', 'company.License #', 'company.licenseNumbers', 'id', 'isActive', 'role']);

  const token = await getToken(tokendata);

  store.dispatch({ type: 'SET_TOKEN', token });
}