import { isObject, size } from 'lodash';

const _size = (obj) => {
  if (isObject(obj)) {
    return Object.keys(obj).length;
  } else {
    return size(obj);
  }
};

export const awaitAll = (arr = [], _opts = {}) => {
  return new Promise((resolve, reject) => {
    const opts = {
      queueSize: 100,
      waitTime: 1,
      checkProgressInterval: 5 * 60000,
      failMessage: 'Progress check failed',
      ..._opts,
    };

    let interval;
    let failed;

    const _reject = (err) => {
      if (failed) {
        return;
      }

      failed = true;
      clearInterval(interval);
      reject(err);
    };

    try {
      let complete = 0;
      const ret = [];
      const queue = {};
      const waitQueue = {};

      const promiseFinish = (resp, i) => {
        if (failed) {
          return;
        }

        complete += 1;
        ret[i] = resp;
        delete queue[i];
        if (opts.onProgress) {
          opts.onProgress(complete / _size(arr));
        }
        checkDone();
      };

      const processQueue = () => {
        if (failed) {
          return;
        }

        if (_size(queue) < opts.queueSize && _size(waitQueue) > 0) {
          const amt = opts.queueSize - _size(queue);

          let i = 0;
          for (let k in waitQueue) {
            if (i >= amt) {
              break;
            }

            const prom = waitQueue[k]();
            queue[k] = waitQueue[k];
            setTimeout(() => {
              prom()
                .then((res) => promiseFinish(res, k))
                .catch((err) => promiseFinish(err, k));
            }, opts.waitTime);

            delete waitQueue[k];
            i++;
          }
        } else if (!_size(arr)) {
          checkDone();
        }
      };

      const checkDone = () => {
        if (failed) {
          return;
        }
        if (complete >= _size(arr)) {
          clearInterval(interval);
          resolve(ret);
        } else {
          processQueue();
        }
      };

      arr.map((a, i) => {
        if (_size(queue) < opts.queueSize) {
          queue[i] = a;
        } else {
          waitQueue[i] = a;
        }
      });

      Object.keys(queue).map((k, i) => {
        const pro = queue[k]();
        setTimeout(() => {
          pro()
            .then((res) => promiseFinish(res, k))
            .catch((err) => promiseFinish(err, k));
        }, opts.waitTime * i);
      });

      processQueue();

      let lastProgress;
      interval = setInterval(() => {
        const currentProgress = complete / _size(arr);
        if (currentProgress === lastProgress) {
          _reject(new Error(opts.failMessage));
        }
        lastProgress = currentProgress;
      }, opts.checkProgressInterval);
    } catch (err) {
      _reject(err);
    }
  });
};

export const prog = (p) => {
  console._log(`Progress: ${(p * 100).toFixed(2)}%`);
};
