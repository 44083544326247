import { get } from 'lodash';
import { instance } from './api.service';

export const getDriverSchedule = async (where) => {
  let resp = {};
  if (where) {
    resp = await instance.post(`/driverschedule`, {
      ...where,
    });
  } else {
    resp = await instance.get(`/driverschedule`, { data: {} });
  }
  return get(resp, 'data.data', null);
};

export const createDriverSchedule = async (schedule) => {
  const resp = await instance.post('/driverschedule/create', {
    schedule,
  });
  return get(resp, 'data.data', null);
};

export const updateDriverSchedule = async (schedule) => {
  const resp = await instance.post('/driverschedule/update', {
    schedule,
  });
  return get(resp, 'data.data', null);
};

export const deleteDriverSchedule = async (schedule) => {
  const resp = await instance.post('/driverschedule/delete', {
    schedule
  });
  return get(resp, 'data.driver', null);
};