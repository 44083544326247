import { get } from 'lodash';
import { instance } from './api.service';

export const getOrder = async (id) => {
  const resp = await instance.get(`/orders/${id}`, { data: {} });
  return get(resp, 'data.order', null);
};

export const getOrders = async (where, opts = {}) => {
  let resp = {};
  if (where) {
    resp = await instance.post(`/orders`, {
      where,
    });
  } else {
    resp = await instance.get(`/orders?${new URLSearchParams(opts).toString()}`, { data: {} });
  }
  return get(resp, 'data.orders', []);
};

export const getUserOrders = async (where, opts = {}) => {
  let resp = {};
  if (where) {
    resp = await instance.post(`/orders/userorders`, {
      where,
    });
  } else {
    resp = await instance.get(`/orders?${new URLSearchParams(opts).toString()}`, { data: {} });
  }
  return get(resp, 'data.orders', []);
};

export const getSchedule = async (opts = {}) => {
  const resp = await instance({
    url: `/orders/schedule`,
    method: 'post',
    credentials: 'include',
    data: {
      opts
    }
  });
  return get(resp, 'data.schedule', []);
};

export const getReconciliation = async (opts = {}) => {
  const resp = await instance({
    url: `/orders/reconciliation`,
    method: 'post',
    credentials: 'include',
    data: {
      opts
    }
  });
  return get(resp, 'data.data', []);
};

export const getOrdersRange = async (opts = {}) => {
  const resp = await instance({
    url: `/orders/ordersrange`,
    method: 'post',
    credentials: 'include',
    data: {
      opts
    }
  });
  return get(resp, 'data.data', []);
};

export const getOrdersRangeForUser = async (opts = {}) => {
  const resp = await instance({
    url: `/orders/ordersrangeforuser`,
    method: 'post',
    credentials: 'include',
    data: {
      opts
    }
  });
  return get(resp, 'data.data', []);
};

export const createOrder = async (order) => {
  const resp = await instance.post('/orders/create', {
    order: order,
  });
  return get(resp, 'data.order', null);
};

export const updateOrder = async (order) => {
  const resp = await instance.post('/orders/update', {
    order: order,
  });
  return get(resp, 'data.order', null);
};

export const deleteOrder = async (order) => {
  const resp = await instance.post('/orders/delete', {
    order: order,
  });
  return get(resp, 'data.order', null);
};

export const undeleteOrder = async (order) => {
  const resp = await instance.post('/orders/undelete', {
    order: order,
  });
  return get(resp, 'data.order', null);
};

export const cancelOrder = async (order) => {
  const resp = await instance.post('/orders/cancel', {
    order,
  });
  return get(resp, 'data.order', null);
};

export const syncManifests = async (opts = {}) => {
  const resp = await instance.post('/metrc/syncall', {
    body: {
      ...opts,
    },
  });
  return get(resp, 'data.data', null);
}

export const fixOrdersAndNotify = async (data = {}) => {
  const resp = await instance({
    url: `/orders/fixorders`,
    method: 'post',
    credentials: 'include',
    data,
  });
  return get(resp, 'data.ordersToUpdate', []);
};

export const sendManifest = async (data) => {
  const resp = await instance({
    url: `/orders/manifestupload`,
    method: 'post',
    data
  })
  return get(resp, 'data', {});
}

export const signManifest = async (data) => {
  const resp = await instance({
    url: `/orders/signmanifest`,
    method: 'post',
    data
  })
  return get(resp, 'data', {});
}

export const mergePdfs = async (data) => {
  const resp = await instance({
    url: `/orders/mergePdfs`,
    method: 'post',
    data
  })
  return get(resp, 'data', {});
}

export const runSignatureReport = async (data) => {
  const resp = await instance({
    url: `/orders/signaturereport`,
    method: 'post',
    data
  })
  return get(resp, 'data.data', {});
}