import { useEffect, useState } from 'react';
import { get } from 'lodash';

export const useParent = node => {
  const [state, setState] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (!!node) {
      setState(s => ({
        ...s,
        width: get(node, 'parentNode.clientWidth', 0),
        height: get(node, 'parentNode.clientHeight', 0),
      }))
    }
  }, [node]);

  return state;
}