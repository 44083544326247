/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import Content from './login.content';
import { getContent, buildErrorMsgFromForm } from '../../actions/utils';
import { withRouter } from 'react-router-dom';
import Logo from '../../_shared/logo';
import { Form, Input, Alert } from 'antd';
import styles from '../authentication.styles';
import SharedStyles from '../../_shared/styles';
import { Button } from '../../_shared/button';
import { getPublicRoutes } from '../../dashboard/dashboard.routes';
import { get } from 'lodash';

class LoginComponent extends Component {
  state = {
    error: null,
    loading: false,
    isSignUp: false,
  };
  componentDidMount = () => {
    const { state } = this.props.history.location;
    const {
      form: { setFields, setFieldsValue },
    } = this.props;

    if (state && state.signUp) {
      setFields({
        email: {
          value: state.signUp,
        },
      });
      this.setState({ isSignUp: true });
    } else if (state) {
      setFieldsValue(state);
    }
  };
  goToForgotPassword = () => {
    this.props.history.push('/forgotPassword');
  };
  signInSubmit = async (e) => {
    e.preventDefault();
    if (this.state.loading) {
      return;
    }

    this.state.loading = true;
    this.state.error = null;
    this.setState(this.state);

    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        const error = buildErrorMsgFromForm(err);
        return this.setState({ error, loading: false });
      }
      const { email, password } = values;

      const _email = email.toLowerCase();

      const req = await this.props.signIn(_email, password);

      if (!req) {
        return this.setState({
          error: 'That username and password combination is not valid',
          loading: false,
        });
      } else {
        this.props.history.push('/dashboard');
      }
    });
  };
  signUpToggle = () => {
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      this.props.history.push('/signup', values);
    })
  }
  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { error, loading, isSignUp } = this.state;
    const btnDisabled = !(getFieldValue('email') && getFieldValue('password'));
    const content = getContent(Content);
    const publicRoutes = getPublicRoutes();

    return (
      <div className="Login" css={css(styles.container)}>
        <div css={css(SharedStyles.logoWrap)}>
          <Logo />
        </div>

        <p css={css(styles.title)}>
          {isSignUp ? content.signUp : content.signIn}
        </p>

        {!!error && (
          <Alert
            message={error}
            type={'error'}
            closable
            onClose={() => this.setState({ error: null })}
          />
        )}

        <div css={css(styles.inputContainer)}>
          <Form layout="vertical" onSubmit={this.signInSubmit}>
            <Form.Item label={content.emailLabel}>
              {getFieldDecorator('email', {
                validateTrigger: 'onBlur',
                rules: [
                  {
                    type: 'email',
                    message: 'The input is not a valid E-mail. ',
                  },
                  {
                    required: true,
                    message: 'The input is not a valid E-mail. ',
                  },
                  {
                    max: 60,
                    message: 'Email is limited to 60 characters. ',
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label={content.passwordLabel}>
              {getFieldDecorator('password', {
                validateTrigger: 'onBlur',
                rules: [
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ],
              })(<Input.Password onPressEnter={this.signInSubmit} />)}
            </Form.Item>

            {!isSignUp && (
              <div
                onClick={this.goToForgotPassword}
                css={css(styles.fgPassword)}
              >
                {content.forgotPasswordBtn}
              </div>
            )}

            <Form.Item>
              <div css={css(styles.buttonContainer)}>
                <Button
                  loading={loading}
                  onClick={this.signInSubmit}
                  disabled={btnDisabled}
                  type={"primary"}
                  css={css(styles.button)}
                >
                  {isSignUp ? content.buttonSignUp : content.button}
                </Button>

                <Button
                  outline={true}
                  loading={loading}
                  onClick={this.signUpToggle}
                  css={css(styles.button)}
                >
                  {isSignUp ? 'Cancel' : content.buttonSignUp}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>

        <div css={css(styles.linkContainer)}>
          {publicRoutes.filter(r => get(r, 'showOnLanding', true)).map(r => (
            <div>
              <a href={r.path}>{get(r, 'landingTitle', r.title)}</a>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export const Login = Form.create()(withRouter(LoginComponent));
