import colors from '../../_shared/colors';

export default {
  container: `
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    width: 100%;
  `,
  calContainer: `
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  `,
  printBtn: `
    position: absolute;
    right: 10px;
    z-index: 100;
  `,
  printOnly: `
    margin-right: auto;
    margin-left: 10px;
  `,
  schedContainer: `
    width: 100%;
  `,
  routeDivider: `
    margin-bottom: 25px;
  `,
  routeTableHeader: `
    margin-left: 10px;
    position: relative;
    flex-direction: row;
  `,
  routeHeader: `
    font-weight: bold;
    font-size: 20px;
    text-decoration: underline;
  `,
  noOrders: `
    font-size: 18px;
    padding: 20px;
  `,
  pickTypeHeader: `
    font-weight: bold;
    font-size: 16px;
    text-align: left;
    margin-left: 15px;
  `,
  margB10: `
    margin-bottom: 10px;
  `
}