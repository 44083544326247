import React, { useEffect, useState } from 'react';
import { Global } from '@emotion/core'
import { Provider } from 'react-redux'
import { configureStore } from './actions/configureStore'
import { PersistGate } from 'redux-persist/integration/react'
import Navigation from './navigation'
import { GlobalInserts } from './_shared/styles';
import Setup from './config/setup';
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import './App.css';

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import BugsnagPerformance from '@bugsnag/browser-performance'
import { getEnv } from './actions/utils';

Bugsnag.start({
  apiKey: '9349836deb086d8d55a621333ee93028',
  plugins: [new BugsnagPluginReact()],
  releaseStage: getEnv(),
  enabledReleaseStages: ['dev', 'prod'],
})
BugsnagPerformance.start({ apiKey: '9349836deb086d8d55a621333ee93028' })

const { store, persistor } = configureStore();

const LoadApp = props => {
  const [state, setState] = useState({ loaded: false });
  
  useEffect(() => {
    Setup(store).finally(() => setState(s => ({ ...s, loaded: true })))
  }, []);

  if (!state.loaded) {
    return <div>Loading...</div>
  }

  return <Navigation />
}

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Global styles={GlobalInserts} />
        <LoadApp />
      </PersistGate>
    </Provider>
  );
}

export default App;
