/** @jsx jsx */
import { jsx } from '@emotion/core';
import { PureComponent } from 'react';
import { AutoComplete as AutoCompleteAnt, Input, Icon } from 'antd';
import { get, size } from 'lodash';

export class AutoComplete extends PureComponent {
  shouldComponentUpdate(nextProps) {
    if (
      nextProps.value !== this.props.value ||
      (nextProps.dataSource !== this.props.dataSource && (nextProps.useSizeCompare ? size(nextProps.dataSource) !== size(this.props.dataSource) : true))
    ) {
      return true;
    }
    return false;
  }
  render() {
    return (
      <AutoCompleteAnt 
        filterOption={this.props.useDefaultFilter ? (inputValue, option) => {
          return (
            get(option, 'props.children', '')
              .toUpperCase()
              .indexOf(inputValue.toUpperCase()) !== -1
          );
        } : undefined} {...this.props}>
        <Input suffix={<Icon type="down" />} />
      </AutoCompleteAnt>
    );
  }
}

AutoComplete.defaultProps = {
  useSizeCompare: true,
};